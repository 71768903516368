import {
  SidebarNavigation,
  SidebarOption,
} from "@components/ui/SettingsNavigation";
import { usePermission } from "@hooks/usePermission";
import { useRole } from "@hooks/useRole";
import { ClerkUserRole, ClerkUserPermission } from "@models/clerkUser";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { SettingsPage } from "../consts";

const SettingsNavigation = () => {
  const isAdmin = useRole(ClerkUserRole.Admin);
  const misc = useSchematicFlag("settings.misc");
  const plansBillingSettingsEditAllowed = usePermission(
    ClerkUserPermission.plan_billing_edit,
  );

  const settingsOptions: SidebarOption[] = [
    ...(isAdmin
      ? [
          {
            label: "Account Settings",
            path: SettingsPage.AccountSettings,
          },
        ]
      : []),
    {
      label: "Audit Log",
      path: SettingsPage.AuditLog,
    },
    {
      label: "Team",
      path: SettingsPage.Team,
    },
    {
      label: "Keys and Traits",
      path: SettingsPage.KeysAndTraits,
    },
    {
      label: "API Keys",
      path: SettingsPage.ApiKeys,
    },
    ...(plansBillingSettingsEditAllowed
      ? [
          {
            label: "Plans",
            path: SettingsPage.Plans,
          },
          {
            label: "Usage & Billing",
            path: SettingsPage.UsageAndBilling,
          },
        ]
      : []),
    ...(misc
      ? [
          {
            label: "Misc",
            path: SettingsPage.Misc,
          },
        ]
      : []),
  ];

  return <SidebarNavigation title="Settings" options={settingsOptions} />;
};

export default SettingsNavigation;
