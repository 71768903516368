import { Alert } from "@ui/Alert";
import { PermissionButton } from "@ui/PermissionButton";
import { ReactNode } from "react";

interface TableBlankStateProps {
  onCreate?: () => void;
  disabled?: boolean;
  title: string;
  description?: string | ReactNode;
  buttonText?: string;
  createButton?: ReactNode;
}

export const TableBlankState = ({
  onCreate,
  disabled,
  title,
  description,
  buttonText,
  createButton,
}: TableBlankStateProps) => (
  <Alert background="schematic" size="lg" style="gray" className="text-center">
    <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
      <div className="flex flex-col text-left items-start space-y-2">
        <div className="text-[25px] font-medium font-body">{title}</div>
        {description && (
          <div className="text-lg leading-6 text-gray-600">{description}</div>
        )}
      </div>
      <div className="flex items-center">
        {onCreate &&
          (createButton || (
            <PermissionButton
              color="blue"
              onClick={onCreate}
              disabled={disabled}
            >
              {buttonText}
            </PermissionButton>
          ))}
      </div>
    </div>
  </Alert>
);
