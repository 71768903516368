import { ButtonGroup } from "@components/ui/ButtonGroup";
import { Icon } from "@components/ui/Icon";
import { Pill } from "@components/ui/Pill";
import { ButtonProps } from "@ui/Button";
import cx from "classnames";
import { ReactNode } from "react";

type SelectMatchingCriteriaProps = {
  onSelected: (criteria: string) => void;
};

interface ChoiceProps {
  title: string | ReactNode;
  description: string | ReactNode;
  buttons: ButtonProps[];
  highlighted?: boolean;
}

export const SelectMatchingCriteria = ({
  onSelected,
}: SelectMatchingCriteriaProps) => {
  const actionButtonsManualUpsert: ButtonProps[] = [
    {
      children: "Continue",
      color: "blue",
      onClick: () => {
        onSelected("manual_upsert");
      },
    },
  ];

  const actionButtonsMetaObjectMatch: ButtonProps[] = [
    {
      children: "Continue",
      color: "blue",
      className:
        "!bg-transparent !border-transparent !text-blue-400 hover:!bg-white hover:!border-blue-400",
      onClick: () => {
        onSelected("billing_meta_object");
      },
    },
  ];

  const choices: ChoiceProps[] = [
    {
      title: "We store Stripe Customer IDs in our database",
      description:
        "Your application saves the Stripe Customer ID with each user record.",
      buttons: actionButtonsManualUpsert,
      highlighted: true,
    },
    {
      title: "We store our User IDs in Stripe metadata",
      description:
        "Your application adds User IDs to the metadata of Stripe customers",
      buttons: actionButtonsMetaObjectMatch,
    },
  ];
  return (
    <div>
      <div>
        <div className="absolute left-6 top-[1.9rem] flex p-1 items-center rounded-full border border-gray-300">
          <Icon name="stripe" className="text-[#6772E5] text-lg leading-none" />
        </div>
      </div>
      <div className="p-6 pt-16">
        <div className="mb-14 space-y-2">
          <div className="text-2xl">
            How do you link your users with Stripe customers?
          </div>

          <div>
            Schematic needs to know how your app identifies Stripe customers to
            correctly sync subscription data.
          </div>
        </div>

        <div className="flex flex-col space-y-6">
          {choices.map((choice, index) => {
            const styles = cx(
              "flex flex-row items-center !p-8",
              "rounded-lg shadow-lg border",
              choice.highlighted
                ? "relative border-blue-400/60"
                : "bg-gray-50 ",
            );
            return (
              <div className={styles} key={index}>
                {choice.highlighted && (
                  <Pill
                    text="normal"
                    type="rounded"
                    color="blue"
                    className="absolute left-8 top-0 translate-y-[-50%] px-3"
                  >
                    <span className="text-xs mr-1">⭐️</span>{" "}
                    <span>Most common</span>
                  </Pill>
                )}

                <div className="flex flex-col space-y-2 flex-1">
                  <div className="text-[21px] text-black leading-none">
                    {choice.title}
                  </div>
                  {choice.description && (
                    <div className="text-sm">{choice.description}</div>
                  )}
                </div>

                <div>
                  <ButtonGroup buttons={choice.buttons} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
