import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  UserButton,
  useSession,
  useUser,
} from "@clerk/clerk-react";
import { FormikSelect } from "@components/forms/FormikSelect";
import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { Button } from "@components/ui/Button";
import { Icon } from "@components/ui/Icon";
import * as api from "@data/accounts";
import { ProvisionAccountParams } from "@data/accounts";
import { FormikControl } from "@forms/FormikControl";
import { createTemporaryToken } from "@modules/settings/queries/tokens";
import { SchematicEmbed } from "@schematichq/schematic-components";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const pricingComponentId =
  import.meta.env.VITE_SCHEMATIC_PRICING_COMPONENT_ID ?? "cmpn_du9R7hqUr3i";

const SelfService = () => {
  const { isLoaded, user } = useUser();
  const [showPricing, setShowPricing] = useState<boolean>(false);
  const [componentsToken, setComponentsToken] = useState<string | null>(null);
  const { session } = useSession();
  const navigate = useNavigate();
  // fetch flag to make sure we subscribe on changes before onboarding is complete
  useSchematicFlag("onboarding", {
    fallback: false,
  });

  const membership = user?.organizationMemberships[0]?.organization;

  const skipPlanSelection = () => {
    const userEnv = session?.user.publicMetadata["curEnvironmentId"];
    navigate(`/${userEnv}/quickstart`);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["accounts", "me"],
    mutationFn: (body: ProvisionAccountParams) => api.provision(body),
    onSuccess: async () => {
      // refreshing clerk token
      const accessTokenResult = await createTemporaryToken({ skipCache: true });

      skipPlanSelection();
      return;
      // temporarily not using this code.
      setComponentsToken(accessTokenResult.token);
      setShowPricing(false);
    },
  });

  if (!isLoaded) {
    return <SchematicOverlayLoader className="fixed top-0 left-0" />;
  }

  if (membership) {
    skipPlanSelection();
    return <div></div>;
  }

  return (
    <div className="min-h-screen fixed left-0 top-0 w-full">
      <nav className="flex justify-between items-center px-4 py-2">
        <div className="flex items-center space-x-4">
          <div className="flex flex-row items-center space-x-2">
            <Icon
              name="schematic"
              className="text-4xl md:text-5xl leading-none text-[#DB6669]"
            />
            <div className="h-[1.5rem] md:h-[2rem] w-[1px] bg-gray-300"></div>
          </div>

          <span className="flex items-center space-x-2.5">
            <Link
              to=""
              className="text-gray-400/80 text-xs md:text-sm leading-none hover:underline hover:text-blue-400"
            >
              Get a demo
            </Link>
            <span className="opacity-50">·</span>
            <Link
              to=""
              className="text-gray-400/80 text-xs md:text-sm leading-none hover:underline hover:text-blue-400"
            >
              View docs
            </Link>
          </span>
        </div>
        <div>
          <SignedIn>
            <UserButton
              appearance={{
                elements: {
                  userButtonAvatarBox: "w-7 h-7 lg:w-9 lg:h-9",
                },
              }}
            />
          </SignedIn>
        </div>
      </nav>

      {showPricing && (
        <div className="mx-auto mt-8 md:mt-0 md:pt-4 flex flex-row  items-center justify-center overflow-y-auto max-h-[calc(100vh-66px)] pb-14">
          <div className="flex flex-col items-center justify-center space-x-4 w-1/2 h-[50vh]">
            <SchematicEmbed
              accessToken={componentsToken!}
              id={pricingComponentId}
            />
            <Button
              type="submit"
              onClick={() => skipPlanSelection()}
              className="w-full mt-6 text-center"
              color="blue"
              size="sm"
            >
              Stay on Free Plan
            </Button>
          </div>
        </div>
      )}

      {!showPricing && (
        <div className="mx-auto mt-8 md:mt-0 md:pt-4 overflow-y-auto max-h-[calc(100vh-66px)] pb-14">
          <div className="flex flex-col space-y-4 text-center mb-12">
            <h1 className="font-display text-xl sm:text-3xl sm:leading-10 font-medium text-gray-900">
              Let's get started
            </h1>
            <p className="text-sm text-gray-500 px-4">
              Get up and running with Schematic in minutes.
            </p>
          </div>
          <div className="max-w-[325px] mx-auto">
            <Formik
              initialValues={{
                fullName: user?.fullName,
                companyEmail: user?.primaryEmailAddress?.emailAddress,
                companyName: "",
                companyUrl: "",
                role: "",
                hear: "",
              }}
              validationSchema={Yup.object({
                fullName: Yup.string().required("Your name is required"),
                companyEmail: Yup.string()
                  .email("Invalid email address")
                  .required("Email is required"),
                companyName: Yup.string().required("Company name is required"),
                companyUrl: Yup.string()
                  .url("Invalid website URL")
                  .required("Company URL is required"),
                role: Yup.string(),
                hear: Yup.string(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const provision = async () => {
                  await mutateAsync({
                    fullName: values.fullName!,
                    email: values.companyEmail as string,
                    companyName: values.companyName,
                    companyWebsite: values.companyUrl,
                    hearFrom: values.hear,
                    role: values.role,
                  });
                };

                setSubmitting(true);
                setTimeout(() => {
                  provision();
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ values }) => (
                <Form>
                  <div className={"flex flex-col space-y-5"}>
                    <FormikControl
                      control="input"
                      name="fullName"
                      type="text"
                      label="Full Name"
                      placeholder="John Smith"
                      value={values.fullName}
                    />

                    <FormikControl
                      control="input"
                      name="companyEmail"
                      type="text"
                      label="Company Email"
                      placeholder="john@billy.ai"
                      value={values.companyEmail}
                    />

                    <FormikControl
                      control="input"
                      name="companyName"
                      type="text"
                      label="Company Name"
                      placeholder="Billy.ai"
                      value={values.companyName}
                    />

                    <div>
                      <FormikControl
                        control="input"
                        name="companyUrl"
                        type="text"
                        label="Company URL"
                        placeholder="https://billy.ai"
                        value={values.companyUrl}
                      />
                    </div>

                    <div>
                      <FormikSelect
                        label="What is your role?"
                        name="role"
                        isClearable={false}
                        placeholder="Choose your role..."
                        options={[
                          {
                            label: "Engineering",
                            value: "engineering",
                          },
                          {
                            label: "Product",
                            value: "product",
                          },
                          {
                            label: "Design",
                            value: "design",
                          },
                          {
                            label: "Sales",
                            value: "sales",
                          },
                          {
                            label: "Marketing",
                            value: "marketing",
                          },
                          {
                            label: "Customer Success",
                            value: "customerSuccess",
                          },
                          {
                            label: "Operations",
                            value: "operations",
                          },
                        ]}
                      />
                      <div className="text-xs text-gray-400/80 mt-2">
                        Optional
                      </div>
                    </div>

                    <div>
                      <FormikControl
                        control="input"
                        name="hear"
                        type="text"
                        label="Where did you hear about us?"
                        placeholder="Linkedin"
                        value={values.hear}
                      />
                      <div className="text-xs text-gray-400/80 mt-2">
                        Optional
                      </div>
                    </div>

                    <div className="flex justify-center text-center pt-4">
                      <Button
                        type="submit"
                        disabled={isPending}
                        className="w-full"
                        color="blue"
                        size="sm"
                      >
                        {isPending && "Creating..."}
                        {!isPending && "Create account"}
                      </Button>
                    </div>

                    <p className="text-center text-xs sm:text-sm text-gray-400/80 px-2">
                      By creating an account, you agree to <br />
                      our{" "}
                      <Link to="" className="underline hover:text-blue-400">
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <Link to="" className="underline hover:text-blue-400">
                        Privacy Policy
                      </Link>
                      .
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      <SignedOut>
        <RedirectToSignIn redirectUrl={"/signin"} />
      </SignedOut>
    </div>
  );
};

export default SelfService;
