import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { Separator } from "@components/ui/Separator";
import { listBillingProductPrices, listBillingProducts } from "@data/billing";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikSelect } from "@forms/FormikSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import {
  BillingPriceResponseData,
  BillingProductResponseData,
} from "@models/api";
import { Plan } from "@models/plan";
import { PriceOptionLabel } from "@modules/plans/components/overlays/PlanEditOverlay/PriceOptionLabel";
import { getAudience } from "@modules/plans/queries";
import { EnvironmentCell } from "@modules/settings/components/EnvironmentCell";
import { Alert } from "@ui/Alert";
import { BillingProductOptionLabel } from "@ui/BillingProductOptionLabel";
import { Button } from "@ui/Button";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { useFormikContext } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

type PlanEditBillingStepProps = {
  verb: string;
  apiError?: string;
  planId?: string;
  noun: string;
};

type BillingProductOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingProductResponseData;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

type PlanBillingType = string;

type PlanType = {
  value: PlanBillingType;
  label: string;
};

const PlanBillingTypeFree: PlanBillingType = "free";
const PlanBillingTypePaid: PlanBillingType = "paid";

const planTypeOptions = [
  { value: PlanBillingTypeFree, label: "Free" },
  { value: PlanBillingTypePaid, label: "Paid" },
];

export const PlanEditBillingStep = ({
  verb,
  apiError,
  planId,
  noun,
}: PlanEditBillingStepProps) => {
  const [billingType, setBillingType] = useState<PlanType | null>(null);

  const {
    setFieldValue,
    values: { billingProduct, id, isFree, monthlyPrice, yearlyPrice },
  } = useFormikContext<Plan>();

  useEffect(() => {
    if (isFree === undefined) {
      return;
    }

    if (isFree) {
      setBillingType({
        ...billingType,
        value: PlanBillingTypeFree,
        label: "Free",
      });
    } else {
      setBillingType({
        ...billingType,
        value: PlanBillingTypePaid,
        label: "Paid",
      });
    }
  }, [billingType, isFree]);

  const { environment } = useCurrentEnvironment();

  const { data: audienceData, isLoading: audienceIsLoading } = useContextQuery({
    queryKey: ["plan", id, "audience"],
    queryFn: async () => {
      if (!id) return null;

      try {
        return await getAudience(id);
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
    enabled: !!planId,
  });

  const [audienceWillChange, setAudienceWillChange] = useState(false);

  const getBillingProductValue = () => {
    return (
      billingProduct && {
        label: <BillingProductOptionLabel product={billingProduct} />,
        name: billingProduct?.name,
        resource: billingProduct,
        value: billingProduct.productId,
      }
    );
  };

  useEffect(() => {
    if (audienceIsLoading || !audienceData) {
      setAudienceWillChange(false);
      return;
    }

    if (billingProduct) {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length != 1 ||
          audienceData.conditions[0].conditionType != "billing_product" ||
          audienceData.conditions[0].resourceIds.length != 1 ||
          audienceData.conditions[0].resourceIds[0] !=
            billingProduct.productId ||
          audienceData.conditions[0].operator != "eq",
      );
    } else {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length > 0,
      );
    }
  }, [audienceData, audienceIsLoading, billingProduct]);

  const isFreeSelected = billingType?.value === PlanBillingTypeFree;

  return (
    <>
      <FormHeader
        label={`${verb} plan`}
        title="Set pricing"
        description="Monetize your offering with flexible pricing options"
      />
      {environment && (
        <LabeledTooltip
          label={
            <EnvironmentCell
              environment={environment}
              className="flex justify-center text-nowrap text-sm"
            />
          }
          description={` This ${noun}'s audience will be synchronized with a billing product
              on this environment only.`}
          position="absolute"
          className="top-10 right-16"
          placement="bottom-center"
        />
      )}
      <FormColumn>
        {audienceWillChange && (
          <Alert size="xs" style="yellow" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>

            <div>
              Changing the billing product will overwrite the currently
              configured audience for this {noun}.
            </div>
          </Alert>
        )}

        <div className="flex gap-6">
          <FormikSelect
            className="w-auto"
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            key={`${billingType?.label}`}
            enableReinitialize={true}
            options={planTypeOptions}
            label="Type"
            name={`${billingType?.label}`}
            placeholder="Select plan type"
            onChange={async (option: PlanType) => {
              if (option?.value === PlanBillingTypeFree) {
                await setFieldValue("isFree", true);
              } else {
                await setFieldValue("isFree", false);
              }
            }}
            selectedOption={billingType}
          />
        </div>

        <div>
          <div className="flex justify-between">
            <div>
              <Icon
                name="stripe"
                className="w-8 h-8 text-blue-400 text-xl border rounded-full text-center inline-table m-2 ml-0"
              />

              <span className="font-medium text-xl">
                {isFreeSelected
                  ? "Optionally Sync with Stripe Product"
                  : "Sync with Stripe Product"}
              </span>
            </div>
          </div>
          <div>
            Companies that have this product in Stripe will belong to this{" "}
            {noun} in Schematic.{" "}
            <Link
              to="https://docs.schematichq.com/integrations/stripe"
              target="_blank"
              className="text-blue-400"
            >
              Learn more.
            </Link>
          </div>
        </div>

        <section className="space-y-2 pt-8">
          <Separator type="normal" className="-mx-12 mb-12" />
          <div className="flex gap-6 items-start">
            <div className="grow">
              <div className="flex items-center">
                <Icon
                  name="stacked"
                  className="leading-none text-4xl mr-1.5 text-gray-400/50 -ml-1"
                />
                <h1 className="font-body text-[1.18rem] font-medium leading-none">
                  {isFreeSelected ? "Free plan" : "Base charge"}
                </h1>
              </div>

              <p className="font-body text-base mb-5">
                {isFreeSelected
                  ? "Optionally link a Stripe product to your free plan"
                  : "Charge companies a recurring fixed fee for this plan"}
              </p>

              <div className="space-y-5 mb-4">
                <FormikAsyncSelect
                  className="flex-1"
                  defaultOptions
                  label={
                    isFreeSelected
                      ? "Stripe product for free plan"
                      : "Stripe product for base charge"
                  }
                  loadOptions={listBillingProducts}
                  loadOptionsMappers={{
                    requestFilter: {
                      limit: 10,
                      priceUsageType: "licensed",
                      withPricesOnly: true,
                      withoutLinkedToPlan: true,
                    },
                    mapperFunction: (
                      product: BillingProductResponseData,
                    ): BillingProductOption => {
                      return {
                        name: product.name,
                        value: product.productId,
                        label: <BillingProductOptionLabel product={product} />,
                        resource: product,
                      };
                    },
                  }}
                  name="billingProductId"
                  placeholder="Type to select Stripe product..."
                  onChange={(option?: BillingProductOption) => {
                    setFieldValue("monthlyPrice", null);
                    setFieldValue("yearlyPrice", null);
                    setFieldValue("billingProduct", option?.resource);
                  }}
                  selectedOption={getBillingProductValue()}
                />

                {billingProduct && (
                  <div className="flex space-x-4">
                    <FormikAsyncSelect
                      key={`monthly-price-${billingProduct?.productId}`}
                      className="flex-1"
                      defaultOptions
                      label={
                        isFreeSelected
                          ? "Monthly Stripe Price"
                          : "Monthly base charge"
                      }
                      description={
                        "At least one price (yearly or monthly) required"
                      }
                      loadOptions={listBillingProductPrices}
                      loadOptionsMappers={{
                        requestFilter: {
                          billingProductId: billingProduct?.productId,
                          limit: 10,
                        },
                        resultsFilter: (price) => price.interval === "month",
                        mapperFunction: (
                          price: BillingPriceResponseData,
                        ): PriceOption => ({
                          name: price.price?.toString(),
                          value: price.id,
                          label: <PriceOptionLabel price={price} />,
                          resource: price,
                        }),
                      }}
                      name="monthlyPriceId"
                      placeholder="Select price"
                      onChange={async (option: PriceOption) => {
                        await setFieldValue(
                          "monthlyPrice",
                          option?.resource || null,
                        );
                      }}
                      selectedOption={
                        monthlyPrice && {
                          name: monthlyPrice.price?.toString(),
                          value: monthlyPrice.id,
                          label: <PriceOptionLabel price={monthlyPrice} />,
                          resource: monthlyPrice,
                        }
                      }
                    />

                    <FormikAsyncSelect
                      key={`yearly-price-${billingProduct?.productId}`}
                      className="flex-1"
                      defaultOptions
                      label={
                        isFreeSelected
                          ? "Yearly Stripe Price"
                          : "Annual base charge"
                      }
                      description={
                        "At least one price (yearly or monthly) required"
                      }
                      loadOptions={listBillingProductPrices}
                      loadOptionsMappers={{
                        requestFilter: {
                          billingProductId: billingProduct?.productId,
                          limit: 10,
                        },
                        resultsFilter: (price) => price.interval === "year",
                        mapperFunction: (
                          price: BillingPriceResponseData,
                        ): PriceOption => ({
                          name: price.price?.toString(),
                          value: price.id,
                          label: <PriceOptionLabel price={price} />,
                          resource: price,
                        }),
                      }}
                      name="yearlyPriceId"
                      placeholder="Select price"
                      onChange={async (option: PriceOption) => {
                        await setFieldValue(
                          "yearlyPrice",
                          option?.resource || null,
                        );
                      }}
                      selectedOption={
                        yearlyPrice && {
                          name: yearlyPrice.price?.toString(),
                          value: yearlyPrice.id,
                          label: <PriceOptionLabel price={yearlyPrice} />,
                          resource: yearlyPrice,
                        }
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isFreeSelected && (
            <div className="bg-[#F9F9F9] rounded-md px-6 py-4">
              <div className="flex items-center">
                <Icon
                  name="arrow-analytics"
                  className="leading-none text-4xl mr-1.5 text-gray-400/50 -ml-1"
                />
                <h1 className="font-body text-[1.18rem] font-medium leading-none">
                  Usage-based charges
                </h1>
              </div>

              <div className="flex flex-row justify-between gap-5">
                <div className="font-body mb-2 text-sm ml-1.5 w-2/3">
                  Edit plan entitlements to charge companies based on their
                  usage of the features within this plan. Pay as you go, pay in
                  advance.
                </div>
                <div className="flex flex-col">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://docs.schematichq.com/billing/usage-based-billing",
                      );
                    }}
                  >
                    Learn more
                  </Button>
                </div>
              </div>
            </div>
          )}
        </section>

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}
      </FormColumn>
    </>
  );
};
