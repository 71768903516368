import { BillingSubscriptionView } from "@models/api";
import pluralize from "pluralize";

export const hasTrial = (subscription: BillingSubscriptionView | undefined) => {
  return (
    subscription && subscription.status === "trialing" && subscription.trialEnd
  );
};

export const getTrialEndDate = (trialEnd: number | undefined) => {
  if (!trialEnd) {
    return new Date(0);
  }
  return new Date(trialEnd * 1000 || 0);
};

export const getDaysLeft = (trialEnd: number | undefined): number => {
  return trialEnd
    ? Math.ceil((trialEnd * 1000 - Date.now()) / (1000 * 60 * 60 * 24))
    : 0;
};

export const getTrialStatus = (trialEnd: number | undefined): string => {
  const days = getDaysLeft(trialEnd);
  if (days < 0) return "Trial ended";
  if (days === 0) return "Trial ends today";
  return `${days} ${pluralize("day", days)} left`;
};
