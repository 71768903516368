import { PlanEntitlement } from "@models/entitlement";
import { ClipCopy } from "@ui/ClipCopy";
import { Icon } from "@ui/Icon";
import { formatCurrency } from "@utils/strings";
import pluralize from "pluralize";
import { ReactNode } from "react";

interface HeaderBlockProps {
  title?: string;
  label: string | ReactNode;
}

const HeaderBlock = ({ title, label }: HeaderBlockProps) => {
  return (
    <div className="flex flex-col">
      <div className="pr-8">
        {title && (
          <span className="text-sm text-black font-medium leading-none inline-block mb-2">
            {title}
          </span>
        )}

        <div className="text-gray-400">{label}</div>
      </div>
    </div>
  );
};

const OveragePriceRow = ({ entitlement }: { entitlement: PlanEntitlement }) => {
  const { meteredMonthlyPrice, meteredYearlyPrice, priceBehavior } =
    entitlement;
  const { priceTier: monthlyPriceTier } = meteredMonthlyPrice ?? {};
  const { priceTier: yearlyPriceTier } = meteredYearlyPrice ?? {};

  if (priceBehavior !== "overage") {
    return <div>Invalid Price Object. Impossible to display correctly.</div>;
  }

  return (
    <>
      {meteredMonthlyPrice && monthlyPriceTier && (
        <>
          <tr key={meteredMonthlyPrice.priceExternalId}>
            <td>
              {`Starts from: ${formatCurrency(
                monthlyPriceTier[0].perUnitPrice ??
                  Number(monthlyPriceTier[0].perUnitPriceDecimal),
                meteredMonthlyPrice.currency,
              )} per unit/month`}{" "}
              for first {monthlyPriceTier[0].upTo}{" "}
              {pluralize("unit", monthlyPriceTier[0].upTo)}
              <br />
              {`Then: ${formatCurrency(
                monthlyPriceTier[1].perUnitPrice ??
                  Number(monthlyPriceTier[1].perUnitPrice),
                meteredMonthlyPrice.currency,
              )} per unit/month`}
            </td>
            <td className={"text-gray-400"}>Tiered</td>
          </tr>
        </>
      )}
      {meteredYearlyPrice && yearlyPriceTier && (
        <>
          <tr key={meteredYearlyPrice.priceExternalId}>
            <td>
              {`Starts from: ${formatCurrency(
                yearlyPriceTier[0].perUnitPrice ??
                  Number(yearlyPriceTier[0].perUnitPriceDecimal),
                meteredYearlyPrice.currency,
              )} per unit/month`}{" "}
              for first {yearlyPriceTier[0].upTo}{" "}
              {pluralize("unit", yearlyPriceTier[0].upTo)}
              <br />
              {`Then: ${formatCurrency(
                yearlyPriceTier[1].perUnitPrice ??
                  Number(yearlyPriceTier[1].perUnitPriceDecimal),
                meteredYearlyPrice.currency,
              )} per unit/month`}
            </td>
            <td className={"text-gray-400"}>Tiered</td>
          </tr>
        </>
      )}
    </>
  );
};

export const BillingUsageBasedEntitlementCard = ({
  entitlement,
}: {
  entitlement: PlanEntitlement;
}) => {
  const meteredPrice =
    entitlement.meteredYearlyPrice ?? entitlement.meteredMonthlyPrice!;

  const getPriceBehaviorLabel = (): string => {
    switch (entitlement.priceBehavior) {
      case "pay_in_advance":
        return "Pay In Advance";
      case "pay_as_you_go":
        return "Pay as You Go";
      case "overage":
        return "Overage";
      default:
        return "";
    }
  };

  return (
    <div className="bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)] rounded-lg">
      {/*Header*/}
      <div className="relative flex justify-between items-center p-4">
        <Icon
          name="stripe"
          className="absolute top-0 right-0 w-6 h-6 text-blue-400 border rounded-full text-center inline-table m-2"
        />

        <HeaderBlock
          title={meteredPrice?.productName}
          label={
            <div className="flex">
              <Icon name="stripe" />
              <ClipCopy data={meteredPrice.productExternalId!} />
            </div>
          }
        />

        {meteredPrice.meterId && (
          <HeaderBlock
            title="Stripe meter"
            label={
              <a
                href={`https://dashboard.stripe.com/meters/${meteredPrice.meterId}`}
                target="_blank"
                className="text-blue-400 hover:opacity-80 space-x-2"
              >
                <span>See in Stripe</span>
                <Icon name="folder" />
              </a>
            }
          />
        )}
        {!meteredPrice.meterId && (
          <HeaderBlock
            title="Stripe meter"
            label={
              <span>
                Not available for {entitlement.feature?.featureType} feature
                type
              </span>
            }
          />
        )}

        <HeaderBlock
          title="Price behavior"
          label={
            <>
              <span className="block text-center">
                {getPriceBehaviorLabel()}
              </span>
            </>
          }
        />

        <HeaderBlock
          label={
            <a
              href={`https://dashboard.stripe.com/products/${meteredPrice.productExternalId}`}
              target="_blank"
              className="text-blue-400 hover:opacity-80 space-x-2"
            >
              <span>See in Stripe</span>
              <Icon name="folder" />
            </a>
          }
        />
      </div>
      {/*  Body*/}
      <div className="bg-gray-100 px-8 py-4">
        <table className="table-compact w-full">
          <thead>
            <tr>
              <th className="w-full">Prices</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {entitlement.meteredYearlyPrice &&
              entitlement.priceBehavior !== "overage" && (
                <>
                  <tr key={entitlement.meteredYearlyPrice.priceExternalId}>
                    <td>
                      {`${formatCurrency(
                        entitlement.meteredYearlyPrice.price,
                        entitlement.meteredYearlyPrice.currency,
                      )} per unit`}
                    </td>
                    <td className="text-gray-400">
                      {entitlement.meteredYearlyPrice.priceTier &&
                      entitlement.meteredYearlyPrice.priceTier.length > 1
                        ? "Tiered"
                        : "Recurring"}
                    </td>
                  </tr>
                </>
              )}
            {entitlement.meteredMonthlyPrice &&
              entitlement.priceBehavior !== "overage" && (
                <>
                  <tr key={entitlement.meteredMonthlyPrice.priceExternalId}>
                    <td>
                      {`${formatCurrency(
                        entitlement.meteredMonthlyPrice.price,
                        entitlement.meteredMonthlyPrice.currency,
                      )} per unit`}
                    </td>
                    <td className="text-gray-400">
                      {entitlement.meteredMonthlyPrice.priceTier &&
                      entitlement.meteredMonthlyPrice.priceTier.length > 1
                        ? "Tiered"
                        : "Recurring"}
                    </td>
                  </tr>
                </>
              )}
            {entitlement.meteredMonthlyPrice &&
              entitlement.priceBehavior === "overage" && (
                <OveragePriceRow entitlement={entitlement} />
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
