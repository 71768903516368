import { useSignIn } from "@clerk/clerk-react";
import { FormikControl } from "@components/forms/FormikControl";
import { Button } from "@components/ui/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormSignInProps } from "../consts";

export const SignInPhoneOtpForm = ({ setError }: FormSignInProps) => {
  const { isLoaded, signIn, setActive } = useSignIn();

  const handleOtpVerification = async (values: { code: string }) => {
    if (!isLoaded || !signIn) return;

    try {
      let signInAttempt;

      if (signIn.status === "needs_second_factor") {
        signInAttempt = await signIn.attemptSecondFactor({
          strategy: "phone_code",
          code: values.code,
        });
      } else {
        signInAttempt = await signIn.attemptFirstFactor({
          strategy: "phone_code",
          code: values.code,
        });
      }

      if (signInAttempt.status === "complete") {
        await setActive({ session: signInAttempt.createdSessionId });
        window.location.href = "/";
      } else {
        console.error(signInAttempt);
        setError("Failed to complete sign-in. Please try again.");
      }
    } catch (err: any) {
      console.error("Error:", JSON.stringify(err, null, 2));
      setError("Invalid verification code.");
    }
  };

  return (
    <Formik
      initialValues={{ code: "" }}
      validationSchema={Yup.object({
        code: Yup.string()
          .length(6, "Verification code must be 6 digits")
          .required("Verification code is required"),
      })}
      onSubmit={handleOtpVerification}
      key="form-otp"
    >
      {({ isSubmitting, dirty }) => (
        <Form className="space-y-4">
          <div className="text-left">
            <FormikControl
              control="input"
              name="code"
              label="Enter verification code"
              type="text"
              placeholder="Enter 6-digit code"
              className="border rounded p-2 w-full"
            />
          </div>
          <div className="w-full flex-1 flex">
            <Button
              type="submit"
              color="blue"
              className="w-full text-center"
              loading={isSubmitting}
              disabled={!dirty || isSubmitting}
            >
              {isSubmitting ? "Verifying code..." : "Verify"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
