import cx from "classnames";
import { CustomerLogos } from "./CustomerLogos";
import { useScript } from "../consts";

export const VideoContent = () => {
  useScript("https://fast.wistia.com/embed/05mrnlr66e.jsonp");
  useScript("https://fast.wistia.com/assets/external/E-v1.js");

  return (
    <div className="flex-1 max-h-full h-full flex items-start justify-center bg-gradient-to-b from-[#DB6769] to-black lg:from-transparent lg:to-transparent mt-4">
      <div className="h-full flex items-center justify-center">
        <div className="flex flex-1 items-center overflow-y-auto justify-center max-h-full h-full flex-col pt-10 lg:pt-20 px-8 lg:px-12">
          <div className="overflow-hidden">
            <div className="text-lg lg:text-2xl text-center font-normal font-display mb-10 lg:mb-12 text-white">
              Watch Schematic implemented in less than 5 minutes
            </div>
            <div>
              <div
                className={cx(
                  "flex items-center justify-center",
                  "wistia_embed wistia_async_05mrnlr66e popover=true popoverContent=link videoFoam=false w-full", // Wistia Embed Activation
                )}
              >
                <div className="mx-auto flex w-full items-center justify-center">
                  <div className="cursor-pointer shadow-lg rounded-md border border-white/40 overflow-hidden w-[280px] h-[160px] lg:w-[400px] lg:h-[240px] group !flex flex-col items-center justify-center space-y-4 bg-black/70">
                    <svg
                      width="41"
                      height="46"
                      viewBox="0 0 41 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block transition-all group-hover:scale-[1.15] group-hover:opacity-90"
                    >
                      <path
                        d="M39.5 20.4019C41.5 21.5566 41.5 24.4434 39.5 25.5981L5 45.5167C3 46.6714 0.500002 45.228 0.500002 42.9186L0.500004 3.08141C0.500004 0.772008 3 -0.671364 5 0.483336L39.5 20.4019Z"
                        fill="#DB6769"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center mb-12 mt-12 lg:mt-24">
              <div className="text-lg font-normal font-display mb-12 text-white">
                Backed by industry leaders
              </div>
              <div>
                <CustomerLogos />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
