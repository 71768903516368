import { Account } from "@models/account";
import { get, post } from "./index";

export const me = async (): Promise<Account> => {
  return get<Account>("accounts/me");
};

export type ProvisionAccountParams = {
  fullName: string;
  email: string;
  companyName: string;
  companyWebsite: string;
  role: string;
  hearFrom: string;
};

export const provision = async (
  body: ProvisionAccountParams,
): Promise<Account> => {
  return post<Account, ProvisionAccountParams>(
    "accounts/provision",
    body,
    true,
  );
};
