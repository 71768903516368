import { useSchematicFeature } from "@hooks/useSchematicFeature";
import { useSchematicFlag } from "@schematichq/schematic-react";

export interface MenuLinks {
  label?: string;
  path: string;
  extra?: MenuLinks[];
}

export const useMenuLinks = (): MenuLinks[] => {
  const planConfigurationFlag = useSchematicFlag("plan-configuration", {
    fallback: true,
  });
  const showQuickstart = useSchematicFlag("onboarding", {
    fallback: false,
  });
  const { canView: canViewComponents } = useSchematicFeature("components", {
    fallback: true,
  });

  return [
    ...(showQuickstart
      ? [
          {
            label: "Quickstart",
            path: "/quickstart",
          },
        ]
      : []),
    {
      label: "Features",
      path: "/features",
      extra: [
        {
          label: "Features",
          path: "/features",
        },
        {
          label: "Flags",
          path: "/flags",
        },
      ],
    },
    {
      label: "Catalog",
      path: "/plans",
      extra: [
        { label: "Plans", path: "/plans" },
        {
          label: "Add Ons",
          path: "/plan-add-ons",
        },
        ...(planConfigurationFlag
          ? [
              {
                label: "Configuration",
                path: "/plan-configuration",
              },
            ]
          : []),
      ],
    },
    {
      label: "Companies",
      path: "/companies",
      extra: [
        {
          label: "Companies",
          path: "/companies",
        },
        {
          label: "Users",
          path: "/users",
        },
      ],
    },
    {
      label: "Events",
      path: "/events",
    },
    ...(canViewComponents
      ? [
          {
            label: "Components",
            path: "/components",
          },
        ]
      : []),
  ];
};
