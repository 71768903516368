import { FormikControl } from "@components/forms/FormikControl";
import { Alert } from "@components/ui/Alert";
import { Button, ButtonProps } from "@components/ui/Button";
import { FormRow } from "@components/ui/FormParts";
import { Switch } from "@components/ui/Switch";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { StripeIntegrationDeleteOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationDeleteOverlay";
import { StripeIntegrationInstallOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationInstallOverlay";
import { StripeIntegrationInstallOverlayV2 } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationInstallOverlayV2";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useMutation } from "@tanstack/react-query";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Toast } from "@ui/Toast";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

export const stripeApiKeySchema = Yup.string()
  .matches(/^(sk_|sk_test|rk_|rk_test)/, {
    message: (
      <>
        API key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          sk_
        </span>{" "}
        or
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          rk_
        </span>
      </>
    ),
    excludeEmptyString: true,
  })
  .test("is-valid-key", "API key must not start with 'pk_'", (value) => {
    return !/^pk_/.test(value || "");
  })
  .required("API key is required");

export const stripePublicApiKeySchema = Yup.string()
  .matches(/^(pk_|pk_test)/, {
    message: (
      <>
        Public API key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          pk_
        </span>{" "}
        or
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          pk_test_
        </span>
      </>
    ),
    excludeEmptyString: true,
  })
  .test("is-valid-key", "API key must not start with 'sk_'", (value) => {
    return !/^sk_/.test(value || "");
  })
  .required("Public API key is required");

export const StripeIntegrationForm = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
  });

  const [searchParam, setSearchParam] = useSearchParams();
  const [connectV2LiveMode, setConnectV2LiveMode] = useState<boolean>(false);
  const [legacyTestAccount, setLegacyTestAccount] = useState<boolean>(false);

  const connectStripeAccount = useMutation({
    mutationKey: ["connectStripeAccount"],
    mutationFn: integrationsApi.installStripeIntegrationV2,
  });

  const customerImportEnabled = useSchematicFlag(
    "integration.stripe.customers.import",
    {
      fallback: true,
    },
  );

  const v2Enabled = useSchematicFlag("integration.stripe.v2.local", {
    fallback: true,
  });

  const [refreshedIntegrationData, setRefreshedIntegrationData] = useState<
    boolean | null
  >(false);

  const refreshIntegrationMutation = useMutation({
    mutationFn: integrationsApi.refreshIntegrationData,
    onMutate: async () => {
      setRefreshedIntegrationData(true);
    },
  });

  const [removeIntegration, setRemoveIntegration] = useState<string | null>(
    null,
  );

  const [showCustomerImportInstallFlow, setShowCustomerImportInstallFlow] =
    useState<boolean>(false);

  const [showCustomerImportInstallFlowV2, setShowCustomerImportInstallFlowV2] =
    useState<boolean>(false);

  const [stripeIntegrationSecretKey, setStripeIntegrationSecretKey] = useState<
    string | null
  >(null);

  const [stripeIntegrationPublicKey, setStripeIntegrationPublicKey] = useState<
    string | null
  >();

  const installIntegrationValidationSchema = Yup.object({
    api_key: stripeApiKeySchema,
    public_key: stripePublicApiKeySchema,
  });

  const initialValues = {
    api_key: "",
    public_key: "",
  };

  const installIntegrationMutation = useMutation({
    mutationFn: integrationsApi.installIntegration,
  });

  useEffect(() => {
    const success = searchParam.get("result") === "success";
    if (success) {
      if (v2Enabled) {
        setShowCustomerImportInstallFlowV2(true);
      } else {
        setShowCustomerImportInstallFlow(true);
      }
    }
  }, [searchParam]);

  if (getIntegrations.isPending) {
    return <div></div>;
  }

  const callStripeInstallOverlayV2 = async () => {
    setShowCustomerImportInstallFlowV2(true);
    if (customerImportEnabled) {
      setShowCustomerImportInstallFlowV2(true);
    } else {
      await installIntegrationMutation.mutateAsync({
        type: "stripe",
      });
      getIntegrations.refetch();
    }
  };

  const callStripeInstallOverlay = async (props: {
    api_key: string;
    public_key: string;
  }) => {
    if (customerImportEnabled) {
      setStripeIntegrationSecretKey(props.api_key);
      setStripeIntegrationPublicKey(props.public_key);
      setShowCustomerImportInstallFlow(true);
    } else {
      await installIntegrationMutation.mutateAsync({
        type: "stripe",
        config: {
          api_key: props.api_key,
          public_key: props.public_key,
        },
      });
      getIntegrations.refetch();
    }
  };

  if (getIntegrations.data?.length === 0 && !v2Enabled) {
    return (
      <div>
        {showCustomerImportInstallFlow && (
          <StripeIntegrationInstallOverlay
            onClose={() => {
              getIntegrations.refetch();
              searchParam.delete("result");
              setSearchParam(searchParam);
              setShowCustomerImportInstallFlow(false);
            }}
            stripeApiKey={stripeIntegrationSecretKey!}
            stripePublicKey={stripeIntegrationPublicKey!}
          />
        )}
        <Formik
          className="flex flex-col"
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={callStripeInstallOverlay}
          validationSchema={installIntegrationValidationSchema}
        >
          {(form) => {
            const keyValue: string = form.getFieldMeta("api_key")
              .value as string;

            const rkKey = keyValue.startsWith("rk_");

            return (
              <Form>
                <FormRow className="items-start">
                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Stripe API key"
                      name="api_key"
                      placeholder="Enter API key to connect"
                      type="text"
                      description={
                        <>
                          Use a Stripe {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://docs.stripe.com/keys#obtain-api-keys`}
                            target="_blank"
                          >
                            Secret Key or Restricted Key
                          </Link>
                        </>
                      }
                    />

                    {rkKey && (
                      <Alert size="xs" style="yellow" className="mt-4">
                        {" "}
                        Because you are using a{" "}
                        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
                          rk_
                        </span>{" "}
                        key, permisions must be added manually to this key
                      </Alert>
                    )}
                  </div>

                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Stripe Publishable API key"
                      name="public_key"
                      placeholder="Enter Publishable API key to connect"
                      type="text"
                      description={
                        <>
                          Use a Stripe {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://docs.stripe.com/keys#obtain-api-keys`}
                            target="_blank"
                          >
                            Publishable Key
                          </Link>
                        </>
                      }
                    />
                  </div>

                  <Button
                    type="submit"
                    className="mt-[25px]"
                    color="blue"
                    size="lg"
                    disabled={!form.dirty || !form.isValid}
                  >
                    Connect to Stripe
                  </Button>
                </FormRow>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  if (
    (getIntegrations.data?.length === 0 && v2Enabled) ||
    (getIntegrations.data?.length === 1 &&
      getIntegrations.data?.[0].config.onboard_url?.length > 0)
  ) {
    return (
      <div className={"flex flex-col w-full"}>
        <div className={"flex flex-row w-full gap-5 justify-start space-y-4"}>
          <Button
            onClick={async () => {
              const response = await connectStripeAccount.mutateAsync({
                type: "stripe_v2",
                config: {},
                live_mode: !connectV2LiveMode,
                is_sandbox: !legacyTestAccount,
                company_matching_criteria: "manual_upsert",
              });

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              window.open(response?.config.config.onboard_url);
            }}
            disabled={connectStripeAccount.isPending}
            color="blue"
            className="mt-4"
          >
            Connect Stripe
          </Button>

          <div className="flex flex-row items-center justify-center gap-1">
            <div className="h-full flex flex-col justify-center">
              <Switch
                label={<span className="font-medium text-sm">Test Mode:</span>}
                checked={connectV2LiveMode}
                value={connectV2LiveMode ? "false" : "true"}
                onCheckedChange={(value) => {
                  setConnectV2LiveMode(value);
                }}
              />
            </div>
          </div>
        </div>
        {connectV2LiveMode && (
          <div className="border border-blue-200 rounded-md p-4 bg-blue-50 mt-4">
            <div className="flex justify-between items-start">
              <div className="flex items-center space-x-2">
                <input
                  id="legacy-test-account-checkbox"
                  type="checkbox"
                  checked={legacyTestAccount}
                  onChange={() => setLegacyTestAccount(!legacyTestAccount)}
                  className="w-4 h-4 text-blue-600 mr-3 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mt-1"
                />
                <div>
                  <label
                    htmlFor="legacy-test-account-checkbox"
                    className="text-sm font-medium text-gray-900 cursor-pointer"
                  >
                    Connect legacy test account
                  </label>
                  <p className="text-xs text-gray-500 mt-1 max-w-md">
                    Use this option if you need to connect to older test
                    environments or pre-existing test data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const getIntegrationControllButtons = (): ButtonProps[] => {
    const props: ButtonProps[] = [
      {
        onClick: () => setRemoveIntegration(getIntegrations.data![0].id),
        children: <>Remove connection</>,
      },
    ];
    if (getIntegrations.data![0].state === "active") {
      props.push({
        onClick: () =>
          refreshIntegrationMutation.mutateAsync(getIntegrations.data![0].id),
        size: "sm",
        color: "white",
        disabled: refreshIntegrationMutation.isPending,
        children: <>Refresh data</>,
      });
    }
    if (getIntegrations.data![0].state === "pending") {
      props.push({
        onClick: () => callStripeInstallOverlayV2(),
        size: "sm",
        color: "white",
        disabled: refreshIntegrationMutation.isPending,
        children: <>Start data import</>,
      });
    }

    return props;
  };

  return (
    <div className="flex items-stretch space-x-12">
      <div>
        {showCustomerImportInstallFlowV2 && (
          <StripeIntegrationInstallOverlayV2
            integrationId={getIntegrations.data![0].id}
            onClose={() => {
              getIntegrations.refetch();
              searchParam.delete("result");
              setSearchParam(searchParam);
              setShowCustomerImportInstallFlowV2(false);
            }}
          />
        )}

        {removeIntegration && (
          <StripeIntegrationDeleteOverlay
            onClose={() => {
              setRemoveIntegration(null);
              getIntegrations.refetch();
            }}
            integrationId={getIntegrations.data![0].id}
          />
        )}

        <ButtonGroup buttons={getIntegrationControllButtons()}></ButtonGroup>

        <Toast
          title={
            <div className="flex space-x-3 items-center">
              <img
                alt="Schematic Loader"
                src="/schematic-load.gif"
                className=" select-none -ml-2 w-6 h-6 inline-block"
              />
              <div>Integration data is being refreshed</div>
            </div>
          }
          open={refreshedIntegrationData!}
          setOpen={(state) => {
            setRefreshedIntegrationData(state);
          }}
        ></Toast>
      </div>
    </div>
  );
};
