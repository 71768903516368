import { patch } from "@data/index";
export type UpdateAccountSettings = {
  companyName: string;
  orgWebsite: string;
};

export const updateAccountSettingsReq = async (
  payload: UpdateAccountSettings,
): Promise<void> => {
  return patch<void, UpdateAccountSettings>("accounts/settings", payload);
};
