import { useContextQuery } from "@hooks/useContextQuery";
import {
  BillingPriceResponseData,
  ChangeSubscriptionInternalRequestBody,
} from "@models/api";
import { Plan } from "@models/plan";
import { Coupon } from "@models/subscription";
import {
  PayAsYouGoEntitlement,
  PayInAdvanceEntitlement,
} from "@modules/companies/components/overlays/CompanyManagePlanOverlay/CompanyWithSubscriptionManagePlanOverlay";
import { previewCheckout } from "@modules/companies/queries";
import { AddOnPriceWithAddOnName } from "@modules/companies/types";
import { Alert } from "@ui/Alert";
import { Icon } from "@ui/Icon";
import {
  capitalize,
  formatCurrency,
  formatOrdinal,
  getMonthName,
} from "@utils/strings";
import React from "react";

const shortPeriod = (p: string) => (p === "month" ? "mo" : "yr");

type PlanComparisonProps = {
  companyId: string;
  currentPlan: Plan;
  selectedPlan?: Plan;
  currentAddOns: AddOnPriceWithAddOnName[];
  selectedAddOns: AddOnPriceWithAddOnName[];
  selectedPrice?: BillingPriceResponseData;
  currentPrice: BillingPriceResponseData;
  payInAdvance: PayInAdvanceEntitlement[];
  payAsYouGo: PayAsYouGoEntitlement[];
  coupon?: Coupon;
};

export const PlanComparison = ({
  companyId,
  currentPlan,
  selectedPlan,
  currentAddOns,
  selectedAddOns,
  selectedPrice,
  currentPrice,
  payInAdvance,
  payAsYouGo,
  coupon,
}: PlanComparisonProps) => {
  const { data, isLoading } = useContextQuery({
    queryKey: [
      "checkout",
      "preview",
      "newPriceId",
      selectedPrice?.id,
      "newPlanId",
      selectedPlan?.id,
      "addOnIds",
      selectedAddOns.flatMap((addOn) => [addOn.addOnId, addOn.id]),
      "payInAdvance",
      JSON.stringify(
        payInAdvance.map(({ priceId, quantity }) => ({
          priceId,
          quantity,
        })),
      ),
      "coupon",
      coupon?.externalId,
    ],
    queryFn: () => {
      const req: ChangeSubscriptionInternalRequestBody = {
        companyId,
        addOnIds: selectedAddOns.map((addOn) => ({
          addOnId: addOn.addOnId,
          priceId: addOn.id,
        })),
        newPlanId: selectedPlan!.id,
        newPriceId: selectedPrice!.id,
        payInAdvance: payInAdvance.map(({ priceId, quantity }) => ({
          priceId,
          quantity,
        })),
        couponExternalId: coupon?.externalId,
      };

      return previewCheckout(req);
    },
    retry: false,
    enabled: !!selectedPlan && !!selectedPrice,
  });
  const charges = data?.finance;

  const willChangePlan = selectedPlan && selectedPlan.id !== currentPlan?.id;
  const willChangePlanOrPrice =
    selectedPlan &&
    (selectedPlan.id !== currentPlan?.id ||
      (selectedPrice && currentPrice.id !== selectedPrice.id));

  const removedAddOns = currentAddOns.filter(
    (current) => !selectedAddOns.some((selected) => current.id === selected.id),
  );
  const willAddOnsChange =
    removedAddOns.length > 0 || selectedAddOns.length > 0;

  const totalPrice = selectedPrice
    ? (selectedPrice.price ?? Number(selectedPrice.priceDecimal)) +
      selectedAddOns.reduce((acc, addOn) => {
        if (addOn.interval == selectedPrice.interval) {
          acc += addOn.price || 0;
        }

        return acc;
      }, 0)
    : 0;

  const currency = selectedPrice?.currency || "USD";

  return (
    <>
      {isLoading || !selectedPlan || !selectedPrice ? (
        <div role="status" className="w-full h-full bg-[#FAFAFA] animate-pulse">
          <div className="space-y-12 p-12">
            <div className="space-y-10">
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  className="flex flex-1 items-center justify-between space-x-4 w-full"
                  key={index}
                >
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row space-x-2">
                      <div className="h-5 bg-gray-300 rounded w-24"></div>
                    </div>
                    <div className="h-3 bg-gray-300 rounded w-44"></div>
                  </div>
                  <div className="h-3 bg-gray-300 rounded w-20"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-8 bg-[#FAFAFA]">
          <div className="flex flex-col px-12 py-6 border-t border-slate-200">
            <div className="flex flex-col">
              <div className="text-sm font-normal text-black opacity-50">
                Plan
              </div>
              <div className="flex flex-col">
                {currentPlan && (
                  <div
                    className={`flex justify-between items-center ${
                      willChangePlanOrPrice ? "opacity-60 line-through" : ""
                    }`}
                  >
                    <div className="font-display text-lg font-extrabold text-black">
                      {currentPlan.name}
                    </div>
                    <div className="text-base font-normal text-black">
                      {formatCurrency(
                        currentPrice.price,
                        currentPrice.currency,
                        currentPrice.priceDecimal,
                      )}
                      /{shortPeriod(currentPrice.interval)}
                    </div>
                  </div>
                )}

                {willChangePlanOrPrice && (
                  <div>
                    <div className="w-full text-left opacity-50 my-1">
                      <Icon
                        name="arrow-down"
                        className="inline-block text-black"
                      />
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="font-display text-lg font-extrabold text-black">
                        {selectedPlan.name}
                      </div>
                      <div className="text-base font-normal text-black">
                        {formatCurrency(
                          selectedPrice.price ?? 0,
                          selectedPrice.currency,
                          selectedPrice.priceDecimal,
                        )}
                        /{shortPeriod(selectedPrice.interval)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {willAddOnsChange && (
              <div className="flex flex-col mt-4">
                <div className="text-sm font-normal text-black opacity-50">
                  Add Ons
                </div>
                <div className="flex flex-col pt-1">
                  {removedAddOns.map((addOn) => (
                    <div
                      className="flex justify-between items-center opacity-60 line-through"
                      key={addOn.id}
                    >
                      <div className="font-display text-lg font-extrabold text-black">
                        {addOn.addOnName}
                      </div>
                      <div className="text-base font-normal text-black">
                        {formatCurrency(
                          addOn.price,
                          addOn.currency,
                          addOn.priceDecimal,
                        )}
                        /{shortPeriod(addOn.interval)}
                      </div>
                    </div>
                  ))}

                  {selectedAddOns.map((addOn) => (
                    <div
                      className="flex justify-between items-center"
                      key={addOn.id}
                    >
                      <div className="font-display text-lg font-extrabold text-black">
                        {addOn.addOnName}
                      </div>
                      <div className="text-base font-normal text-black">
                        {formatCurrency(
                          addOn.price ?? 0,
                          addOn.currency,
                          addOn.priceDecimal,
                        )}
                        /{shortPeriod(addOn.interval)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {(payInAdvance.length > 0 || payAsYouGo.length > 0) && (
              <div className="flex flex-col mt-4">
                <div className="text-sm font-normal text-black opacity-50">
                  Usage-Based
                </div>
                <div className="flex flex-col pt-1 space-y-1">
                  {payInAdvance.map((entitlement) => (
                    <div
                      className="flex justify-between items-center"
                      key={entitlement.featureId}
                    >
                      <div className="font-display text-lg font-extrabold text-black">
                        {entitlement.quantity}{" "}
                        {entitlement.featureUsage?.feature?.name ??
                          "Unnamed feature"}
                      </div>
                      <div className="text-base font-normal text-black">
                        {formatCurrency(
                          entitlement.quantity *
                            (entitlement.meteredPrice?.price ?? 0),
                          entitlement.meteredPrice?.currency,
                          entitlement.meteredPrice?.priceDecimal
                            ? (
                                entitlement.quantity *
                                Number.parseFloat(
                                  entitlement.meteredPrice?.priceDecimal,
                                )
                              ).toString()
                            : "",
                        )}
                        /
                        {shortPeriod(
                          entitlement.meteredPrice?.interval ?? "month",
                        )}
                      </div>
                    </div>
                  ))}

                  {payAsYouGo.map((entitlement) => (
                    <div
                      className="flex justify-between items-center"
                      key={entitlement.featureId}
                    >
                      <div className="font-display text-lg font-extrabold text-black">
                        {`${formatCurrency(
                          entitlement.meteredPrice?.price ?? -1,
                          entitlement.meteredPrice?.currency,
                          entitlement.meteredPrice?.priceDecimal,
                        )} per ${capitalize(
                          entitlement.featureUsage?.feature?.name ?? "",
                        )}`}
                      </div>
                      <div className="flex flex-col">
                        <div className="text-base font-normal text-black ml-auto">
                          {formatCurrency(
                            (entitlement.featureUsage?.usage ?? 0) *
                              (entitlement.meteredPrice?.price ??
                                Number(
                                  entitlement.meteredPrice?.priceDecimal,
                                ) ??
                                0),
                            entitlement.meteredPrice?.currency,
                          )}
                        </div>
                        <div className="text-sm font-light text-black">
                          {entitlement.featureUsage
                            ? `${entitlement.featureUsage.usage} ${
                                entitlement.featureUsage?.feature?.name ?? ""
                              } this period`
                            : ""}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!!charges?.proration && (
              <div className="flex flex-col mt-4">
                <div className="text-sm font-normal text-black opacity-50">
                  {charges.proration > 0 ? "Proration" : "Credits"}
                </div>
                {currentPlan && (
                  <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                      <div className="font-display text-lg font-extrabold text-black">
                        Unused time with {currentPlan.name}
                      </div>
                      <div className="text-base font-normal text-black">
                        {charges.proration > 0 ? "-" : ""}
                        {formatCurrency(charges.proration, currency)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col relative w-full space-y-4 px-12 py-6 border-t border-slate-200">
            {coupon && (
              <>
                <div className="flex justify-between items-center">
                  <div className="text-sm font-normal text-black opacity-60">
                    Discount:
                  </div>
                  <div className="text-sm font-normal text-black border rounded border-gray-300 p-0.5">
                    {coupon.name.toUpperCase()}
                  </div>
                </div>

                {charges?.percentOff !== undefined &&
                  charges.percentOff > 0 && (
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-normal text-black opacity-60">
                        {charges.percentOff}% off:
                      </div>
                      <div className="text-base font-normal text-black">
                        {formatCurrency(
                          (charges.newCharges * charges.percentOff) / 100,
                          currency,
                        )}
                      </div>
                    </div>
                  )}

                {charges?.amountOff !== undefined && charges.amountOff > 0 && (
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-normal text-black opacity-60">
                      {charges.amountOff} off:
                    </div>
                    <div className="text-base font-normal text-black">
                      {formatCurrency(charges.amountOff, currency)}
                    </div>
                  </div>
                )}
              </>
            )}

            {selectedPlan && selectedPrice && (
              <div className="flex justify-between items-center">
                <div className="text-sm font-normal text-black opacity-60">
                  {selectedPrice.interval === "month" ? "Monthly" : "Yearly"}{" "}
                  total:
                </div>
                <div className="text-base font-normal text-black">
                  {formatCurrency(totalPrice, currency)}/
                  {shortPeriod(selectedPrice.interval)}
                </div>
              </div>
            )}

            {charges && (
              <div className="flex justify-between items-center">
                <div className="text-sm font-normal text-black opacity-60">
                  Due today:
                </div>
                <div className="text-base font-normal text-black">
                  {formatCurrency(Math.max(0, charges.dueNow), currency)}
                </div>
              </div>
            )}

            {charges?.dueNow !== undefined && charges.dueNow < 0 && (
              <div className="flex justify-between items-center">
                <div className="text-sm font-normal text-black opacity-60">
                  Credits to be applied to future invoices:
                </div>
                <div className="text-base font-normal text-black">
                  {formatCurrency(Math.abs(charges.dueNow), currency)}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col relative w-full space-y-4 px-12 py-4 border-t border-b border-slate-200">
            <div className="text-sm font-normal text-black opacity-60">
              {selectedPrice &&
                `Company will be billed ${formatCurrency(
                  totalPrice,
                  currency,
                )} for this subscription
                every ${selectedPrice.interval} ${
                  charges?.periodStart
                    ? `on the ${formatOrdinal(charges.periodStart.getDate())}`
                    : ""
                } ${
                  selectedPrice.interval === "year" && charges?.periodStart
                    ? `of ${getMonthName(charges.periodStart)}`
                    : ""
                } unless they unsubscribe.`}
            </div>
          </div>

          {willChangePlan &&
            data?.usageViolations !== undefined &&
            data.usageViolations?.length > 0 && (
              <Alert size="md" style="yellow" className="m-12 space-y-2">
                <div>
                  Switching to {selectedPlan.name} would violate following
                  feature usage:{" "}
                </div>
                <div className="flex flex-col space-y-0.5 pl-2">
                  {data.usageViolations.map((usage) => (
                    <div key={usage.entitlementId}>
                      • Feature{" "}
                      <span className="font-bold">
                        {usage.feature?.name ?? "Unknown feature name"}
                      </span>{" "}
                      - usage{" "}
                      <span className="font-bold">
                        {usage.usage}/{usage.allocation}
                      </span>
                    </div>
                  ))}
                </div>
              </Alert>
            )}
        </div>
      )}
    </>
  );
};
