import { Dispatch, SetStateAction, useEffect } from "react";

// Sign in Interfaces/Types:
export enum SignInStepName {
  SIGN_IN = "sign_in",
  PHONE = "phone",
  OTP = "otp-phone",
  SOCIAL = "social",
  RESET = "reset",
}

export type FormErrorSetAction = any;
export type FormSignInSteps = `${SignInStepName}`;
export type FormSignInSetAction = Dispatch<SetStateAction<FormSignInSteps>>;

export interface FormSignInProps {
  setStep: FormSignInSetAction;
  setError?: FormErrorSetAction;
}

// Sign up Interfaces/Types:
export enum SignUpStepName {
  SIGN_UP = "sign-up",
  OTP_PHONE = "otp-phone",
  OTP_EMAIL = "otp-email",
  DONE = "done",
}

export type FormSignUpSteps = `${SignUpStepName}`;
export type FormSignUpSetAction = Dispatch<SetStateAction<FormSignUpSteps>>;

export interface FormSignUpProps {
  setStep: FormSignUpSetAction;
  setError?: FormErrorSetAction;
}

// Use Script hook:
export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
