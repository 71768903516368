import { useSignIn, useSignUp } from "@clerk/clerk-react";
import { Button } from "@components/ui/Button";
import { Icon } from "@components/ui/Icon";

interface SocialFormProps {
  setError: any;
  type: "sign-in" | "sign-up";
}

export const SocialForm = ({ setError, type }: SocialFormProps) => {
  const { isLoaded: isSignInLoaded, signIn } = useSignIn();
  const { isLoaded: isSignUpLoaded, signUp } = useSignUp();

  const handleGoogleSignUp = async () => {
    if (!isSignUpLoaded) return;

    try {
      await signUp.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/",
        redirectUrlComplete: "/",
      });
    } catch (err: any) {
      setError("Failed to sign up with Google");
    }
  };

  const handleGithubSignUp = async () => {
    if (!isSignUpLoaded) return;

    try {
      await signUp.authenticateWithRedirect({
        strategy: "oauth_github",
        redirectUrl: "/",
        redirectUrlComplete: "/",
      });
    } catch (err: any) {
      setError("Failed to sign up with Github");
    }
  };

  const handleGoogleSignIn = async () => {
    if (!isSignInLoaded) return;

    try {
      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/", // Where to redirect after a successful sign-in
        redirectUrlComplete: "/", // Fallback redirect URL if needed
      });
    } catch (err: any) {
      setError("Failed to sign in with Google");
    }
  };

  const handleGithubSignIn = async () => {
    if (!isSignInLoaded) return;

    try {
      await signIn.authenticateWithRedirect({
        strategy: "oauth_github",
        redirectUrl: "/",
        redirectUrlComplete: "/",
      });
    } catch (err: any) {
      setError("GitHub Sign-In failed");
    }
  };

  const isSignIn = type === "sign-in";
  const isSignUp = type === "sign-up";

  const handleGoogle = () => {
    isSignIn && handleGoogleSignIn();
    isSignUp && handleGoogleSignUp();
  };

  const handleGithub = () => {
    isSignIn && handleGithubSignIn();
    isSignUp && handleGithubSignUp();
  };

  return (
    <div className="flex flex-col space-y-3 text-center w-full">
      <div className="relative font-display text-lg mt-8 leading-none mb-6 border-t border-gray-300 my-12">
        <span className="bg-white px-4 absolute left-[50%] translate-x-[-50%] translate-y-[-55%] top-[50%] leading-none text-sm">
          or
        </span>
      </div>
      <div className="flex flex-col space-y-4">
        <Button
          onClick={handleGoogle}
          className="text-white px-4 py-2 rounded items-center inline-flex justify-center hover:bg-blue-400 hover:text-white"
          color="white"
        >
          <Icon
            name="google"
            className="text-xl inline-block leading-[.9em] scale-125 mr-2"
          />
          <span>Continue with Google</span>
        </Button>
        <Button
          onClick={handleGithub}
          className=" text-white px-4 py-2 rounded"
          color="white"
        >
          <Icon
            name="github"
            className="text-xl inline-block leading-[.85em] scale-125 mr-2"
          />
          <span>Continue with Github</span>
        </Button>
      </div>
    </div>
  );
};
