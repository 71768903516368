import { useNode, UserComponent } from "@craftjs/core";
import {
  Checkbox,
  Select,
  TextInput,
  Toggle,
} from "@modules/components/components/builder/ui";
import {
  UnsubscribeButton as EmbedUnsubscribeButton,
  type ButtonSizeTypes,
  type UnsubscribeButtonProps,
} from "@schematichq/schematic-components";
import * as Settings from "../../controls/Sidebar/Settings";

export const UnsubscribeButton: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as UnsubscribeButtonProps,
  }));

  return (
    <EmbedUnsubscribeButton
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const UnsubscribeButtonSettings = () => {
  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as UnsubscribeButtonProps,
  }));

  const buttonSizeOptions: { value: ButtonSizeTypes; label: string }[] = [
    { value: "sm", label: "Small" },
    { value: "md", label: "Medium" },
    { value: "lg", label: "Large" },
  ];

  const buttonStyleOptions: {
    value: "primary" | "secondary" | "tertiary";
    label: string;
  }[] = [
    { value: "primary", label: "Primary" },
    { value: "secondary", label: "Secondary" },
    { value: "tertiary", label: "Tertiary" },
  ];

  return (
    <Settings.Root title="⛔️ Unsubscribe" category="Billing">
      <Settings.Section>
        <Settings.Input label="Text">
          <TextInput
            className="w-36"
            textAlign="left"
            value={nodeProps.button.text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProp((props: UnsubscribeButtonProps) => {
                props.button.text = event.target.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonStyleOptions}
            rawValue={nodeProps.button.style as string}
            value={buttonStyleOptions.find(
              (opt) => opt.value === nodeProps.button.style,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: UnsubscribeButtonProps) => {
                props.button.style = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Size">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonSizeOptions}
            rawValue={nodeProps.button.size as string}
            value={buttonSizeOptions.find(
              (opt) => opt.value === nodeProps.button.size,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: UnsubscribeButtonProps) => {
                props.button.size = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Full Width">
          <Checkbox
            checked={nodeProps.button.fullWidth}
            onChange={() => {
              setProp((props: UnsubscribeButtonProps) => {
                props.button.fullWidth = !props.button.fullWidth;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Alignment">
          <Toggle
            value={nodeProps.button.alignment}
            onChange={(value) => {
              setProp((props: UnsubscribeButtonProps) => {
                if (
                  value !== "start" &&
                  value !== "center" &&
                  value !== "end"
                ) {
                  return;
                }

                props.button.alignment = value;
              });
            }}
            options={[
              // TODO: Add icons for labels
              {
                label: "Left",
                value: "start",
              },
              {
                label: "Center",
                value: "center",
              },
              {
                label: "Right",
                value: "end",
              },
            ]}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

UnsubscribeButton.craft = {
  displayName: "Unsubscribe",
  props: {
    button: {
      text: "Unsubscribe",
      style: "primary",
      size: "md",
      fullWidth: true,
      alignment: "center",
    },
  } satisfies UnsubscribeButtonProps,
  related: {
    settings: UnsubscribeButtonSettings,
  },
};
