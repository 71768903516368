import { useEditor } from "@craftjs/core";
import { pointerWithin, DndContext, type DragEndEvent } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { type EmbedSettings } from "@schematichq/schematic-components";
import { type EmbedContextProps } from "@schematichq/schematic-components";
import { useCallback, useMemo } from "react";
import ReactSelect, {
  components,
  type ActionMeta,
  type GroupBase,
  type MultiValueProps,
  type MultiValueRemoveProps,
  type OnChangeValue,
  type Props,
  type StylesConfig,
} from "react-select";
import AsyncReactSelect, { type AsyncProps } from "react-select/async";

export interface SelectOption {
  value: string;
  label: string;
}

const createStyles = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  settings: EmbedSettings,
): StylesConfig<Option, IsMulti, Group> => ({
  control: (styles) => ({
    ...styles,
    height: "100%",
    minHeight: "auto",
    borderColor: "#E2E5E9",
  }),
  valueContainer: (styles, props) => ({
    ...styles,
    padding: props.isMulti ? "0.5rem" : "0 0.25rem",
  }),
  singleValue: (styles, props) => {
    // @ts-expect-error: unknown option value
    if (!props.rawValue) {
      return styles;
    }

    const { fontFamily, fontWeight } =
      // @ts-expect-error: unknown option value
      settings.theme.typography[rawValue] || {};

    return {
      ...styles,
      fontFamily,
      fontWeight,
    };
  },
  multiValue: (styles) => ({
    ...styles,
    zIndex: 999999,
    padding: "0.125rem 0.5rem",
    borderRadius: "9999px",
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "0 0.25rem",
    color: "#667085",
  }),
  option: (styles, props) => {
    const { fontFamily, fontWeight } =
      // @ts-expect-error: unknown option value
      settings.theme.typography[props.data.value] || {};

    return {
      ...styles,
      fontFamily,
      fontWeight,
      padding: "0.25rem 0.75rem",
    };
  },
});

interface SettingsSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  rawValue?: unknown;
}

export const Select = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  options = [],
  value,
  rawValue,
  onChange,
  ...rest
}: SettingsSelectProps<Option, IsMulti, Group>) => {
  const { settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={createStyles<Option, IsMulti, Group>(settings)}
      {...rest}
    />
  );
};

interface SettingsAsyncProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends AsyncProps<Option, IsMulti, Group> {
  rawValue?: unknown;
}

export const AsyncSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  options = [],
  value,
  rawValue,
  onChange,
  ...rest
}: SettingsAsyncProps<Option, IsMulti, Group>) => {
  const { settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <AsyncReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={createStyles<Option, IsMulti, Group>(settings)}
      {...rest}
    />
  );
};

const MultiValue = (
  props: MultiValueProps<SelectOption, true, GroupBase<SelectOption>>,
) => {
  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.data.value,
      transition: {
        duration: 100,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
};

const MultiValueRemove = (
  props: MultiValueRemoveProps<SelectOption, true, GroupBase<SelectOption>>,
) => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        onPointerDown: (event) => {
          event.stopPropagation();
        },
        ...props.innerProps,
      }}
    />
  );
};

export const SortableMultiSelect = ({
  components,
  isMulti,
  onChange,
  options = [],
  value,
  ...props
}: Props<SelectOption, true, GroupBase<SelectOption>>) => {
  function isOption(
    option: SelectOption | GroupBase<SelectOption>,
  ): option is SelectOption {
    return "value" in option && option.value !== "all";
  }

  const handleChange = useCallback(
    (
      newValue: OnChangeValue<SelectOption, true>,
      actionMeta: ActionMeta<SelectOption>,
    ) => {
      const isAllSelected = newValue.some(
        (option) => "value" in option && option.value === "all",
      );
      const updated = isAllSelected ? options.filter(isOption) : [...newValue];
      onChange?.(updated, actionMeta);
    },
    [options, onChange],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!active || !over || !Array.isArray(value)) {
        return;
      }

      const from = value.findIndex((option) => option.value === active.id);
      const to = value.findIndex((option) => option.value === over.id);
      const updated = arrayMove(value, from, to);
      onChange?.(updated, {
        // TODO: Add more specific action
        action: "select-option",
        option: undefined,
        name: "reorder",
      });
    },
    [value, onChange],
  );

  const items = useMemo(() => {
    return options.reduce((acc: string[], option) => {
      if (isOption(option)) {
        acc.push(option.value);
      }

      return acc;
    }, []);
  }, [options]);

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      onDragEnd={handleDragEnd}
      collisionDetection={pointerWithin}
    >
      <SortableContext items={items}>
        <Select
          isMulti
          options={options}
          value={value}
          onChange={handleChange}
          components={{
            MultiValue,
            MultiValueRemove,
            ...components,
          }}
          closeMenuOnSelect={false}
          {...props}
        />
      </SortableContext>
    </DndContext>
  );
};
