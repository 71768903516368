import { CheckoutDataResponseData } from "@models/api";
import { PriceBehaviourType } from "@models/entitlement";

export const buildManagePlanFormValues = (
  checkoutData: CheckoutDataResponseData,
) => {
  const planProduct = checkoutData.subscription!.products.find(
    (product) =>
      product.id === checkoutData.activePlan?.billingProduct?.productId,
  )!;

  const currentPrice = {
    id: planProduct.priceId,
    price: planProduct.price,
    interval: planProduct.interval,
    externalPriceId: planProduct.priceExternalId,
    currency: planProduct.currency,
    scheme: planProduct.billingScheme,
  };

  const selectedPrice =
    checkoutData.activePlan?.id === checkoutData.selectedPlan?.id
      ? currentPrice
      : checkoutData.selectedPlan?.billingProduct?.prices.find(
          (price) => price.interval === currentPrice?.interval,
        );

  // TODO: Simplify with returning active price in addOn from api
  const addOns = checkoutData.activeAddOns.flatMap((addOn) => {
    if (!checkoutData.subscription || !addOn.billingProduct) {
      return [];
    }

    const product = checkoutData.subscription.products.find(
      (product) => product.id === addOn.billingProduct?.productId,
    );

    if (!product) {
      return [];
    }

    const priceId = product.priceId;
    const price = addOn.billingProduct.prices.find(
      (price) => price.id === priceId,
    );

    if (price) {
      return [
        {
          ...price,
          addOnName: addOn.name,
          addOnId: addOn.id,
          billingProductId: product.id,
          scheme: product.billingScheme,
        },
      ];
    }

    return [];
  });

  const entitlements = (
    checkoutData.selectedUsageBasedEntitlements ||
    checkoutData.activeUsageBasedEntitlements
  ).map((entitlement) => {
    const featureUsage = checkoutData.featureUsage?.features.find(
      (feature) => feature.feature?.id === entitlement.featureId,
    );

    return {
      featureUsage,
      ...entitlement,
    };
  });

  const payInAdvance = entitlements
    .filter(
      (entitlement) =>
        entitlement.priceBehavior === PriceBehaviourType.PayInAdvance,
    )
    .map((entitlement) => ({
      ...entitlement,
      priceId: entitlement.meteredPrice!.id,
      quantity: entitlement.featureUsage?.allocation || 0,
    }));
  const payAsYouGo = entitlements.filter(
    (entitlement) =>
      entitlement.priceBehavior === PriceBehaviourType.PayAsYouGo,
  );

  const discount =
    checkoutData.subscription?.discounts &&
    checkoutData.subscription?.discounts?.length > 0
      ? checkoutData.subscription?.discounts[0]
      : undefined;

  const coupon = discount && {
    ...discount,
    name: discount.couponName,
    externalId: discount.couponId,
  };

  return {
    currentPrice,
    addOns,
    selectedPrice,
    payInAdvance,
    payAsYouGo,
    coupon,
    scheme: planProduct.billingScheme,
  };
};
