import { useSignIn } from "@clerk/clerk-react";
import { FormikControl } from "@components/forms/FormikControl";
import { Button } from "@components/ui/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormSignInProps } from "../consts";

const phoneValidationSchema = Yup.object({
  phone: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number")
    .required("Phone number is required"),
});

export const SignInPhoneForm = ({ setStep, setError }: FormSignInProps) => {
  const { isLoaded, signIn } = useSignIn();
  const handleChange = () => setStep("sign_in");

  const handlePhoneSignIn = async (values: { phone: string }) => {
    if (!isLoaded || !signIn) return;

    try {
      const { supportedFirstFactors } = await signIn.create({
        identifier: values.phone,
      });

      const phoneCodeFactor = supportedFirstFactors?.find(
        (factor) => factor.strategy === "phone_code",
      );

      if (phoneCodeFactor && "phoneNumberId" in phoneCodeFactor) {
        const { phoneNumberId } = phoneCodeFactor;

        await signIn.prepareFirstFactor({
          strategy: "phone_code",
          phoneNumberId,
        });
        setStep("otp-phone");
      } else {
        setError("OTP is not supported for this phone number.");
      }
    } catch (err: any) {
      console.error("Error:", JSON.stringify(err, null, 2));
      setError(err.errors?.[0]?.message || "Something went wrong.");
    }
  };

  return (
    <Formik
      initialValues={{ phone: "" }}
      validationSchema={phoneValidationSchema}
      onSubmit={handlePhoneSignIn}
      key="form-phone"
    >
      {({ dirty, isSubmitting }) => {
        return (
          <Form className="space-y-4">
            <div className="text-left relative">
              <FormikControl
                control="input"
                name="phone"
                type="text"
                label="Phone Number"
                placeholder="+1-800-937-8997"
                className="border rounded p-2 w-full"
              />
              <div
                className="text-blue-400 text-sm hover:underline transition-all top-0 right-0 absolute cursor-pointer"
                onClick={handleChange}
              >
                Use Email
              </div>
            </div>
            <div className="w-full flex-1 flex">
              <Button
                type="submit"
                color="blue"
                className="w-full text-center"
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
              >
                Continue
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
