import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { type CompanyResponseData } from "@models/api";
import { listCompanies } from "@modules/companies/queries";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { useFormikContext } from "formik";
import { CompanyUserValue } from "./CompanyUserEditOverlay";

type CompanyUserEditOverlayDefineStepProps = {
  verb: string;
};

type CompanyOption = Option & {
  entity: CompanyResponseData;
};

export const CompanyUserEditOverlayDefineStep = ({
  verb,
}: CompanyUserEditOverlayDefineStepProps) => {
  const { setFieldValue, values } = useFormikContext<CompanyUserValue>();

  return (
    <>
      <FormHeader
        label={`${verb} user`}
        title="Define user"
        description="Give user name and company"
      />

      <FormColumn>
        <FormRow>
          <div className="flex-1">
            <FormikControl
              control="input"
              name="name"
              type="text"
              label="User name"
              placeholder="Enter user name"
              description="A human-friendly name for your user."
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="flex-1">
            <FormikAsyncSelect
              className="flex-1"
              label="Companies"
              name="companyIds"
              placeholder="Type to select company..."
              defaultOptions
              isMulti
              loadOptions={listCompanies}
              loadOptionsMappers={{
                requestFilter: {
                  limit: 10,
                },
                mapperFunction: (company) => ({
                  value: company.id,
                  label: company.name || company.id,
                  entity: company,
                }),
              }}
              onChange={async (options: CompanyOption[]) => {
                await setFieldValue(
                  "companies",
                  options.map(({ entity }) => entity),
                );
              }}
              selectedOption={values.companies.map((company) => ({
                value: company.id,
                label: company.name || company.id,
                entity: company,
              }))}
            />
          </div>
        </FormRow>
      </FormColumn>
    </>
  );
};
