import {
  DndContext,
  closestCenter,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import cx from "classnames";
import { SortableItem } from "./SortableItem";

type directionProps = "column" | "row";

interface SortableListProps {
  items: any[];
  setItems: (newItems: any[]) => void;
  renderItem: (item: any) => React.ReactNode;
  direction: directionProps;
  className?: string;
}

// TODO: Add icons to icon font

export const SortableList = ({
  items,
  setItems,
  renderItem,
  direction = "column",
  className,
}: SortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const newOrder = arrayMove(items, oldIndex, newIndex);
      setItems(newOrder);
    }
  };

  const directionMap = {
    column: "flex flex-col pt-0.5",
    row: "flex flexrow space-x-4",
  };

  const itemStyles = cx(
    "relative",
    direction === "column"
      ? "flex flex-row items-center pl-2.5 pr-12 group  border-b border-b-gray-300 py-1.5 hover:bg-gray-300/70 last-of-type:border-b-0"
      : "",
  );

  const strategy =
    direction === "column"
      ? verticalListSortingStrategy
      : horizontalListSortingStrategy;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items.map((item) => item.id)} strategy={strategy}>
        <div
          className={cx("overflow-hidden", directionMap[direction], className)}
        >
          {items.map((item) => (
            <SortableItem key={item.id} id={item.id} className={itemStyles}>
              {direction === "column" && (
                <svg
                  width="6"
                  height="14"
                  viewBox="0 0 6 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 group-hover:opacity-100 opacity-0"
                >
                  <circle cx="1" cy="1" r="1" fill="#938A8A" />
                  <circle cx="5" cy="1" r="1" fill="#938A8A" />
                  <circle cx="1" cy="5" r="1" fill="#938A8A" />
                  <circle cx="5" cy="5" r="1" fill="#938A8A" />
                  <circle cx="1" cy="9" r="1" fill="#938A8A" />
                  <circle cx="5" cy="9" r="1" fill="#938A8A" />
                  <circle cx="1" cy="13" r="1" fill="#938A8A" />
                  <circle cx="5" cy="13" r="1" fill="#938A8A" />
                </svg>
              )}
              {renderItem(item)}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
