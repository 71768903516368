import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listBillingProductPrices } from "@data/billing";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { useContextQuery } from "@hooks/useContextQuery";
import {
  BillingCouponResponseData,
  BillingPriceResponseData,
  ChangeSubscriptionInternalRequestBody,
  CompanyDetailResponseData,
  FeatureUsageResponseData,
  UpdatePayInAdvanceRequestBody,
  UsageBasedEntitlementResponseData,
} from "@models/api";
import { Plan } from "@models/plan";
import { Coupon } from "@models/subscription";
import { PlanComparison } from "@modules/companies/components/overlays/PlanComparison";
import {
  checkout,
  getCheckoutData,
  listAddOnsPricesWithAddOnName,
  listCoupons,
} from "@modules/companies/queries";
import {
  AddOnPriceWithAddOnName,
  ProductPriceInterval,
} from "@modules/companies/types";
import { PriceOptionLabel } from "@modules/plans/components/overlays/PlanEditOverlay/PriceOptionLabel";
import { PriceIntervalDisplayName } from "@modules/plans/consts";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Logo } from "@ui/Logo";
import {
  Overlay,
  OverlayFooter,
  OverlayHeader,
  OverlayModal,
} from "@ui/Overlay";
import { Pill } from "@ui/Pill";
import { capitalize, formatCurrency } from "@utils/strings";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";
import { AddOnPriceOptionLabel } from "./AddOnPriceOptionLabel";
import { buildManagePlanFormValues } from "./helpers";
import { ManagePlanPlanFields } from "./ManagePlanPlanFields";

type CompanyWithSubscriptionManagePlanOverlayProps = {
  onClose: () => void;
  onSuccess: () => void;
  company: CompanyDetailResponseData;
};

export type PayInAdvanceEntitlement = UsageBasedEntitlementResponseData &
  UpdatePayInAdvanceRequestBody & {
    featureUsage?: FeatureUsageResponseData;
  };

export type PayAsYouGoEntitlement = UsageBasedEntitlementResponseData & {
  featureUsage?: FeatureUsageResponseData;
};

export type ManagePlanWithSubscriptionFormValues = {
  currentPlan: Plan;
  selectedPlan: Plan;
  selectedPlanId: string;
  selectedPrice?: BillingPriceResponseData;
  selectedPriceId?: string;
  currentPrice: BillingPriceResponseData;
  currentAddOns: AddOnPriceWithAddOnName[];
  selectedAddOns: AddOnPriceWithAddOnName[];
  selectedAddOnIds: string[];
  payInAdvance: PayInAdvanceEntitlement[];
  payAsYouGo: PayAsYouGoEntitlement[];
  coupon?: Coupon;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

type CouponOption = {
  name: string;
  value: Coupon;
  label: ReactNode;
};

const CouponOptionLabel = ({ coupon }: { coupon: Coupon }) => {
  return (
    <Pill
      key={coupon.externalId}
      color="gray"
      type="tag"
      text="normal"
      className="text-black font-semibold font-display items-baseline"
    >
      {coupon.percentOff
        ? `${coupon.percentOff} %`
        : `${coupon.amountOff} ${coupon.currency}`}{" "}
      - {coupon.name}
    </Pill>
  );
};

export const CompanyWithSubscriptionManagePlanOverlay = ({
  onClose,
  onSuccess,
  company,
}: CompanyWithSubscriptionManagePlanOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<
    ManagePlanWithSubscriptionFormValues | undefined
  >(undefined);

  const { data: checkoutData, isLoading: isCheckoutDataLoading } =
    useContextQuery({
      queryKey: ["checkoutData", company.id, "selectedPlanId", undefined],
      queryFn: () =>
        getCheckoutData({
          companyId: company.id,
        }),
      retry: false,
    });

  useEffect(() => {
    if (checkoutData) {
      const {
        currentPrice,
        addOns,
        selectedPrice,
        payInAdvance,
        payAsYouGo,
        coupon,
      } = buildManagePlanFormValues(checkoutData);

      setInitialValues((prevValues) => ({
        currentPlan:
          prevValues === undefined
            ? (checkoutData.activePlan as Plan)
            : prevValues.currentPlan,
        currentPrice:
          prevValues === undefined ? currentPrice : prevValues.currentPrice,
        currentAddOns:
          prevValues === undefined ? addOns : prevValues.currentAddOns,
        selectedPlan: checkoutData.selectedPlan as Plan,
        selectedPlanId: checkoutData.selectedPlan!.id,
        selectedPrice: selectedPrice,
        selectedPriceId: selectedPrice?.id,
        selectedAddOns: addOns,
        selectedAddOnIds: addOns.map(({ addOnId }) => addOnId),
        payInAdvance,
        payAsYouGo,
        coupon,
        scheme: "per_unit",
      }));
    }
  }, [checkoutData]);

  const onSubmit = async (
    values: ManagePlanWithSubscriptionFormValues,
    helpers: FormikHelpers<ManagePlanWithSubscriptionFormValues>,
  ) => {
    setLoading(true);

    try {
      const req: ChangeSubscriptionInternalRequestBody = {
        companyId: company.id,
        addOnIds: values.selectedAddOns.map((addOn) => ({
          addOnId: addOn.addOnId,
          priceId: addOn.id,
        })),
        newPlanId: values.selectedPlan.id,
        newPriceId: values.selectedPrice!.id,
        payInAdvance: values.payInAdvance.map(({ priceId, quantity }) => ({
          priceId,
          quantity,
        })),
        couponExternalId: values.coupon?.externalId,
      };

      await checkout(req);

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      onSuccess();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {(loading || isCheckoutDataLoading) && <SchematicOverlayLoader />}
      <OverlayModal>
        <OverlayHeader
          label="Manage plan"
          title={company?.name}
          onClose={onClose}
        >
          <Logo src={company.logoUrl} alt={company.name} />
        </OverlayHeader>

        {initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              selectedPlanId: Yup.string().required("Must provide a plan"),
              selectedPriceId: Yup.string().required(
                "Must provide a price for a plan",
              ),
              selectedAddOnIds: Yup.array().test(
                "no-different-interval",
                "Only addons with same price interval could be selected",
                (_, { parent }) => {
                  if (!parent.selectedPrice) {
                    return true;
                  }

                  return !(parent.selectedAddOns || []).some(
                    ({ interval }: AddOnPriceWithAddOnName) =>
                      interval !== parent.selectedPrice.interval,
                  );
                },
              ),
            })}
          >
            {({ values, setFieldValue, setFieldTouched, dirty }) => (
              <Form className="flex flex-col">
                <FormColumn className="px-12">
                  <FormRow>
                    <ManagePlanPlanFields companyId={company.id} />

                    {values.selectedPlan && (
                      <FormikAsyncSelect
                        key={`price-${values.selectedPlan.id}`}
                        className="flex-1"
                        defaultOptions
                        nullable
                        label="Price"
                        loadOptions={listBillingProductPrices}
                        loadOptionsMappers={{
                          requestFilter: {
                            billingProductId:
                              values.selectedPlan.billingProduct!.productId,
                            limit: 10,
                          },
                          mapperFunction: (
                            price: BillingPriceResponseData,
                          ): PriceOption => ({
                            name: price.price.toString(),
                            value: price.id,
                            label: <PriceOptionLabel price={price} />,
                            resource: price,
                          }),
                        }}
                        name="selectedPriceId"
                        placeholder="Select price"
                        onChange={async (option: PriceOption) => {
                          if (option) {
                            await setFieldTouched(
                              "selectedAddOnIds",
                              true,
                              true,
                            );
                          }

                          await setFieldValue(
                            "selectedPrice",
                            option?.resource || null,
                          );
                        }}
                        selectedOption={
                          values.selectedPrice
                            ? {
                                name: values.selectedPrice.price,
                                value: values.selectedPrice.id,
                                label: (
                                  <PriceOptionLabel
                                    price={values.selectedPrice}
                                  />
                                ),
                                resource: values.selectedPrice,
                              }
                            : null
                        }
                      />
                    )}
                  </FormRow>

                  {values.payInAdvance.length > 0 && (
                    <div className="flex flex-col space-y-4">
                      {values.payInAdvance.map((entitlement, index) => {
                        const price =
                          values.selectedPrice?.interval ===
                          ProductPriceInterval.Month
                            ? entitlement.monthlyUsageBasedPrice
                            : entitlement.yearlyUsageBasedPrice;
                        const interval =
                          values.selectedPrice?.interval ??
                          ProductPriceInterval.Month;
                        const placeholder = price
                          ? `${formatCurrency(
                              price.price,
                              price.currency,
                              price.priceDecimal,
                            )}/${capitalize(
                              entitlement.featureUsage?.feature?.name ?? "",
                            )}${PriceIntervalDisplayName[price.interval]}`
                          : `No price is set for period ${interval}`;

                        return (
                          <FormikControl
                            key={entitlement.featureId}
                            className="relative p-4"
                            control="input"
                            type="number"
                            name={`payInAdvance[${index}].quantity`}
                            label={
                              entitlement?.featureUsage?.feature?.name ??
                              "Unnamed feature"
                            }
                            suffix={placeholder}
                          />
                        );
                      })}
                    </div>
                  )}

                  {values.payAsYouGo.length > 0 && (
                    <div className="flex flex-col space-y-4">
                      {values.payAsYouGo.map((entitlement) => {
                        const price =
                          values.selectedPrice?.interval ===
                          ProductPriceInterval.Month
                            ? entitlement.monthlyUsageBasedPrice
                            : entitlement.yearlyUsageBasedPrice;
                        const interval =
                          values.selectedPrice?.interval ??
                          ProductPriceInterval.Month;
                        const value = price
                          ? `${formatCurrency(
                              price.price,
                              price.currency,
                              price.priceDecimal,
                            )} per ${capitalize(
                              entitlement.featureUsage?.feature?.name ?? "",
                            )}`
                          : `No price is set for period ${interval}`;

                        return (
                          <FormikControl
                            key={entitlement.featureId}
                            className="relative p-4"
                            control="input"
                            type="text"
                            name={`payAsYouGoPlaceholderValue`}
                            disabled
                            value={value}
                            label={
                              entitlement?.featureUsage?.feature?.name ??
                              "Unnamed feature"
                            }
                          />
                        );
                      })}
                    </div>
                  )}

                  <FormRow>
                    <FormikAsyncSelect
                      key="addons"
                      className="flex-1"
                      defaultOptions
                      label="Add Ons"
                      isMulti
                      description="Add Ons must have same billing period as plan"
                      loadOptions={listAddOnsPricesWithAddOnName}
                      loadOptionsMappers={{
                        mapperFunction: (
                          price: AddOnPriceWithAddOnName,
                        ): PriceOption => ({
                          // Using billingProductId as value let us select only on price per product
                          name: price.billingProductId,
                          value: price.billingProductId,
                          label: <AddOnPriceOptionLabel price={price} />,
                          resource: price,
                        }),
                      }}
                      name="selectedAddOnIds"
                      placeholder="Select add ons"
                      onChange={async (options: PriceOption[]) => {
                        await setFieldValue(
                          "selectedAddOns",
                          options.map(({ resource }) => resource),
                        );
                      }}
                      selectedOption={values.selectedAddOns.map((price) => ({
                        name: price.billingProductId,
                        value: price.billingProductId,
                        label: <AddOnPriceOptionLabel price={price} />,
                        resource: price,
                      }))}
                    />
                  </FormRow>

                  <FormRow>
                    <FormikAsyncSelect
                      key="coupon"
                      className="flex-1"
                      defaultOptions
                      label="Discount"
                      loadOptions={listCoupons}
                      loadOptionsMappers={{
                        mapperFunction: (
                          coupon: BillingCouponResponseData,
                        ): CouponOption => ({
                          name: coupon.name,
                          value: coupon,
                          label: <CouponOptionLabel coupon={coupon} />,
                        }),
                      }}
                      name="coupon"
                      placeholder="Select discount coupon"
                      selectedOption={
                        values.coupon && {
                          name: values.coupon.name,
                          value: values.coupon,
                          label: <CouponOptionLabel coupon={values.coupon} />,
                        }
                      }
                    />
                  </FormRow>
                </FormColumn>

                <PlanComparison
                  companyId={company.id}
                  currentPlan={values.currentPlan}
                  currentAddOns={values.currentAddOns}
                  selectedPlan={values.selectedPlan}
                  selectedAddOns={values.selectedAddOns}
                  selectedPrice={values.selectedPrice}
                  currentPrice={values.currentPrice}
                  payInAdvance={values.payInAdvance}
                  payAsYouGo={values.payAsYouGo}
                  coupon={values.coupon}
                />

                {apiError && (
                  <div className="pt-12 px-12">
                    <Alert size="xs" style="red">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="text-base font-body">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </div>
                      </div>
                    </Alert>
                  </div>
                )}

                <OverlayFooter className="px-12 pb-6">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="submit" color="blue" disabled={!dirty}>
                    Save changes
                  </Button>
                </OverlayFooter>
              </Form>
            )}
          </Formik>
        )}
      </OverlayModal>
    </Overlay>
  );
};
