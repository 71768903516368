import { BillingSubscriptionView } from "@models/api";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { hasTrial } from "@utils/trials";
import { useEffect, useState } from "react";
import { TrialPill } from "./TrialPill";

export const SubscriptionPriceDisplay = ({
  subscription,
}: {
  subscription?: BillingSubscriptionView;
}) => {
  const [price, setPrice] = useState<number>(0);
  const priceFormatter = (value: number): string => {
    const currency =
      subscription?.products[0]?.currency?.toUpperCase() ?? "USD";
    const priceInterval = subscription?.interval;
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
    }).format(value / 100);

    return `${formatted}/${priceInterval}`;
  };

  const [hasUsageBasedPrice, setHasUsageBasedPrice] = useState<boolean>(false);
  useEffect(() => {
    if (subscription) {
      subscription.products.map((t) => {
        if (t.usageType === "metered") {
          setHasUsageBasedPrice(true);
        }
      });

      let newPrice = 0;
      if (hasUsageBasedPrice) {
        newPrice = subscription.products.reduce((acc, prv) => {
          return acc + prv.price * prv.quantity;
        }, 0);
      } else {
        newPrice = subscription.totalPrice;
      }

      setPrice(newPrice);
    }
  }, [hasUsageBasedPrice, subscription]);

  const renderPrice = subscription ? priceFormatter(price) : "n/a";

  const showTrialPill = hasTrial(subscription);
  return (
    <>
      <div className="text-sm">
        {renderPrice} {hasUsageBasedPrice && <span>+ Usage</span>}
        {subscription?.latestInvoice && hasUsageBasedPrice && (
          <LabeledTooltip
            description={`Invoice subtotal ${priceFormatter(
              subscription.latestInvoice.subtotal,
            )}`}
            className="!bg-transparent"
            placement="bottom-left"
            size="md"
          />
        )}
      </div>
      {showTrialPill && <TrialPill trialEnd={subscription?.trialEnd} />}
    </>
  );
};
