import { newPillColorLookup } from "@consts/colors";
import { EntityTraitType } from "@models/entityTrait";
import {
  WebhookEventStatus,
  WebhookRequestType,
  WebhookStatus,
} from "@models/webhook";

export enum SettingsPage {
  ApiKeys = "api-keys",
  AccountSettings = "account-settings",
  AuditLog = "audit-log",
  HubSpotIntegration = "hubspot",
  KeysAndTraits = "keys-and-traits",
  SegmentIntegration = "segment",
  StripeIntegration = "stripe",
  Misc = "misc",
  Plans = "plan",
  UsageAndBilling = "usage-and-billing",
  Webhook = "webhook",
  Team = "team",
}

export const EntityTraitTypeDisplay = {
  [EntityTraitType.Boolean]: "Boolean",
  [EntityTraitType.Currency]: "Currency",
  [EntityTraitType.Date]: "Date",
  [EntityTraitType.Number]: "Number",
  [EntityTraitType.String]: "String",
  [EntityTraitType.URL]: "URL",
};

export const AuditLogMapping: { [key: string]: any } = {
  feature: {
    path: "features",
  },
  flag: {
    path: "flags",
  },
  account: {},
  environment: {},
  api_key: {
    path: "settings/api-keys",
  },
  api_key_request: {},
  company: {
    path: "companies",
    action: {
      post: "upserted",
    },
  },
  user: {
    path: "users",
    action: {
      post: "upserted",
    },
  },
  entity_trait: {
    path: "keys-and-traits",
  },
  company_membership: {},
  company_override: {
    path: "features",
  },
  plan_entitlement: {
    path: "features",
  },
  plan_audience: {
    path: "plans",
  },
  raw_event: {},
  event: {},
  event_summary: {},
  rules: {
    path: "flags",
  },
  rule: {
    path: "flags",
  },
  check_flag: {},
  flag_check_log: {},
  plan: {
    path: "plans",
  },
};

export const WebhookRequestTypeDescription: Record<WebhookRequestType, string> =
  {
    [WebhookRequestType.CompanyUpdated]: "Occurs when a company has changed",
    [WebhookRequestType.UserUpdated]: "Occurs when a user has changed",
    [WebhookRequestType.PlanUpdated]: "Occurs when a plan has changed",
    [WebhookRequestType.PlanEntitlementUpdated]:
      "Occurs when a plan entitlement has changed",
    [WebhookRequestType.CompanyOverrideUpdated]:
      "Occurs when a company override has changed",
    [WebhookRequestType.FeatureUpdated]: "Occurs when a feature has changed",
    [WebhookRequestType.FlagUpdated]: "Occurs when a flag has changed",
    [WebhookRequestType.FlagRulesUpdated]:
      "Occurs when a flag's targeting has changed",

    [WebhookRequestType.CompanyCreated]:
      "Occurs when a company has been created",
    [WebhookRequestType.UserCreated]: "Occurs when a user has been created",
    [WebhookRequestType.PlanCreated]: "Occurs when a plan has been created",
    [WebhookRequestType.PlanEntitlementCreated]:
      "Occurs when a plan entitlement has been created",
    [WebhookRequestType.CompanyOverrideCreated]:
      "Occurs when a company override has been created",
    [WebhookRequestType.FeatureCreated]:
      "Occurs when a feature has been created",
    [WebhookRequestType.FlagCreated]: "Occurs when a flag has been created",

    [WebhookRequestType.CompanyDeleted]:
      "Occurs when a company has been deleted",
    [WebhookRequestType.UserDeleted]: "Occurs when a user has been deleted",
    [WebhookRequestType.PlanDeleted]: "Occurs when a plan has been deleted",
    [WebhookRequestType.PlanEntitlementDeleted]:
      "Occurs when a plan entitlement has been deleted",
    [WebhookRequestType.CompanyOverrideDeleted]:
      "Occurs when a company override has been deleted",
    [WebhookRequestType.FeatureDeleted]:
      "Occurs when a feature has been deleted",
    [WebhookRequestType.FlagDeleted]: "Occurs when a flag has been deleted",

    [WebhookRequestType.SubscriptionTrialEnded]: "Occurs when a trial ends",
  };

export const pillColorWebhookStatus = newPillColorLookup({
  [WebhookStatus.Active]: "green",
  [WebhookStatus.Inactive]: "gray",
});

export const pillColorWebhookEventStatus = newPillColorLookup({
  [WebhookEventStatus.Success]: "green",
  [WebhookEventStatus.Pending]: "yellow",
  [WebhookEventStatus.Failed]: "red",
});
