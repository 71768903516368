import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listFeatures } from "@data/features";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import {
  CreateEntityTraitDefinitionRequestBodyEntityTypeEnum,
  CreateEntityTraitDefinitionRequestBodyTraitTypeEnum,
} from "@models/api";
import { EntitlementValueType } from "@models/entitlement";
import { FeatureType } from "@models/feature";
import { Plan } from "@models/plan";
import { createMultiplePlanEntitlements } from "@modules/plans";
import { createEntityTraitDefinition } from "@modules/settings/queries/entityTraits";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { DynamicTitle } from "@ui/DynamicTitle";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { Separator } from "@ui/Separator";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { EntitlementFeatureBlock } from "./EntitlementFeatureBlock";
import { FormValues } from "./PlanEntitlementEditOverlay";
import {
  addMultipleEntitlementsValidationSchema,
  MAX_NUMBER_OF_CREATE_ENTITLEMENT,
} from "./validation";

type PlanEntitlementCreateMultipleOverlayProps = {
  plan: Plan;
  onClose: () => void;
};

type CreateMultipleFormValues = {
  plan: Plan;
  planId: string;
  entitlements: FormValues[];
};

export const PlanEntitlementCreateMultipleOverlay = ({
  onClose,
  plan,
}: PlanEntitlementCreateMultipleOverlayProps) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const initialValues: CreateMultipleFormValues = {
    plan,
    planId: plan?.id ?? "",
    entitlements: [],
  };

  const onSubmit = async (
    { entitlements }: CreateMultipleFormValues,
    helpers: FormikHelpers<CreateMultipleFormValues>,
  ) => {
    setLoading(true);

    try {
      const createEntitlementsRequests = entitlements.map(
        ({ feature, plan, trait, ...rest }) => {
          const r = { ...rest, feature };
          if (
            r.priceBehavior === "overage" &&
            r.overageBillingProductId === "generate"
          ) {
            r.overageBillingProductId = undefined;
          }

          if (r.priceBehavior === "overage") {
            r.currency = plan?.billingProduct?.currency;
          }

          if (r.yearlyUnitPrice) {
            if (
              Math.round(Math.floor(r.yearlyUnitPrice * 100) * 1000) % 1 !==
              0
            ) {
              r.yearlyUnitPriceDecimal = Math.round(
                (Math.abs(rest.yearlyUnitPrice!) / 10000) * 1000000,
              ).toString();
            } else {
              r.yearlyUnitPrice = Math.floor(r.yearlyUnitPrice * 100);
            }
          }

          if (
            r.monthlyUnitPrice &&
            Math.floor(r.monthlyUnitPrice) !== r.monthlyUnitPrice
          ) {
            if (
              r.monthlyUnitPrice &&
              Math.round((Math.abs(r.monthlyUnitPrice) / 100) * 10000) % 1 !== 0
            ) {
              r.monthlyUnitPriceDecimal = Math.round(
                (Math.abs(rest.monthlyUnitPrice!) / 10000) * 1000000,
              ).toString();
            } else {
              r.monthlyUnitPrice = Math.floor(r.monthlyUnitPrice * 100);
            }
          }

          return r;
        },
      );
      const traitsCreationPromises = createEntitlementsRequests.map(
        async (r) => {
          if (r.priceBehavior === "pay_in_advance") {
            let traitLimitSlug = r
              .feature!.name.toLowerCase()
              .replace(/ /g, "-")
              .replace(/[^\w-]+/g, "");

            traitLimitSlug += "-schematic-system-trait";

            // create trait for planEntitlements to handle feature limits
            // using comparison traits
            const createdEntityTrait = await createEntityTraitDefinition({
              displayName: traitLimitSlug,
              entityType:
                CreateEntityTraitDefinitionRequestBodyEntityTypeEnum.Company,
              hierarchy: [traitLimitSlug],
              traitType:
                CreateEntityTraitDefinitionRequestBodyTraitTypeEnum.Number,
            });

            r.valueTraitId = createdEntityTrait.id;

            return r;
          }

          return r;
        },
      );

      const createEntitlementsRequest = await Promise.all(
        traitsCreationPromises,
      );

      await createMultiplePlanEntitlements(
        createEntitlementsRequest.map(({ ...rest }) => ({ ...rest })),
      );

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="lg" expand={true} className={"max-h-[auto]"}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={addMultipleEntitlementsValidationSchema({
            plan: plan,
          })}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, isValid }) => (
            <Form className="pt-12 flex flex-col flex-1">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
              >
                <Icon name="close" className="text-3xl" />
              </button>

              <div className="flex-1 px-12">
                <FormHeader
                  label="Plan entitlement"
                  title={
                    <DynamicTitle
                      fromLabel={values.plan?.name ?? "Plan"}
                      from={values.plan?.name}
                      toLabel={"Choose features"}
                      to={false}
                    />
                  }
                  compact
                />
                <FormColumn>
                  <FormikAsyncSelect
                    label="Plan"
                    name="plan"
                    defaultOptions
                    disabled
                    selectedOption={
                      values.plan && {
                        value: values.plan,
                        label: values.plan?.name || values.planId,
                      }
                    }
                  />

                  <Separator />

                  <FormikAsyncSelect
                    label="Feature"
                    name="feature"
                    defaultOptions
                    isMulti
                    maxNumberOfSelected={MAX_NUMBER_OF_CREATE_ENTITLEMENT}
                    loadOptions={listFeatures}
                    loadOptionsMappers={{
                      requestFilter: plan && {
                        withoutPlanEntitlementFor: plan.id,
                      },
                    }}
                    onChange={async (options: Option[]) => {
                      const updatedOptions = options.map((option) => {
                        const oldValue = values.entitlements.find(
                          (item) => item.featureId === option.value.id,
                        );

                        return {
                          feature: option.value,
                          featureId: option.value.id,
                          plan: values.plan,
                          planId: values.planId,
                          ...(option.value.featureType === FeatureType.Boolean
                            ? {
                                valueType: EntitlementValueType.Boolean,
                                metricPeriod: undefined,
                                valueBool: true,
                                valueNumeric: undefined,
                              }
                            : {
                                valueType: EntitlementValueType.Numeric,
                                valueBool: undefined,
                                valueNumeric: 0,
                              }),
                          ...oldValue,
                        };
                      });

                      await setFieldValue("entitlements", updatedOptions);
                      await setFieldValue("$context", { plan });
                    }}
                  />
                </FormColumn>
              </div>
              <div className="pb-6 mt-6 overflow-y-auto">
                {values.entitlements.map((entitlement, index) => {
                  return (
                    <EntitlementFeatureBlock
                      key={entitlement.featureId}
                      values={entitlement}
                      name={`entitlements[${index}].`}
                      setFieldValue={setFieldValue}
                    />
                  );
                })}
              </div>

              {values.entitlements.length > 0 && values.planId && (
                <div className="sticky bottom-0 p-12 border-t bg-gradient-to-b from-white/50 backdrop-blur-xs via-white to-white">
                  {apiError && (
                    <div className="pb-12 px-12">
                      <Alert size="xs" style="red">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="text-base font-body ">
                            <span className="font-semibold">Uh-oh!</span>{" "}
                            {apiError}
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}

                  <div className="flex items-end justify-end space-x-2">
                    <button
                      disabled={!isValid}
                      className="button disabled:bg-gray-300 disabled:border-gray-300 button-sm button-blue"
                      type="submit"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
