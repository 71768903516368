import { ButtonWithPill, ButtonWithPillProps } from "@ui/ButtonWithPill";

export enum DisabledReasonsType {
  Permission = "permission",
  Entitlement = "entitlement",
  Quota = "quota",
}

const disabledTooltipTextMap = {
  permission:
    "This feature is disabled for your role. Ask your admin to update your permissions",
  entitlement:
    "Your plan does not allow this feature. Consider upgrading your plan.",
  quota: "You have reached your quota limit. Consider upgrading your plan.",
};
export interface PermissionButtonProps extends ButtonWithPillProps {
  disabledReason?: DisabledReasonsType;
}

export const PermissionButton = ({
  disabled,
  disabledReason,
  disabledTooltip,
  ...props
}: PermissionButtonProps) => {
  const disabledTooltipText = disabledReason
    ? disabledTooltipTextMap[disabledReason]
    : disabledTooltip;

  return (
    <ButtonWithPill
      disabled={disabled}
      disabledTooltip={disabledTooltipText}
      {...props}
    />
  );
};
