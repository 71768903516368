import { Resolver } from "@craftjs/core";
import {
  PlanManager,
  IncludedFeatures,
  MeteredFeatures,
  PricingTable,
  UpcomingBill,
  PaymentMethod,
  Invoices,
  UnsubscribeButton,
} from "./components/builder/elements";
import { Root, Column, Viewport } from "./components/builder/layout";

export const builderResolver: Resolver = {
  // Layout Components
  Root,
  Viewport,
  Column,

  // Plan Components
  PlanManager,
  IncludedFeatures,
  MeteredFeatures,
  PricingTable,

  // Billing Components
  UpcomingBill,
  PaymentMethod,
  Invoices,
  UnsubscribeButton,
};
