import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { WebhookResponseData } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { WebhookRequestType } from "@models/webhook";
import { WebhookEventsTable } from "@modules/integrations/components/tables/WebhookEventsTable";
import { WebhookRequestTypeDescription } from "@modules/settings/consts";
import { getWebhook } from "@modules/settings/queries/webhooks";
import { Button } from "@ui/Button";
import { ClipCopy } from "@ui/ClipCopy";
import { LabelData } from "@ui/LabelData";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import { OverlayLoader } from "@ui/Overlay/OverlayLoader";
import React, { useState } from "react";

const SecretLabel = ({ secret }: { secret: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <LabelData label="Secret">
      <div className="px-3 border border-gray-300 h-[44px] rounded-lg flex w-full items-center">
        {isVisible ? (
          <ClipCopy
            data={secret}
            title={secret}
            className="w-full min-w-full"
          />
        ) : (
          <>
            <span className="blur-sm select-none overflow-hidden whitespace-nowrap text-ellipsis">
              {secret}
            </span>
            <Button
              size="xs"
              color="white"
              className="ml-auto"
              onClick={() => setIsVisible(true)}
            >
              Reveal
            </Button>
          </>
        )}
      </div>
    </LabelData>
  );
};

type WebhookDetailsOverlayProps = {
  webhookId: string;
  onClose: () => void;
  onEdit: (webhook: WebhookResponseData) => void;
};

export const WebhookDetailsOverlay = ({
  webhookId,
  onClose,
  onEdit,
}: WebhookDetailsOverlayProps) => {
  const [showAll, setShowAll] = useState(false);

  const webhookRevealSecretAllowed = usePermission(
    ClerkUserPermission.webhooks_reveal_secret,
  );

  const webhookEditAllowed = usePermission(ClerkUserPermission.webhooks_edit);

  const { isLoading, error, data } = useContextQuery({
    queryKey: [`webhook`, webhookId],
    queryFn: () => getWebhook(webhookId),
  });

  const handleShowAll = () => setShowAll(!showAll);

  const requestTypeData = (data?.requestTypes as WebhookRequestType[]) || [];
  const truncatableData = showAll
    ? requestTypeData
    : requestTypeData.slice(0, 5);
  if (error) throw error;

  return (
    <Overlay onClose={onClose}>
      <OverlayModal position="right">
        {isLoading || !data ? (
          <OverlayLoader />
        ) : (
          <>
            <OverlayHeader title="Webhook Detail" onClose={onClose}>
              {webhookEditAllowed ? (
                <Button
                  size="sm"
                  color="white"
                  className="mr-6 mb-8"
                  onClick={() => {
                    onEdit(data);
                  }}
                >
                  Edit
                </Button>
              ) : null}
            </OverlayHeader>
            <div className="px-12 pt-8 space-y-6">
              <LabelData label="Name">
                <ClipCopy data={data.name} title={data.name} />
              </LabelData>

              <LabelData label="URL">
                <ClipCopy data={data.url} title={data.url} />
              </LabelData>

              {/*TODO: Has permission?*/}
              {webhookRevealSecretAllowed ? (
                <SecretLabel secret={data.secret} />
              ) : null}

              <div className="flex flex-col ">
                <div className="flex flex-row justify-between items-center">
                  <LabelData label="Listening for" />

                  <span
                    onClick={handleShowAll}
                    className="text-blue-400 cursor-pointer mt-4 hover:underline"
                  >
                    {showAll ? "Hide" : "See all"}
                  </span>
                </div>
                <div className="w-full flex flex-col">
                  {truncatableData.map((requestType) => {
                    return (
                      <div
                        key={requestType}
                        className="flex flex-col relative py-2 border-b-2 border-r-gray-500 last-of-type:border-b-0"
                      >
                        <div className="font-bold text-black">
                          {requestType}
                        </div>
                        <div className="text-sm text-gray-500">
                          {WebhookRequestTypeDescription[requestType]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <WebhookEventsTable webhookId={data.id} />
            </div>
          </>
        )}
      </OverlayModal>
    </Overlay>
  );
};
