import { BillingProductResponseData } from "@models/api";
import { Icon } from "@ui/Icon";

interface BillingProductOptionLabelProps {
  product: BillingProductResponseData;
}

export const BillingProductOptionLabel = ({
  product,
}: BillingProductOptionLabelProps) => {
  return (
    <div className="flex items-center space-x-4 justify-between">
      <div>{product.name}</div>
      <span className="stripe-label-id leading-none text-xs text-gray-400 flex items-center justify-center">
        <Icon
          name="stripe"
          className="text-[#6772E5] bg-gray-200 border border-gray-300 rounded-full w-4 h-4 inline-flex justify-center items-center mr-2"
        />
        <span>{product.externalId}</span>
      </span>
    </div>
  );
};

export const AutoCreateBillingProductOptionLabel = () => {
  return (
    <div className="flex items-center justify-between w-full py-1 px-1">
      <div className="flex items-center">
        <div className={"mr-2"}>
          <Icon
            name="plan"
            className="text-[#6772E5] bg-gray-200 border border-gray-300 rounded-full w-4 h-4 inline-flex justify-center items-center mr-1"
          />
        </div>
        <span className="">Create automatically</span>
      </div>
    </div>
  );
};
