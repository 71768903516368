import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import {
  useEditor,
  useNode,
  Editor,
  Frame,
  Element,
  type SerializedNodes,
} from "@craftjs/core";
import { errorMessage } from "@data/index";
import { useContextQuery } from "@hooks/useContextQuery";
import { type CompanyDetailResponseData } from "@models/api";
import { ComponentType } from "@models/component";
import { previewComponent, updateComponent } from "@modules/components/queries";
import { useEmbed, EmbedProvider } from "@schematichq/schematic-components";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import cx from "classnames";
import isEmpty from "lodash.isempty";
import { inflate } from "pako";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { Topbar, Layout, Sidebar } from "./builder/controls";
import {
  PlanManager,
  IncludedFeatures,
  UpcomingBill,
  PaymentMethod,
} from "./builder/elements";
import { Root, Viewport, Column } from "./builder/layout";
import { builderResolver } from "../resolver";
import "react-fontpicker-ts/dist/index.css";

export const RenderNode = ({ render }: { render: React.ReactNode }) => {
  const portalRef = useRef<HTMLDivElement | null>(null);

  const { actions, query } = useEditor();
  const {
    connectors: { drag },
    id,
    dom,
    displayName,
    // parentNodeId,
    isRoot,
    isDeletable,
    isDraggable,
    isHovered,
    isSelected,
    isResizing,
    isLayoutElement,
    isColumn,
    isViewport,
  } = useNode((node) => ({
    id: node.id,
    dom: node.dom,
    displayName: node.data.displayName,
    // parentNodeId: node.data.parent,
    isRoot: query.node(node.id).isRoot(),
    isDeletable: query.node(node.id).isDeletable(),
    isDraggable: query.node(node.id).isDraggable(),
    isHovered: node.events.hovered,
    isSelected: query.getEvent("selected").contains(node.id),
    isResizing: node.data.custom.isResizing as boolean | undefined,
    isLayoutElement: node.dom?.classList.contains("layout"),
    isColumn: node.data.name === "Column",
    isViewport: node.data.name === "Viewport",
  }));

  const getPos = useCallback(() => {
    const style = { top: "0px", left: "0px" };

    if (dom) {
      const { top, left, bottom } = dom.getBoundingClientRect();
      style.top = `${top > 0 ? top - (isViewport ? 0 : 23) : bottom}px`;
      style.left = `${left}px`;
    }

    return style;
  }, [dom, isViewport]);

  const scroll = useCallback(() => {
    const { top, left } = getPos();
    if (portalRef.current) {
      portalRef.current.style.top = top;
      portalRef.current.style.left = left;
    }
  }, [getPos]);

  useEffect(() => {
    const renderer = document.querySelector(".craftjs-renderer");
    if (renderer) {
      renderer.addEventListener("scroll", scroll);
    }

    return () => {
      if (renderer) {
        renderer.removeEventListener("scroll", scroll);
      }
    };
  }, [scroll]);

  useEffect(() => {
    if (isRoot) return;
    dom?.classList.toggle("component-selected", isSelected || isHovered);
  }, [dom, isRoot, isSelected, isHovered]);

  return (
    <>
      {(isHovered || isSelected) &&
        !isRoot &&
        createPortal(
          <div
            ref={portalRef}
            className={cx("fixed z-[9]", {
              hidden: isResizing,
            })}
            style={getPos()}
          >
            <div
              className={cx("flex text-xs text-white", {
                "bg-[#194BFB]": !isLayoutElement,
                "bg-[#CECECE]": isLayoutElement,
              })}
            >
              {displayName && <div className="py-1 px-2">{displayName}</div>}

              {isDraggable && (
                <div
                  ref={(ref) => drag(ref!)}
                  className="flex justify-center items-center px-1.5 cursor-move hover:bg-[#476FFC]"
                >
                  <svg viewBox="0 0 13 13" width={13} height={13}>
                    <g clipPath="url(#clip0_18837_5213)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.49998 0.609375C6.43295 0.609375 6.36998 0.642383 6.33239 0.697735L4.63381 3.17034C4.59065 3.23229 4.58608 3.31304 4.62112 3.38006C4.65667 3.44659 4.72573 3.48823 4.80139 3.48823H5.70835V5.70837H3.48821V4.80141L3.4877 4.80192C3.48821 4.72625 3.44657 4.65719 3.38004 4.62165C3.31301 4.58661 3.23227 4.59118 3.17032 4.63333L0.697715 6.33243C0.64287 6.37051 0.609863 6.43297 0.609863 6.4995C0.609863 6.56603 0.642872 6.62849 0.697715 6.66658L3.17032 8.36672C3.23227 8.40937 3.31302 8.41395 3.37954 8.37891C3.44606 8.34336 3.48821 8.2743 3.4877 8.19914V7.29168H5.70784V9.51234H4.80088H4.80139C4.72624 9.51234 4.65666 9.55398 4.62163 9.6205C4.58659 9.68753 4.59116 9.76777 4.63381 9.83023L6.3324 12.3028C6.36997 12.3577 6.43243 12.3907 6.49947 12.3907C6.56599 12.3907 6.62846 12.3577 6.66655 12.3028L8.36669 9.83023C8.40934 9.76777 8.41391 9.68702 8.37888 9.6205C8.34333 9.55398 8.27427 9.51234 8.1986 9.51234H7.29165V7.29168H9.51179V8.19914H9.51229C9.51179 8.2743 9.55394 8.34336 9.62046 8.37891C9.68698 8.41395 9.76772 8.40938 9.82968 8.36672L12.3023 6.66658C12.3571 6.62849 12.3901 6.56603 12.3901 6.4995C12.3901 6.43298 12.3571 6.37052 12.3023 6.33243L9.82968 4.63333C9.76773 4.59118 9.68698 4.58661 9.61995 4.62165C9.55343 4.65719 9.51179 4.72626 9.51229 4.80192V5.70888L7.29163 5.70837V3.48823H8.19859C8.27426 3.48874 8.34383 3.44709 8.37886 3.38006C8.41441 3.31303 8.40984 3.23229 8.36668 3.17034L6.66654 0.697735C6.62896 0.64289 6.56649 0.609883 6.49998 0.609375Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_18837_5213">
                        <rect width="13" height="13" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}

              {isDeletable && !isLayoutElement && (
                <button
                  type="button"
                  className="flex justify-center items-center px-1.5 cursor-pointer bg-[#EF4444] hover:bg-[#F26969]"
                  tabIndex={0}
                  onClick={() => {
                    actions.delete(id);
                  }}
                >
                  <svg viewBox="0 0 11 11" width={11} height={11}>
                    <path
                      d="M1.71875 9.26409C1.71875 9.84847 2.18282 10.3125 2.76718 10.3125H8.23286C8.81724 10.3125 9.28129 9.84845 9.28129 9.26409V3.09375H1.71879L1.71875 9.26409Z"
                      fill="white"
                    />
                    <path
                      d="M6.70307 1.375V0.6875H4.29682V1.375H1.375V2.40625H9.625V1.375H6.70307Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}

              {isColumn && (
                <div className="flex justify-center items-center pr-2">
                  <svg
                    width="17"
                    height="6"
                    viewBox="0 0 17 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.08333 0L0 3L3.08333 6V4H13.3611V6L16.4444 3L13.3611 0V2H3.08333V0Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>,
          document.querySelector(".page-container") as Element,
        )}
      {render}
    </>
  );
};

const getTemplate = (componentType?: string) => {
  switch (componentType) {
    case ComponentType.Entitlement:
      return (
        <Root>
          <Element is={Viewport} id="viewport">
            <Element is={Column} canvas id="1">
              <PlanManager />
              <IncludedFeatures />
            </Element>
            <Element is={Column} canvas id="2">
              <UpcomingBill />
              <PaymentMethod />
            </Element>
            <Element is={Column} canvas id="3" />
          </Element>
        </Root>
      );
    default:
      return (
        <Root>
          <Element is={Viewport} id="viewport">
            <Element is={Column} canvas id="1" />
            <Element is={Column} canvas id="2" />
            <Element is={Column} canvas id="3" />
          </Element>
        </Root>
      );
  }
};

const CustomEditor = () => {
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<
    CompanyDetailResponseData | undefined
  >();

  const { id } = useParams() as { id: string };

  const { setData, updateSettings, setIsPending } = useEmbed();

  const queryClient = useQueryClient();

  const { data: component, isLoading } = useContextQuery({
    queryKey: ["component", id, company?.id],
    refetchOnWindowFocus: false,
    placeholderData: (prev) => prev,
    queryFn: async () => {
      const response = await previewComponent({
        componentId: id,
        ...(company?.id && { companyId: company.id }),
      });
      const { component, ...data } = response;
      setData(data);

      const astData = component?.ast as unknown as Uint8Array;
      const ast = isEmpty(astData)
        ? undefined
        : inflate(astData, { to: "string" });

      if (ast) {
        const state = JSON.parse(ast) as SerializedNodes;
        updateSettings(state.ROOT.props.settings);
      }

      return { ...component, ast };
    },
    retry: false,
  });

  const uint8ArrayToObject = (array: Uint8Array): { [key: string]: number } => {
    const result: { [key: string]: number } = {};
    for (let i = 0; i < array.length; i++) {
      result[i.toString()] = array[i];
    }
    return result;
  };

  const onChangeCompany = (entity: CompanyDetailResponseData | undefined) => {
    setCompany(entity);
  };

  const onPublish = async (ast: Uint8Array) => {
    try {
      setLoading(true);
      // TODO: Fix types and values
      await updateComponent(id, { ast: uint8ArrayToObject(ast) });

      queryClient.invalidateQueries();
      setApiError(undefined);
    } catch (error) {
      setApiError(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsPending(isLoading);
  }, [isLoading]);

  if (isLoading && !component) {
    return <SinglePageLoader />;
  }

  return (
    <div className="fixed top-0 left-0 z-[500] w-full h-full">
      <Editor
        resolver={builderResolver}
        onRender={RenderNode}
        onNodesChange={(query) => {
          const editorState = query.getState();
          const state = editorState.nodes.ROOT?.data?.props;
          updateSettings(state.settings);
        }}
      >
        <div className="h-[calc(100vh-84px)] page-container">
          <Topbar
            company={company}
            onChangeCompany={onChangeCompany}
            onPublish={onPublish}
            id={id}
            name={component?.name}
            updatedAt={component?.updatedAt}
          />
          <div className="flex h-full">
            <div className="flex flex-col flex-grow">
              <Layout />
              <div className="craftjs-renderer h-[calc(100%-56px)] relative overflow-auto">
                {loading && (
                  <SchematicOverlayLoader className="w-[100%]  h-[100%] !bg-white/40" />
                )}
                <Frame data={component?.ast}>
                  {getTemplate(component?.type)}
                </Frame>
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </Editor>

      {apiError && (
        <div className="px-2">
          <Alert size="xs" style="red">
            <div className="flex items-center justify-center space-x-2">
              <div className="text-base font-body ">
                <span className="font-semibold">Uh-oh!</span> {apiError}
              </div>
            </div>
          </Alert>
        </div>
      )}
    </div>
  );
};

export const ComponentView = () => {
  return (
    <EmbedProvider mode="edit">
      <CustomEditor />
    </EmbedProvider>
  );
};
