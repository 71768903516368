import { post } from "@data/index";

type TemporaryTokenResp = {
  token: string;
};

export type TokenRequestParams = {
  skipCache: boolean;
};

export const createTemporaryToken = async ({
  skipCache,
}: TokenRequestParams): Promise<TemporaryTokenResp> => {
  return post<TemporaryTokenResp, object>(
    "schematic/temporary-token",
    {},
    skipCache,
  );
};
