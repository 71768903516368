import { Link } from "react-router-dom";

export const PrivacyPolicy = ({ type }: { type: "sign-in" | "sign-up" }) => {
  return (
    <div className="flex flex-col justify-center items-center space-y-6 w-full text-center">
      <div className="mt-4">
        <div className="text-gray-400/80 text-sm">
          By signing {type === "sign-in" ? "in" : "up"}, you agree to our
          privacy{" "}
          <Link className="underline hover:opacity-80" to="">
            policy
          </Link>{" "}
          and{" "}
          <Link className="underline hover:opacity-80" to="">
            terms
          </Link>
          .
        </div>
      </div>
    </div>
  );
};
