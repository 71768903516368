import cx from "classnames";
import { HTMLAttributes, ReactNode, SyntheticEvent } from "react";

export type PillTextStylesTypes = "normal" | "uppercase" | "code";
export type PillSizeTypes = "sm" | "md";
export type PillStyleTypes =
  | "blue"
  | "yellow"
  | "green"
  | "orange"
  | "gray"
  | "red"
  | "white-button";

export type PillTypeTypes = "rounded" | "tag";

interface PillProps extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  className?: string;
  color?: PillStyleTypes;
  onClick?: (e: SyntheticEvent) => void;
  text?: PillTextStylesTypes;
  type?: PillTypeTypes;
  size?: PillSizeTypes;
}

export const Pill = ({
  children,
  className,
  color = "gray",
  size = "sm",
  onClick,
  text = "uppercase",
  type = "tag",
  ...props
}: PillProps) => {
  const typeMap = {
    rounded: "rounded-full",
    tag: "rounded-md",
  };

  const textMap = {
    normal: "font-normal text-sm",
    uppercase: "uppercase font-bold tracking-widest text-xs",
    code: "font-mono text-black text-xs lowercase",
  };

  const styleMap = {
    blue: "bg-[#D3DBF7] text-blue-400",
    yellow: "bg-yellow-100 text-yellow-300",
    green: "bg-green-100 text-green-300",
    gray: "bg-gray-100 text-gray-400",
    red: "bg-red-100 text-red-600",
    orange: "bg-orange-200 text-orange-600",
    "white-button": "bg-gray-800 text-gray-800",
  };

  const sizeMap = {
    sm: "py-1 px-2 ",
    md: "py-2 px-4",
  };

  const styles = cx(
    "bg-gray-200 inline leading-none",
    textMap[text],
    styleMap[color],
    typeMap[type],
    sizeMap[size],
    className,
  );

  return (
    <span className={styles} onClick={onClick} {...props}>
      {children}
    </span>
  );
};
