import { PriceBehaviourType } from "@models/entitlement";
import { FeatureType } from "@models/feature";
import { FeatureCompany, FeatureUsage } from "@models/usage";
import { ProgressBar } from "@ui/ProgressBar";
import { formatCurrency } from "@utils/strings";
import { useEffect, useState } from "react";

export interface EntitlementUsageCellProps {
  featureUsage: FeatureUsage | FeatureCompany;
  parent?: "table" | "company-sidebar" | "feature-sidebar";
}

export const EntitlementUsageCell = ({
  featureUsage,
  parent = "table",
}: EntitlementUsageCellProps) => {
  const { feature, usage, allocation } = featureUsage;
  const [calculatedAllocation, setCalculatedAllocation] = useState<
    number | undefined
  >(allocation);

  useEffect(() => {
    if (featureUsage.priceBehavior !== "overage") {
      return;
    }
    setCalculatedAllocation(featureUsage.softLimit);
  }, [allocation, usage, featureUsage]);

  if (!feature) return <></>;

  // For boolean, show events count from last 60 days without progress bar
  if (
    feature.featureType === FeatureType.Boolean &&
    typeof usage === "undefined"
  ) {
    return <></>;
  } else if (feature.featureType === FeatureType.Boolean) {
    return (
      <>
        {parent == "table" && (
          <div>
            {usage} <span className="text-gray-400">events in last 60</span>
          </div>
        )}
        {parent == "feature-sidebar" && (
          <div className="text-xs text-gray-400/60 font-bold  leading-1">
            {usage} events
            <br />
            in last 60
          </div>
        )}
        {parent == "company-sidebar" && (
          <div className="text-3xl leading-none">{usage}</div>
        )}
      </>
    );
  }

  const pctUsed =
    calculatedAllocation && typeof usage !== "undefined"
      ? 100.0 * (usage / calculatedAllocation)
      : 0;
  let progressColor: "blue" | "red" | "orange" = "blue";
  if (pctUsed >= 100.0) {
    progressColor = "red";
  } else if (pctUsed >= 90.0) {
    progressColor = "orange";
  }

  const usageLimit =
    typeof calculatedAllocation === "undefined"
      ? "∞"
      : calculatedAllocation.toString();

  const overuse = featureUsage.softLimit ? usage! - featureUsage.softLimit! : 0;
  const price =
    featureUsage.monthReset === "billing_cycle" &&
    featureUsage.period === "current_month"
      ? featureUsage.monthlyUsageBasedPrice
      : featureUsage.yearlyUsageBasedPrice;

  let dollarAmountPrice = price?.price ?? 0;
  if (featureUsage.priceBehavior === PriceBehaviourType.Overage) {
    if (!price) {
      return;
    }

    const { priceTier } = price;
    if (priceTier.length) {
      dollarAmountPrice =
        priceTier[priceTier.length - 1].perUnitPrice ??
        Number(priceTier[priceTier.length - 1].perUnitPriceDecimal);
    }
  }

  return (
    <div className="inline-block space-y-1 min-w-[160px]">
      <ProgressBar
        softLimit={featureUsage.softLimit}
        color={progressColor}
        progress={pctUsed}
        total={usageLimit}
        value={usage ?? 0}
      />

      {featureUsage.priceBehavior === PriceBehaviourType.Overage &&
        overuse > 0 && (
          <div className="flex leading-none text-sm mt-5 text-gray-400">
            {overuse} over limit ·{" "}
            {price &&
              formatCurrency(
                dollarAmountPrice * overuse,
                price.currency,
                price.priceDecimal
                  ? (Number.parseFloat(price.priceDecimal) * overuse).toString()
                  : "",
              )}
          </div>
        )}
    </div>
  );
};
