import { SettingsPageLoader } from "@components/loaders/SettingsPageLoader";
import { useSchematicFeature } from "@hooks/useSchematicFeature";
import useTablePagination from "@hooks/useTablePagination";
import { ListWebhooksParams, WebhookResponseData } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { UpgradeCtaOverlay } from "@modules/integrations/components/overlays/UpgradeCtaOverlay";
import {
  countWebhooks,
  listWebhooks,
} from "@modules/settings/queries/webhooks";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { ButtonProps } from "@ui/Button";
import { SettingsBox } from "@ui/SettingsBox";
import React, { useEffect, useState } from "react";
import { WebhookEditOverlay } from "../overlays/WebhookEditOverlay";
import { WebhookEventsTable } from "../tables/WebhookEventsTable";
import { WebhooksTable } from "../tables/WebhooksTable";

export const WebhooksPage = () => {
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const { canCreate } = useSchematicFeature("settings.webhook", {
    editPermission: ClerkUserPermission.webhooks_edit,
    fallback: true,
  });
  const handleCreate = () => setShowCreateOverlay(true);

  const buttons: ButtonProps[] = [
    {
      children: "Create",
      color: "blue",
      onClick: handleCreate,
      disabled: !canCreate,
      size: "sm",
    },
  ];

  const webhooksEnabled = useSchematicFlag("settings.webhook", {
    fallback: false,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListWebhooksParams>({});

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const { countQuery } = useTablePagination<
    WebhookResponseData,
    ListWebhooksParams
  >(["webhooks"], listWebhooks, countWebhooks);

  const noWebhooksCreated = countQuery?.data?.count === 0;

  useEffect(() => {
    setFilter((prevState) =>
      searchTerm === ""
        ? { ...prevState, q: undefined }
        : { ...prevState, q: searchTerm },
    );
  }, [searchTerm]);

  if (countQuery.isPending) {
    return <SettingsPageLoader />;
  }

  return (
    <div className="relative -m-2 w-full">
      {!webhooksEnabled ? (
        <UpgradeCtaOverlay title="Upgrade to use Webhooks" />
      ) : null}
      <div className="p-2">
        <SettingsBox
          title="Webhooks"
          description="Subscribe to changes in Schematic"
          searchPlaceholder="Search webhook"
          onSearch={handleSearch}
          buttons={buttons}
          className="overflow-visible w-full"
        >
          <WebhooksTable filter={filter} onCreate={handleCreate} />

          {!noWebhooksCreated && <WebhookEventsTable />}
        </SettingsBox>

        {showCreateOverlay && (
          <WebhookEditOverlay
            webhook={null}
            onClose={() => {
              setShowCreateOverlay(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
