import { Icon } from "@components/ui/Icon";
import { Button } from "@ui/Button";
import cx from "classnames";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from "formik";
import React, { ReactNode, ReactElement, useState } from "react";

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  label: string | ReactNode;
  className?: string;
  children?: ReactNode;
}

export interface FormikStepperProps<T extends FormikValues>
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  className?: string;
  initialValues?: FormikValues;
  children?: ReactNode;
  onClose?: () => void;
  innerRef?: any;
  editMode?: boolean;
  dirty?: boolean;
  expand?: boolean;
  onStepChange?: (values: T, helpers: FormikHelpers<T>) => void;
  initialStep?: number;
  onSubmit?: (values: T, helpers: FormikHelpers<T>, editMode?: boolean) => void;
}

export const FormikStep = ({ children, className }: FormikStepProps) => {
  return <div className={cx("w-full", className)}>{children}</div>;
};

export const FormikStepper = <T extends FormikValues>({
  children,
  onStepChange,
  initialStep = 0,
  onSubmit,
  innerRef,
  onClose,
  editMode,
  dirty,
  expand,
  ...props
}: FormikStepperProps<T>) => {
  const childrenArray: ReactElement<FormikStepProps>[] = React.Children.toArray(
    children,
  ) as ReactElement<FormikStepProps>[];

  const [step, setStep] = useState(initialStep);

  const currentChild = childrenArray[step];

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  return (
    // @ts-expect-error it wants initialValues, when i give it initial values it breaks...
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values: T, helpers: FormikHelpers<T>) => {
        onStepChange && onStepChange(values, helpers);

        if (editMode && dirty) {
          onSubmit && onSubmit(values, helpers, editMode);
          helpers.resetForm(values);
        } else {
          isLastStep() && onSubmit && onSubmit(values, helpers); // Return final form values...
          !isLastStep() &&
            setStep((s) => {
              return editMode && dirty ? s : s + 1;
            });
        }
      }}
      innerRef={innerRef}
    >
      {(values) => {
        const { dirty } = values;

        return (
          <Form className={cx("flex flex-col", expand && "h-auto")}>
            <div className="flex h-full flex-1 max-h-full overflow-hidden relative">
              <div
                onClick={() => onClose && onClose()}
                className="inline-flex absolute z-[500] top-8 right-8 hover:cursor-pointer text-black/50 hover:text-blue-400"
              >
                <Icon name="close" className="text-3xl" />
              </div>

              <div className="flex flex-col  bg-gray-200 rounded-tl-lg rounded-bl-lg  py-12 space-y-8">
                {childrenArray.map((c, idx) => {
                  const styles = cx(
                    "pl-12 pr-16 border-l-[3px] cursor-pointer font-medium",
                    idx == step ? "border-l-blue-400" : "border-l-transparent",
                  );

                  const handleClick = () => setStep(idx);

                  return (
                    <div key={idx} className={styles} onClick={handleClick}>
                      {c?.props.label}
                    </div>
                  );
                })}
              </div>

              <div
                className={cx(
                  !expand && "min-h-[632px] max-h-[700px] h-full",
                  "flex flex-col flex-1 max-w-full overflow-hidden overflow-y-scroll relative",
                )}
              >
                <div className="flex-1 overflow-y-auto px-12 py-12 pb-40">
                  {currentChild}
                </div>
                <div
                  className={cx(
                    step > 0 ? "justify-between" : "justify-end",
                    "flex mt-6 items-end w-full space-x-2 px-12 py-12 bg-gradient-to-b from-white/50  backdrop-blur-xs via-white to-white absolute bottom-0 z-10 rounded-br-lg",
                  )}
                >
                  {step > 0 && !editMode && (
                    <Button
                      type="button"
                      size="sm"
                      onClick={() => setStep(step - 1)}
                    >
                      Back
                    </Button>
                  )}

                  <Button
                    color="blue"
                    size="sm"
                    disabled={!dirty && editMode}
                    type="submit"
                  >
                    {isLastStep()
                      ? "Save"
                      : editMode
                        ? "Save and exit"
                        : "Continue"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
