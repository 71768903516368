import { useOrganization } from "@clerk/clerk-react";
import { Alert } from "@components/ui/Alert";
import { updateAccountSettingsReq } from "@modules/settings/queries/accountSettings";
import { useQueryClient } from "@tanstack/react-query";
import { SettingsBox } from "@ui/SettingsBox";
import { Toast } from "@ui/Toast";
import { useEffect, useRef, useState } from "react";

type FormUpdateData = {
  companyName: string;
  websiteUrl: string;
};

export const AccountSettingsPage = () => {
  const fileInputRef = useRef(null);
  const { organization } = useOrganization();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<FormUpdateData>({
    companyName: "",
    websiteUrl: "",
  });

  const [newOrgLogo, setNewOrgLogo] = useState<string | null>(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!organization) {
      return;
    }

    setFormData({
      companyName: organization.name,
      websiteUrl: organization.publicMetadata.orgWebsite as string,
    });
  }, [organization]);

  const updateCompanyLogo = async () => {
    if (!newOrgLogo) {
      return;
    }

    await organization!.setLogo({
      file: newOrgLogo,
    });
  };

  // eslint-disable-next-line
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const updateAccountData = async () => {
    if (!organization) {
      return;
    }

    await updateAccountSettingsReq({
      orgWebsite: formData.websiteUrl,
      companyName: formData.companyName,
    });

    await queryClient.invalidateQueries();
  };

  // eslint-disable-next-line
  const updateAccountSettings = async (e: any) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await Promise.all([updateCompanyLogo(), updateAccountData()]);
      setDataUpdated(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-8 max-w-full w-full">
      <div>
        <div>
          {organization && (
            <SettingsBox
              title="Account settings"
              description="Edit your account's details. Accessible only to Admins"
              style="inline"
              className="h-auto relative w-full"
            >
              <div className={"w-full"}>
                <Alert size="sm" style="light-gray" className="mb-12 w-full">
                  <div className="mb-6">
                    <label className="text-sm font-medium text-gray-700 mb-1 block">
                      Logo
                    </label>
                    <div className="flex items-center gap-4">
                      <div className="w-12 h-12 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                        {newOrgLogo ? (
                          <img
                            src={newOrgLogo}
                            alt="Company logo"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="text-xl font-bold">
                            <img
                              src={organization.imageUrl}
                              className="w-full h-full object-cover rounded-br"
                            />
                          </span>
                        )}
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              setNewOrgLogo(e.target?.result as string);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        accept="image/*"
                        className="hidden"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          // eslint-disable-next-line
                          // @ts-ignore
                          fileInputRef.current?.click();
                        }}
                        className="text-blue-600 text-sm hover:text-blue-700"
                      >
                        Upload New Logo
                      </button>
                    </div>
                  </div>

                  <form
                    onSubmit={updateAccountSettings}
                    className="space-y-6 w-full"
                  >
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="websiteUrl"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Website URL
                        </label>
                        <input
                          type="url"
                          id="websiteUrl"
                          name="websiteUrl"
                          value={formData.websiteUrl}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="disabled:bg-gray-400 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        {isLoading ? (
                          <span>Updating....</span>
                        ) : (
                          <span>Save</span>
                        )}
                      </button>

                      <Toast
                        title={
                          <div className="flex space-x-3 items-center">
                            <img
                              alt="Schematic Loader"
                              src="/schematic-load.gif"
                              className=" select-none -ml-2 w-6 h-6 inline-block"
                            />
                            <div>Your data has been updated</div>
                          </div>
                        }
                        open={dataUpdated!}
                        setOpen={(state) => setDataUpdated(state)}
                      ></Toast>
                    </div>
                  </form>
                </Alert>
              </div>
            </SettingsBox>
          )}
        </div>
      </div>
    </div>
  );
};
