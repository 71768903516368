import { PlanGroupPlanDetailResponseData } from "@models/api";
import { AddActivePlansOverlay } from "@modules/plans/components/overlays";
import { PlanConfigurationCard } from "@modules/plans/components/PlanConfigurationCard";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { SortableList } from "../sortables/SortableList";

enum BilledPeriod {
  Monthly = "monthly",
  Yearly = "yearly",
}

export type LivePlansElevateProps = {
  onCustomPlanSettingDisplay: (customPlanId: string | null) => void;
};

export const LivePlansElevate = ({
  onCustomPlanSettingDisplay,
}: LivePlansElevateProps) => {
  const { setFieldValue, values, touched, errors } =
    useFormikContext<PlansConfigurationViewFormValues>();

  const [addActivePlansOverlay, setAddActivePlansOverlay] = useState(false);
  const [billedPeriod, setBilledPeriod] = useState(BilledPeriod.Monthly);
  const [monthlyPlans, setMonthlyPlans] = useState<
    PlanGroupPlanDetailResponseData[]
  >([]);

  const [yearlyPlans, setYearlyPlans] = useState<
    PlanGroupPlanDetailResponseData[]
  >([]);

  // console.log(monthlyPlans, "@@@");
  useEffect(() => {
    if (monthlyPlans.length === 0) {
      setMonthlyPlans(values.plans.filter((p) => p.monthlyPrice));
    }

    if (yearlyPlans.length === 0) {
      setYearlyPlans(values.plans.filter((p) => p.yearlyPrice));
    }

    if (values.customPlanId) {
      setCustomPlanId(values.customPlanId);
    }
  }, [values]);

  useEffect(() => {
    const update = async () => {
      if (billedPeriod === BilledPeriod.Monthly) {
        await setFieldValue("plans", monthlyPlans);
      } else {
        await setFieldValue("plans", yearlyPlans);
      }
    };

    update();
  }, [monthlyPlans, yearlyPlans]);

  const [customPlanId, setCustomPlanId] = useState<string | null>(null);

  useEffect(() => {
    onCustomPlanSettingDisplay(customPlanId);
  }, [customPlanId]);

  return (
    <Elevate>
      <div className="flex justify-between items-center mb-4">
        <div className="text-xl flex items-center">
          <span className="h-3 w-3 bg-emerald-300 rounded-full inline-block mr-2"></span>
          Live plans
        </div>
        <div className="flex space-x-4">
          <LabeledTooltip
            description="Live Plans are visible in components and available for purchase."
            position="relative"
            placement="top-left"
            size="lg"
          />
          <div className="-space-x-4 flex border border-blue-100 rounded-3xl">
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Monthly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Monthly)}
            >
              {monthlyPlans.length} Billed Monthly
            </button>
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Yearly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Yearly)}
            >
              {yearlyPlans.length} Billed Yearly
            </button>
          </div>
        </div>
      </div>
      {((billedPeriod === BilledPeriod.Monthly && monthlyPlans.length === 0) ||
        (billedPeriod === BilledPeriod.Yearly && yearlyPlans.length === 0)) && (
        <Alert size="lg" style="light-gray" background="schematic">
          <div className="flex justify-center items-center">
            <Button
              color="blue"
              type="button"
              onClick={() => {
                setAddActivePlansOverlay(true);
              }}
            >
              Add plans
            </Button>
          </div>
        </Alert>
      )}

      {billedPeriod === BilledPeriod.Monthly && monthlyPlans.length > 0 && (
        <div className="flex relative">
          <div className="relative overflow-hidden grow">
            <div className="flex grow space-x-4 overflow-hidden overflow-x-scroll max-w-[99%]">
              <SortableList
                className="max-w-full overflow-x-auto"
                direction="row"
                items={monthlyPlans}
                setItems={setMonthlyPlans}
                renderItem={(plan) => {
                  return (
                    <PlanConfigurationCard
                      plan={plan}
                      setMonthlyPlans={setMonthlyPlans}
                      setYearlyPlans={setYearlyPlans}
                      key={plan.id}
                      planEntitlementsConfig={
                        values.orderedPlanList?.find(
                          (p) => p.planId === plan.id,
                        )?.entitlements
                      }
                      isCustom={customPlanId === plan.id}
                      displayCustom={true}
                      onCustomClick={async (checked: boolean) => {
                        if (!checked) {
                          setCustomPlanId(null);
                          await setFieldValue("customPlanId", null);
                          return;
                        }
                        await setFieldValue("customPlanId", plan.id);
                        setCustomPlanId(plan.id);
                      }}
                      onClose={async (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const newPlanIds = values.planIds.filter(
                          (item) => item !== plan.id,
                        );
                        const newPlans = values.plans.filter(
                          (item) => item.id !== plan.id,
                        );
                        await setFieldValue("planIds", newPlanIds);
                        await setFieldValue("plans", newPlans);
                        setMonthlyPlans(newPlans);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <Button
            type="button"
            className="relative right-0 top-0 bottom-0 !text-3xl"
            color="white"
            onClick={() => {
              setAddActivePlansOverlay(true);
            }}
          >
            +
          </Button>
        </div>
      )}

      {billedPeriod === BilledPeriod.Yearly && monthlyPlans.length > 0 && (
        <div className="flex relative">
          <div className="relative overflow-hidden grow">
            <div className="flex grow space-x-4 overflow-hidden overflow-x-scroll max-w-[99%]">
              <SortableList
                className="max-w-full overflow-x-auto"
                direction="row"
                items={yearlyPlans}
                setItems={setYearlyPlans}
                renderItem={(plan) => {
                  return (
                    <PlanConfigurationCard
                      plan={plan}
                      setMonthlyPlans={setMonthlyPlans}
                      setYearlyPlans={setYearlyPlans}
                      key={plan.id}
                      isCustom={customPlanId === plan.id}
                      displayCustom={true}
                      planEntitlementsConfig={
                        values.orderedPlanList?.find(
                          (p) => p.planId === plan.id,
                        )?.entitlements
                      }
                      onCustomClick={async (checked: boolean) => {
                        if (!checked) {
                          setCustomPlanId(null);
                          await setFieldValue("customPlanId", null);
                          return;
                        }
                        await setFieldValue("customPlanId", plan.id);
                        setCustomPlanId(plan.id);
                      }}
                      onClose={async (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const newPlanIds = values.planIds.filter(
                          (item) => item !== plan.id,
                        );
                        const newPlans = values.plans.filter(
                          (item) => item.id !== plan.id,
                        );
                        await setFieldValue("planIds", newPlanIds);
                        await setFieldValue("plans", newPlans);
                        setYearlyPlans(newPlans);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <Button
            type="button"
            className="relative right-0 top-0 bottom-0 !text-3xl"
            color="white"
            onClick={() => {
              setAddActivePlansOverlay(true);
            }}
          >
            +
          </Button>
        </div>
      )}

      {touched.planIds && errors.plans && errors.plans.length > 0 && (
        <Alert size="xs" style="red" className="mt-4">
          <div className="flex items-center justify-center space-x-2">
            <div className="text-base font-body ">
              <span className="font-semibold">Uh-oh!</span> All plans should
              have set-up default monthly or yearly price
            </div>
          </div>
        </Alert>
      )}

      {addActivePlansOverlay && (
        <AddActivePlansOverlay
          selectedPlans={values.plans}
          setActivePlans={async (plans) => {
            const planIds = plans.map((plan) => plan.id);

            if (billedPeriod === BilledPeriod.Yearly) {
              setYearlyPlans(plans);
            } else {
              setMonthlyPlans(plans);
            }
            //
            // await setFieldValue("plans", plans);
            // await setFieldValue("planIds", planIds);

            if (
              values.defaultPlanId &&
              !planIds.includes(values.defaultPlanId)
            ) {
              await setFieldValue("defaultPlan", undefined);
              await setFieldValue("defaultPlanId", undefined);
            }
          }}
          onClose={() => {
            setAddActivePlansOverlay(false);
          }}
        />
      )}
    </Elevate>
  );
};
