import { NotFound } from "@components/blanks/pages/NotFound";
import { SignInPage } from "@modules/accounts/SignInPage";
import { SignUpPage } from "@modules/accounts/SignUpPage";
import { companyRoutes } from "@modules/companies/routes";
import { companyUserRoutes } from "@modules/companyUsers/routes";
import { componentRoutes } from "@modules/components/routes";
import { EventsView, EventView } from "@modules/events";
import { featureRoutes, flagRoutes } from "@modules/features/routes";
import { integrationsRoutes } from "@modules/integrations/routes";
import { planRoutes } from "@modules/plans/routes";
import { quickstartRoutes } from "@modules/quickstart/routes";
import SelfService from "@modules/selfservice/SelfService";
import { settingRoutes } from "@modules/settings/routes";
import { BaseRoute } from "@routes/BaseRoute";
import { IndexRoutePage } from "@routes/IndexRoutePage";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { createBrowserRouter } from "react-router-dom";
import { ProtectedComponent } from "./ProtectedComponent";
import App from "../App";

export const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <BaseRoute />,
        errorElement: <ErrorBoundaryFallback />,
      },
      {
        path: "sign-up/*",
        element: <SignUpPage />,
        errorElement: <ErrorBoundaryFallback />,
      },
      {
        path: "sign-in/*",
        element: <SignInPage />,
        errorElement: <ErrorBoundaryFallback />,
      },
      {
        path: "onboard",
        element: <SelfService />,
        errorElement: <ErrorBoundaryFallback />,
      },
      {
        path: ":environmentId/*",
        element: <ProtectedComponent />,
        errorElement: <ErrorBoundaryFallback />,
        children: [
          {
            index: true,
            element: <IndexRoutePage />,
            errorElement: <ErrorBoundaryFallback />,
          },
          {
            path: "events",
            element: <EventsView />,
            errorElement: <ErrorBoundaryFallback />,
          },
          {
            path: "events/:id",
            element: <EventView />,
            errorElement: <ErrorBoundaryFallback />,
          },
          ...featureRoutes,
          ...flagRoutes,
          ...planRoutes,
          ...companyRoutes,
          ...companyUserRoutes,
          ...settingRoutes,
          ...integrationsRoutes,
          ...componentRoutes,
          ...quickstartRoutes,
        ],
      },
    ],
  },
]);
