import { useSignIn } from "@clerk/clerk-react";
import { FormikControl } from "@components/forms/FormikControl";
import { Button } from "@components/ui/Button";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { FormSignInProps } from "../consts";

type ResetSteps = "create" | "validate";
export const ResetForm = ({ setStep, setError }: FormSignInProps) => {
  const { setActive, isLoaded, signIn } = useSignIn();
  const [resetStep, setResetStep] = useState<ResetSteps>("create");

  const resetCreate = resetStep === "create";
  const resetValidate = resetStep === "validate";
  const handleResetCreate = async (
    values: { email: string },
    setSubmitting: any,
  ) => {
    if (!isLoaded) return;

    try {
      await signIn.create({
        identifier: values.email,
        strategy: "reset_password_email_code",
      });
      setResetStep("validate");
    } catch (err: any) {
      setSubmitting(false);
      setError(err.errors?.[0]?.message || "Something went wrong");
    }
  };

  const handleResetValidate = async (
    values: { password: string; code: string },
    setSubmitting: any,
  ) => {
    if (!isLoaded) return;

    try {
      await signIn
        .attemptFirstFactor({
          strategy: "reset_password_email_code",
          code: values.code,
          password: values.password,
        })
        .then((result) => {
          setActive({ session: result.createdSessionId });
        });

      window.location.href = "/";
    } catch (err: any) {
      setSubmitting(false);
      setError(err.errors?.[0]?.message || "Something went wrong");
    }
  };

  const renderSteps = () => {
    switch (true) {
      case resetCreate:
        return (
          <Formik
            initialValues={
              {
                email: "",
              } as any
            }
            validationSchema={Yup.object({
              email: Yup.string()
                .email()
                .required("Your email address is required!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleResetCreate(values, setSubmitting);
            }}
            className="space-y-4"
            key="form-reset-create"
          >
            {({ dirty, isSubmitting }) => {
              return (
                <Form>
                  <div className="flex items-start text-left flex-col space-y-4">
                    <div className="relative flex-1 w-full">
                      <FormikControl
                        control="input"
                        type="text"
                        name="email"
                        label="Email Address"
                        placeholder="Enter Email Address"
                        className="w-full"
                      />
                    </div>

                    <div className="w-full flex-1 flex pt-4">
                      <Button
                        type="submit"
                        className="w-full text-center max-w-[100%]"
                        color="blue"
                        disabled={!dirty || isSubmitting}
                      >
                        {isSubmitting ? (
                          <div
                            role="status"
                            className="flex items-center justify-center space-x-3"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-200 animate-spin dark:text-blue-400 fill-gray-200"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className=" leading-none">
                              Generating verification code
                            </span>
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </div>
                    <div className="w-full flex flex-col">
                      <div className="w-full flex">
                        <Button
                          className="w-full text-center max-w-[100%]"
                          color="white"
                          onClick={() => setStep("sign_in")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
      case resetValidate:
        return (
          <Formik
            initialValues={
              {
                code: "",
                password: "",
              } as any
            }
            validationSchema={Yup.object({
              code: Yup.number().required(
                "Your verification code is required!",
              ),
              password: Yup.string().required("Your password is required!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleResetValidate(values, setSubmitting);
            }}
            className="space-y-4"
            key="form-reset-validate"
          >
            {({ dirty, isSubmitting }) => {
              return (
                <Form>
                  <div className="flex items-start text-left flex-col space-y-4">
                    {signIn?.identifier && (
                      <div className="flex justify-between items-center w-full py-2 px-5 text-center text-sm rounded-full bg-blue-50 border-blue-100/50 border">
                        <div>
                          Password reset for{" "}
                          <span className="font-bold">
                            {signIn?.identifier}
                          </span>
                        </div>
                        <div>
                          {" "}
                          <div
                            className="w-full text-blue-400 cursor-pointer hover:underline"
                            onClick={() => setStep("sign_in")}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="relative flex-1 w-full">
                      <FormikControl
                        control="input"
                        type="text"
                        name="code"
                        label="Code"
                        placeholder="Enter Code"
                        className="w-full"
                      />
                    </div>
                    <div className="relative flex-1 w-full">
                      <FormikControl
                        control="input"
                        type="password"
                        name="password"
                        label="New Password"
                        placeholder="Enter New Password"
                        className="w-full"
                      />
                    </div>
                    <div className="w-full flex-1 flex pt-4">
                      <Button
                        type="submit"
                        className="w-full text-center max-w-[100%]"
                        color="blue"
                        disabled={!dirty || isSubmitting}
                      >
                        {isSubmitting ? (
                          <div
                            role="status"
                            className="flex items-center justify-center space-x-3"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-200 animate-spin dark:text-blue-400 fill-gray-200"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className=" leading-none">
                              Logging you in...
                            </span>
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
    }
  };
  return <div className="flex flex-col">{renderSteps()}</div>;
};
