import { useSignUp, useUser } from "@clerk/clerk-react";
import { FormikControl } from "@components/forms/FormikControl";
import { Button } from "@components/ui/Button";
import { router } from "@routes/routes";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { FormSignUpProps } from "../consts";

export const SignUpForm = ({ setStep, setError }: FormSignUpProps) => {
  const { isLoaded, signUp, setActive } = useSignUp();
  const { user } = useUser();

  const [searchParams] = useSearchParams();
  const clerkTicket = searchParams.get("__clerk_ticket");
  const email = searchParams.get("email");

  useEffect(() => {
    if (user?.id) {
      router.navigate("/");
    }
  }, [user?.id]);

  const handleSignUp = async (
    values: { email: string; password: string; phoneNumber: string },
    setSubmitting: any,
  ) => {
    if (!isLoaded) return;

    try {
      if (clerkTicket) {
        const signUpAttempt = await signUp.create({
          strategy: "ticket",
          ticket: clerkTicket,
          password: values.password,
        });

        if (signUpAttempt.status === "complete") {
          await setActive({ session: signUpAttempt.createdSessionId });
          setStep("done");
        }
      } else {
        // No need for email verification in case of invitation
        await signUp.create({
          emailAddress: values.email,
          password: values.password,
          ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
        });

        await signUp.prepareEmailAddressVerification();
        setStep("otp-email");
      }

      setError("");
    } catch (err: any) {
      setSubmitting(false);
      setError(err.errors?.[0]?.message || "Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={{
        email: email ?? "",
        password: "",
        phoneNumber: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required("Your email address is required!"),
        password: Yup.string().required("Your password is required!"),
        phoneNumber: Yup.string().matches(
          /^\+?[1-9]\d{1,14}$/,
          "Invalid phone number",
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSignUp(values, setSubmitting);
      }}
    >
      {({ dirty, isSubmitting }) => (
        <Form className="flex flex-col space-y-4 text-left">
          <div className="flex-1">
            <FormikControl
              control="input"
              type="text"
              name="email"
              label="Email Address"
              placeholder="Enter Email Address"
              className="w-full"
              required
            />
          </div>
          <div>
            <FormikControl
              control="input"
              type="password"
              name="password"
              label="Password"
              placeholder="Enter Password"
              className="w-full"
              required
            />
          </div>

          <div id="clerk-captcha"></div>

          <div className="w-full flex-1 flex pt-4">
            <Button
              type="submit"
              className="w-full text-center max-w-[100%]"
              color="blue"
              disabled={!dirty || isSubmitting}
              loading={isSubmitting}
            >
              {isSubmitting ? "Loading email verification..." : "Continue"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
