import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { Plan } from "@models/plan";
import { getCheckoutData } from "@modules/companies/queries";
import { listPlansWithEntitlements } from "@modules/plans/queries/planConfiguration";
import { PlanLabel } from "@ui/PlanLabel";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { ManagePlanWithSubscriptionFormValues } from "./CompanyWithSubscriptionManagePlanOverlay";
import { buildManagePlanFormValues } from "./helpers";

type PlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type ManagePlanPlanFieldsProps = {
  companyId: string;
};

export const ManagePlanPlanFields = ({
  companyId,
}: ManagePlanPlanFieldsProps) => {
  const { values, setValues, setFieldTouched } =
    useFormikContext<ManagePlanWithSubscriptionFormValues>();
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>();

  const { data: checkoutData } = useContextQuery({
    queryKey: ["checkoutData", companyId, "selectedPlanId", selectedPlanId],
    queryFn: () =>
      getCheckoutData({
        companyId,
        selectedPlanId: selectedPlanId,
      }),
    retry: false,
  });

  // TODO: Refactor duplicate
  useEffect(() => {
    if (checkoutData) {
      const {
        currentPrice,
        addOns,
        selectedPrice,
        payInAdvance,
        payAsYouGo,
        coupon,
      } = buildManagePlanFormValues(checkoutData);

      setValues((prevValues) => ({
        currentPlan:
          prevValues === undefined
            ? (checkoutData.activePlan as Plan)
            : prevValues.currentPlan,
        currentPrice:
          prevValues === undefined ? currentPrice : prevValues.currentPrice,
        currentAddOns:
          prevValues === undefined ? addOns : prevValues.currentAddOns,
        selectedPlan: checkoutData.selectedPlan as Plan,
        selectedPlanId: checkoutData.selectedPlan!.id,
        selectedPrice: selectedPrice,
        selectedPriceId: selectedPrice?.id,
        selectedAddOns: addOns,
        selectedAddOnIds: addOns.map(({ addOnId }) => addOnId),
        payInAdvance,
        payAsYouGo,
        coupon,
      }));
    }
  }, [checkoutData, setValues]);

  return (
    <FormikAsyncSelect
      className="flex-1"
      label="Plan"
      name="selectedPlanId"
      placeholder="Type to select plan..."
      defaultOptions
      loadOptions={listPlansWithEntitlements}
      loadOptionsMappers={{
        mapperFunction: (plan) => ({
          value: plan.id,
          label: <PlanLabel plan={plan} font="normal" />,
          entity: plan,
        }),
        requestFilter: {
          hasProductId: true,
        },
      }}
      selectedOption={
        values.selectedPlan && {
          value: values.selectedPlan.id,
          label: <PlanLabel plan={values.selectedPlan} font="normal" />,
          entity: values.selectedPlan,
        }
      }
      onChange={async (option: PlanOption) => {
        if (option?.entity) {
          const price = option.entity.monthlyPrice || option.entity.yearlyPrice;
          await setValues({
            ...values,
            // @ts-expect-error Formik fix
            selectedPrice: price || null,
            // @ts-expect-error Formik fix
            selectedPriceId: price?.id || null,
            // @ts-expect-error Formik fix
            selectedPlan: option?.entity || null,
          });
        } else {
          await setValues({
            ...values,
            // @ts-expect-error Formik fix
            selectedPrice: null,
            // @ts-expect-error Formik fix
            selectedPriceId: null,
            // @ts-expect-error Formik fix
            selectedPlan: option?.entity || null,
          });
        }
        await setFieldTouched("selectedPriceId", true, true);
        setSelectedPlanId(option?.entity.id);
      }}
    />
  );
};
