import { Button, ButtonProps } from "@components/ui/Button";
import { Pill, PillStyleTypes } from "@components/ui/Pill";
import cx from "classnames";

export interface ButtonWithPillProps extends ButtonProps {
  pillText?: string;
}

const pillColorMap = {
  blue: "blue",
  white: "white-button",
  red: "red",
  black: "gray",
};

export const ButtonWithPill = ({
  pillText,
  color = "white",
  children,
  disabled,
  ...props
}: ButtonWithPillProps) => {
  const pillStyles = cx(
    "ml-2 whitespace-nowrap transition-all",
    disabled ? "!bg-gray-300 !text-gray-400" : "bg-opacity-20 text-white",
    // This garbage is all to support the pill on a white button (it turns blue on hover)
    color === "white"
      ? "!text-gray-800 !group-hover:text-white !group-hover:bg-opacity-20 !group-hover:bg-[#D3DBF7]"
      : "",
  );

  return (
    <Button color={color} disabled={disabled} {...props}>
      <div className="flex flex-row items-center">
        {children}
        {pillText && (
          <Pill
            color={pillColorMap[color] as PillStyleTypes}
            type="rounded"
            className={pillStyles}
          >
            {pillText}
          </Pill>
        )}
      </div>
    </Button>
  );
};
