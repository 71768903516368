import { useEditor } from "@craftjs/core";
import { useStripeIntegration } from "@hooks/useStripeIntegration";
import { useEffect, useState } from "react";

export enum UsageCondition {
  USED = "USED",
  STRIPE_INTEGRATION_REQUIRED = "STRIPE_INTEGRATION_REQUIRED",
}

const StripeIntegrationRequiredComponents = {
  PlanManager: false,
  IncludedFeatures: false,
  MeteredFeatures: false,
  PricingTable: true,
  UpcomingBill: true,
  PaymentMethod: true,
  Invoices: true,
  UnsubscribeButton: true,
};

export const useComponentUsageCondition = (
  componentName: keyof typeof StripeIntegrationRequiredComponents,
): UsageCondition | null => {
  const { nodes } = useEditor((_, query) => ({
    nodes: query.getNodes(),
  }));

  const { hasStripeIntegration } = useStripeIntegration();

  const [isUsed, setIsUsed] = useState(false);

  useEffect(() => {
    const isComponentUsed = Object.values(nodes).some(
      (node) => node.data.name === componentName,
    );
    setIsUsed(isComponentUsed);
  }, [nodes, componentName]);

  if (isUsed) return UsageCondition.USED;

  if (
    StripeIntegrationRequiredComponents[componentName] &&
    !hasStripeIntegration
  ) {
    return UsageCondition.STRIPE_INTEGRATION_REQUIRED;
  }

  return null;
};
