import {
  useSchematicIsPending,
  useSchematicFlag,
} from "@schematichq/schematic-react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const IndexRoutePage = () => {
  const isPending = useSchematicIsPending();
  const showQuickstart = useSchematicFlag("onboarding", {
    fallback: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!isPending && showQuickstart) {
      navigate("/quickstart", {
        replace: true,
      });
    }
  }, [isPending, navigate, showQuickstart]);

  if (isPending) {
    return null;
  }

  const to = showQuickstart ? "quickstart" : "features";

  return <Navigate to={to} replace />;
};
