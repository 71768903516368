import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { BillingPriceResponseData } from "@models/api";
import { PriceIntervalDisplayName } from "@modules/plans/consts";
import { PlanSubview } from "@modules/plans/types";
import { Pill } from "@ui/Pill";

import { formatCurrency } from "@utils/strings";
import { SyntheticEvent } from "react";

interface PriceInfoBlockProps {
  planId: string;
  items: BillingPriceResponseData[];
}

export const PriceInfoBlock = ({ planId, items }: PriceInfoBlockProps) => {
  const navigate = useNavigateEnvironment();

  return (
    <div className="flex items-center space-x-1.5">
      {items.map((item, index) => {
        console.log("Price info block", item);
        return (
          index < 1 && (
            <div
              key={item.id}
              className="text-base flex items-baseline font-display text-gray-500/80"
            >
              {formatCurrency(item.price, item.currency, item.priceDecimal)}
              {PriceIntervalDisplayName[item.interval] || ""}
            </div>
          )
        );
      })}

      {items.length > 1 && (
        <Pill
          className="hover:bg-blue-400 transition-all hover:text-white hover:cursor-pointer z-10"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            navigate(`plans/${planId}/${PlanSubview.Billing}`);
          }}
          text="normal"
          type="rounded"
        >
          +{items.length - 1}
        </Pill>
      )}
    </div>
  );
};
