import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import {
  CompanyDetailResponseData,
  type EntityKeyDetailResponseData,
  UpsertUserRequestBody,
  UserDetailResponseData,
} from "@models/api";
import { EntityType } from "@models/entityKey";
import { CompanyOrCompanyUserEditOverlayKeyStep } from "@modules/companies/components/overlays/CompanyOrCompanyUserEditOverlayKeyStep";
import {
  createCompanyUser,
  updateCompanyUser,
} from "@modules/companyUsers/queries";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { OverlayFormAlert } from "@ui/OverlayFormAlert";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { CompanyUserEditOverlayDefineStep } from "./CompanyUserEditOverlayDefineStep";

type CompanyUserEditOverlayProps = {
  onClose: () => void;
  user?: UserDetailResponseData;
};

export type CompanyUserValue = {
  name: string;
  companies: CompanyDetailResponseData[];
  companyIds: string[];
  keys: (EntityKeyDetailResponseData & { uuid: string })[];
};

export const CompanyUserEditOverlay = ({
  onClose,
  user,
}: CompanyUserEditOverlayProps) => {
  const queryClient = useQueryClient();
  const verb = user?.id ? "Edit" : "Create";
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [dirty, setDirty] = useState(false);

  const companies = (user?.companyMemberships || []).map(
    ({ company }) => company!,
  );

  const initialValues = {
    name: user?.name ?? "",
    companies,
    companyIds: companies.map((company) => company.id),
    keys: (user?.keys || []).map((key) => ({ ...key, uuid: uuidv4() })),
  } as CompanyUserValue;

  const onSubmit = async (
    { companies, ...restValues }: CompanyUserValue,
    helpers: FormikHelpers<CompanyUserValue>,
  ) => {
    setLoading(true);
    const saveFn = user?.id
      ? (values: UpsertUserRequestBody) => updateCompanyUser(user.id, values)
      : createCompanyUser;

    try {
      const reqData: any = {
        ...restValues,
        keys: restValues.keys.reduce((acc, key) => {
          return {
            ...acc,
            [key.key]: key.value,
          };
        }, {}),
      };

      await saveFn(reqData);
      await queryClient.invalidateQueries();
      setApiError(undefined);
      onClose();
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());

  return (
    <Overlay
      onClose={handleClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl">
        <FormikStepper
          className="flex-1 w-full"
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: any) => {
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!user?.id}
          initialValues={initialValues}
        >
          <FormikStep
            label="Define"
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              companyIds: Yup.array()
                .of(Yup.string())
                .min(1, "Company is required"),
            })}
          >
            <CompanyUserEditOverlayDefineStep verb={verb} />
          </FormikStep>

          <FormikStep
            label="Keys"
            validationSchema={Yup.object().shape({
              keys: Yup.array()
                .min(1)
                .of(
                  Yup.object().shape({
                    id: Yup.string().required("Key is required"),
                    value: Yup.string().required("Value is required"),
                  }),
                ),
            })}
          >
            <CompanyOrCompanyUserEditOverlayKeyStep
              label={`${verb} user`}
              entityType={EntityType.User}
            />

            {apiError && (
              <div className="px-2">
                <Alert size="xs" style="red">
                  <div className="flex items-center justify-center space-x-2">
                    <div className="text-base font-body ">
                      <span className="font-semibold">Uh-oh!</span> {apiError}
                    </div>
                  </div>
                </Alert>
              </div>
            )}
          </FormikStep>
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
