import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import * as integrationsApi from "@data/integrations";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { ButtonStyleTypes } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import React, { useState } from "react";

type ClerkIntegrationDeleteOverlayProps = {
  onClose: () => void;
  integrationId: string;
};

export const ClerkIntegrationDeleteOverlay = ({
  onClose,
  integrationId,
}: ClerkIntegrationDeleteOverlayProps) => {
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const removeIntegrationMutation = useMutation({
    mutationFn: integrationsApi.removeIntegration,
  });

  const handleUninstall = async () => {
    setLoading(true);
    try {
      await removeIntegrationMutation.mutateAsync(integrationId);
      setApiError(undefined);

      onClose();
    } catch (error) {
      setApiError(errorMessage(error));
    }
    setLoading(false);
  };

  const actionButtons = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Remove integration",
      color: "blue" as ButtonStyleTypes,
      onClick: handleUninstall,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md" className="pb-12">
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-4">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">Remove Clerk connection?</div>

            <div>Removes Clerk integration from Schematic.</div>
          </div>

          <Alert style="yellow" size="xs" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-red-500"
              />
            </div>
            <div>
              Any data that has been imported from Clerk will not be deleted and
              you will stop receiving new updates from Clerk. As a result, you
              may have stale user and organization data that could impact team
              management and permissions.
            </div>
          </Alert>
        </div>

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}

        <div className="px-12">
          <ButtonGroup
            buttons={actionButtons}
            className="flex !justify-between "
          />
        </div>
      </OverlayModal>
    </Overlay>
  );
};
