import { useSignUp } from "@clerk/clerk-react";
import { FormikControl } from "@forms/FormikControl";
import { FormSignUpProps } from "@modules/accounts/consts";
import { Button } from "@ui/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";

export const SignUpPhoneOtpForm = ({ setStep, setError }: FormSignUpProps) => {
  const { isLoaded, signUp } = useSignUp();

  const handlePhoneVerification = async (
    setSubmitting: any,
    phoneVerificationCode: string,
  ) => {
    if (!isLoaded) return;
    try {
      const result = await signUp.attemptPhoneNumberVerification({
        code: phoneVerificationCode,
      });
      if (result.status === "complete") {
        setStep("done");
      } else {
        setError("Phone verification succeeded, but account is not complete.");
      }
    } catch (error: any) {
      setSubmitting(false);
      setError("Invalid verification code.");
    }
  };

  return (
    <Formik
      initialValues={{ phoneVerificationCode: "" }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handlePhoneVerification(setSubmitting, values.phoneVerificationCode);
      }}
      validationSchema={Yup.object({
        phoneVerificationCode: Yup.string()
          .length(6, "Verification code must be 6 digits")
          .required("Verification code is required"),
      })}
      key="form-otp-phone"
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <div className="text-left">
            <FormikControl
              control="input"
              type="text"
              name="phoneVerificationCode"
              label="Phone Verification Code"
              placeholder="Enter verification code sent to your phone"
              className="w-full"
            />
          </div>
          <div className="w-full flex-1 flex pt-4">
            <Button
              type="submit"
              className="w-full text-center max-w-[100%]"
              color="blue"
              disabled={!dirty || isSubmitting}
              loading={isSubmitting}
            >
              {isSubmitting ? "Verifying code..." : "Verify"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
