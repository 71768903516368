import { SettingsPageLoader } from "@components/loaders/SettingsPageLoader";
import { Icon } from "@components/ui/Icon";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { InstalledIntegration } from "@models/integrations";
import { BillingCustomersTable } from "@modules/integrations/components/tables/BillingCustomersTable";
import { BillingProductsTable } from "@modules/integrations/components/tables/BillingProductsTable";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { SettingsBox } from "@ui/SettingsBox";
import { useEffect, useMemo, useState } from "react";
import { StripeIntegrationForm } from "../../../settings/components/StripeIntegrationForm";

export const StripeIntegrationPage = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(5000);

  const {
    isSuccess,
    data: integrationData,
    isLoading,
  } = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    if (integrationData && integrationData[0]?.state === "created") {
      setRefetchInterval(2000);
      return;
    }

    setRefetchInterval(false);
  }, [integrationData]);

  const customerImportEnabledFlag = useSchematicFlag(
    "integration.stripe.customers.import",
  );

  const integration = useMemo((): InstalledIntegration | null => {
    if (!isSuccess || !integrationData?.[0]) {
      return null;
    }

    // get fist integration of Stripe type
    const integration = integrationData?.[0] as InstalledIntegration;

    if (integration.state === "active") {
      return integration;
    }

    if (
      integration.config?.version === 2 &&
      integration.config?.onboarding_url !== undefined
    ) {
      return integration;
    }

    return null;
  }, [isSuccess, integrationData]);

  const getConnectedStripeEnvironment = () => {
    if (!integration || !integration.config?.version) {
      return <></>;
    }

    if (integration.config?.version === 2) {
      switch (integration.config?.live_mode) {
        case false:
          return (
            <label className="text-sm text-yellow-700">
              Connected in {!integration.config?.is_sandbox && "legacy"} test
              mode {integration.config?.is_sandbox && "(sandbox)"}
            </label>
          );
        default:
          return (
            <label className="text-sm text-green-700">
              Connected in live mode
            </label>
          );
      }
    }

    return integration.config?.api_key?.startsWith("sk_test") ? "test" : "live";
  };

  if (isLoading) {
    return <SettingsPageLoader />;
  }

  return (
    <div className="flex flex-col space-y-8 max-w-full justify-items-start w-full">
      <div>
        <div>
          <SettingsBox
            title="Stripe Integration"
            label={getConnectedStripeEnvironment()}
            description={
              <>
                Rollout flags, define entitlements, and build plan audiences
                based on Stripe subscriptions.{" "}
                <a
                  target="_blank"
                  href="https://docs.schematichq.com/integrations/stripe"
                  className="cursor-pointer text-blue-400 hover:underline hover:opacity-80"
                >
                  Learn more in the docs
                  <Icon
                    name="arrow-right"
                    className="-rotate-45 inline-block ml-1 text-base"
                  />
                </a>
                .
              </>
            }
            style="boxed"
            className="h-auto"
          >
            <div className=" absolute right-12 top-12 flex p-1 items-center rounded-full border border-gray-300">
              <Icon
                name="stripe"
                className="text-[#6772E5] text-2xl leading-none"
              />
            </div>
            <StripeIntegrationForm />
          </SettingsBox>
        </div>
      </div>

      {integrationData?.[0]?.state === "created" && (
        <div>
          <SettingsBox
            title="Import in progress"
            description="This will take a few minutes if you have a lot of customers and subscriptions in Stripe"
            style="boxed"
            className="h-auto"
          >
            <div className={"flex  flex-row gap-6 w-full"}>
              <div className="bg-gray-200 flex-1 h-2 rounded-full relative overflow-hidden">
                <div className="absolute bg-blue-400 h-2 w-[25%] left-0 top-0 rounded-full animate-bar"></div>
              </div>
            </div>
          </SettingsBox>
        </div>
      )}

      {isSuccess && integration && (
        <>
          {customerImportEnabledFlag && (
            <div>
              <BillingCustomersTable />
            </div>
          )}

          <div>
            <BillingProductsTable />
          </div>
        </>
      )}

      <div>
        <SettingsBox
          title="Unique Identifier Key"
          description="Map Stripe Customers to Companies in Schematic. Include the Stripe customer ID in any API request or event sent to Schematic."
          style="boxed"
          className="h-auto"
        >
          <div className="flex space-x-6 items-center">
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="stripe"
                  className="text-[#6772E5] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">customer_id</div>
            </div>
            <div>
              <Icon name="arrow-right" className="text-3xl leading-none" />
            </div>
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="schematic"
                  className="text-[#DB6769] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">stripe_customer_id</div>
            </div>
          </div>
        </SettingsBox>
      </div>
    </div>
  );
};
