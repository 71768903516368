import { useSignUp } from "@clerk/clerk-react";
import { Alert } from "@components/ui/Alert";
import { useEffect, useState } from "react";
import { FormSignInSteps } from "./consts";
import {
  AccountPageContent,
  AccountPageWrapper,
  AnotherMethod,
  SignInPhoneForm,
  PrivacyPolicy,
  ResetForm,
  SignInForm,
  SignInPhoneOtpForm,
  SocialForm,
  VideoContent,
} from ".";

export const SignInPage = () => {
  const { signUp } = useSignUp();
  const [step, setStep] = useState<FormSignInSteps>("sign_in");
  const [error, setError] = useState("");

  const isOtpPhone = step === "otp-phone";
  const isPhone = step === "phone";
  const isReset = step === "reset";
  const isSignIn = step === "sign_in";

  const isSocialForm = !isOtpPhone && !isPhone && !isReset;
  const isAnotherMethod = !isSignIn && !isReset;

  const renderForm = () => {
    switch (true) {
      case isSignIn:
        return <SignInForm setError={setError} setStep={setStep} />;
      case isPhone:
        return <SignInPhoneForm setError={setError} setStep={setStep} />;
      case isOtpPhone:
        return <SignInPhoneOtpForm setError={setError} setStep={setStep} />;
      case isReset:
        return <ResetForm setError={setError} setStep={setStep} />;
    }
  };

  const accountExists =
    signUp?.verifications?.externalAccount?.error?.code ===
    "external_account_exists";

  useEffect(() => {
    accountExists && setError("Account already exists");
  });

  return (
    <AccountPageWrapper>
      <AccountPageContent
        title="Welcome to Schematic"
        description={
          <>
            Login below or sign up
            <span
              className="text-[#DB6769] ml-1 cursor-pointer"
              onClick={() => {
                window.location.href = "/sign-up";
              }}
            >
              here
            </span>
          </>
        }
        actionDescription="No Schematic account?"
        actionLabel="Sign up"
        actionUrl="sign-up"
      >
        {renderForm()}

        {error && (
          <Alert size="xs" style="red" className="mt-8 text-sm py-4 w-full">
            {error}
          </Alert>
        )}

        {isAnotherMethod && (
          <AnotherMethod setStep={setStep} setError={setError} />
        )}
        {isSocialForm && <SocialForm setError={setError} type="sign-in" />}
        {!isReset && <PrivacyPolicy type="sign-in" />}
      </AccountPageContent>
      <VideoContent />
    </AccountPageWrapper>
  );
};
