import { AuthenticationRedirect } from "@routes/AuthenticatedRedirect";
import { ReactNode, StrictMode } from "react";

interface AccountPageProps {
  children: ReactNode;
}

export const AccountPageWrapper = ({ children }: AccountPageProps) => {
  return (
    <StrictMode>
      <AuthenticationRedirect>
        <div className="w-screen h-screen -mt-[93px] login-gradient">
          <div className="flex flex-col lg:flex-row h-auto lg:h-full justify-start max-w-[1600px] mx-auto overflow-y-auto max-h-screen lg:overflow-hidden">
            {children}
          </div>
        </div>
      </AuthenticationRedirect>
    </StrictMode>
  );
};
