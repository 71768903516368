import { Button } from "@components/ui/Button";
import { FormSignInProps } from "../consts";

export const AnotherMethod = ({ setStep, setError }: FormSignInProps) => {
  const handleClick = () => {
    setStep("sign_in");
    setError("");
  };

  return (
    <div className="text-center">
      <div className="relative font-display text-lg mt-8 leading-none mb-6 border-t border-gray-300 my-12">
        <span className="bg-white px-4 absolute left-[50%] translate-x-[-50%] translate-y-[-55%] top-[50%] leading-none text-sm">
          or
        </span>
      </div>
      <div className="flex">
        <Button
          onClick={handleClick}
          color="black"
          className="w-full text-center mt-4 hover:text-white hover:bg-blue-400 !py-3"
        >
          Use another method
        </Button>
      </div>
    </div>
  );
};
