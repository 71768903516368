import { DisabledReasonsType } from "@components/ui/PermissionButton";
import { useMemo } from "react";

export const useDisabledReason = (
  isUserAllowed: boolean,
  isFeatureEnabled: boolean,
  isUnderQuota: boolean,
): DisabledReasonsType | undefined => {
  return useMemo(
    () =>
      !isUserAllowed
        ? DisabledReasonsType.Permission
        : !isFeatureEnabled
          ? DisabledReasonsType.Entitlement
          : !isUnderQuota
            ? DisabledReasonsType.Quota
            : undefined,
    [isUserAllowed, isFeatureEnabled, isUnderQuota],
  );
};
