import { Pill } from "@components/ui/Pill";

import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import {
  type PlanEntitlementResponseData,
  type PlanEntitlementsOrder,
  PlanGroupPlanDetailResponseData,
} from "@models/api";
import { FeatureType } from "@models/feature";
import { Checkbox } from "@modules/components/components/builder/ui";
import { EntitlementCell } from "@modules/features";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { PriceInfoBlock } from "@modules/plans/components/PriceInfoBlock";
import { PlanSubview } from "@modules/plans/types";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Button } from "@ui/Button";
import { Icon, IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { PlanLabel } from "@ui/PlanLabel";
import { useFormikContext } from "formik";
import pluralize from "pluralize";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { SortableList } from "./sortables/SortableList";

export interface OrderedEntitlement extends PlanEntitlementResponseData {
  visible: boolean;
}

type PlanConfigurationCardProps = {
  plan: PlanGroupPlanDetailResponseData;
  isCustom?: boolean;
  displayCustom?: boolean;
  setMonthlyPlans?: React.Dispatch<any>;
  setYearlyPlans?: React.Dispatch<any>;
  onCustomClick?: (selected: boolean) => void;
  onClose: (e: SyntheticEvent) => void;
  planEntitlementsConfig?: PlanEntitlementsOrder[];
};

export const PlanConfigurationCard = ({
  plan,
  onClose, // TODO: update to onDelete
  isCustom,
  displayCustom,
  planEntitlementsConfig,
  onCustomClick,
}: PlanConfigurationCardProps) => {
  const navigate = useNavigateEnvironment();
  const customPlanEnabled = useSchematicFlag("custom-plan");
  const [orderedEntitlements, setOrderedEntitlements] = useState<
    OrderedEntitlement[]
  >([]);

  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<PlansConfigurationViewFormValues>();

  useEffect(() => {
    const orderMap = new Map();
    planEntitlementsConfig?.forEach((item, index) => {
      orderMap.set(item.planEntitlementId, index);
    });

    const sortedEntitlements = plan.entitlements?.sort((a, b) => {
      const posA = orderMap.get(a.id);
      const posB = orderMap.get(b.id);

      return posA - posB;
    });

    setOrderedEntitlements(
      sortedEntitlements?.map((e) => {
        const entConfig = planEntitlementsConfig?.find(
          (p) => e.id == p.planEntitlementId,
        );
        let visible = false;
        if (typeof entConfig === "undefined") {
          visible = true;
        }
        if (typeof entConfig === "object") {
          visible = entConfig.visible!;
        }

        return {
          visible,
          ...e,
        };
      }),
    );
  }, [plan.entitlements, planEntitlementsConfig]);

  const handleEntitlementsReorder = async (
    sortedEntitlements: OrderedEntitlement[],
  ) => {
    if (!values.orderedPlanList) {
      return;
    }

    let planIndex = values.orderedPlanList!.findIndex(
      (p) => p.planId === plan.id,
    );

    if (planIndex === -1) {
      const newOrderedValues = values.orderedPlanList?.push({
        planId: plan.id,
        entitlements: plan.entitlements.map((e) => ({
          planEntitlementId: e.id,
          visible: true,
        })),
      });
      await setFieldValue("orderedPlanList", newOrderedValues);
      planIndex = values.orderedPlanList.length;
    }

    const planConfigOrder = values.orderedPlanList?.find(
      (p) => p.planId === plan.id,
    );
    if (!planConfigOrder) {
      console.warn(
        "Something went wrong.... Can't find the ordered plan config for entitlements. ",
        { plan_id: plan.id },
      );
    }

    values.orderedPlanList![planIndex]!.entitlements = sortedEntitlements.map(
      (ent) => {
        return {
          planEntitlementId: ent.id,
          visible: isEntitlementVisible(ent.id),
        };
      },
    );

    await setFieldValue("orderedPlanList", values.orderedPlanList);
    await setFieldTouched("orderedPlanList", true);
  };

  const onAddEnvironmentButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    navigate(`plans/${plan.id}/${PlanSubview.Entitlements}`);
  };

  const handleEntitlementVisibilityToggle = async (
    eId: string,
    val: boolean,
  ) => {
    values.orderedPlanList?.forEach((p) => {
      if (p.planId === plan.id) {
        let setEntitlement = false;
        p.entitlements?.forEach((entitlement) => {
          if (entitlement.planEntitlementId === eId) {
            entitlement.visible = val;
            setEntitlement = true;
          }
        });

        if (!setEntitlement) {
          p.entitlements?.push({
            planEntitlementId: eId,
            visible: val,
          });
        }
      }
    });

    await setFieldValue("orderedPlanList", values.orderedPlanList);
    await setFieldTouched("orderedPlanList", true);
  };

  const isEntitlementVisible = useCallback(
    (entitlementId: string) => {
      const planConfig = values.orderedPlanList?.find(
        (p) => p.planId === plan.id,
      );
      if (!planConfig) {
        return true;
      }

      const entitlementConfig = planConfig.entitlements?.find(
        (e) => e.planEntitlementId === entitlementId,
      );
      if (!entitlementConfig) {
        return true;
      }

      return entitlementConfig.visible;
    },
    [plan, values.orderedPlanList],
  );

  return (
    <div>
      <div className="min-w-70 bg-gray-100 flex flex-col border border-blue-100 rounded-lg relative">
        <div className="flex flex-col border-b border-blue-100 p-6">
          {/* min-h-[123px] max-h-[123px] h-[123px] */}
          <div className="flex flex-row space-x-5 items-center ">
            <div className="inline-block">
              <button
                type="button"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  onClose(e);
                }}
                className="inline-flex absolute top-0.5 right-1"
              >
                <Icon
                  name="close"
                  className="cursor-pointer text-black/30 text-[1.6rem] leading-none hover:text-blue-400"
                />
              </button>
            </div>

            {/* {customPlanEnabled && displayCustom && (
              <div
                className="inline-flex items-center justify-cente absolute top-0.5 right-8"
                {...listeners}
                {...attributes}
              >
                <Icon
                  name="move"
                  className="cursor-pointer text-black/30 text-[1.6rem] leading-none hover:text-blue-400"
                />
              </div>
            )} */}
          </div>

          <div className="mb-4">
            <PlanLabel plan={plan} className="" />
          </div>

          <div className="flex flex-col space-y-1">
            <div className="flex flex-row justify-between">
              <div className="text-gray-500 text-sm mr-6">Companies:</div>
              <div className="text-black">{plan.companyCount}</div>
            </div>

            {plan.billingProduct && (
              <div className="flex flex-row justify-between">
                <div className="text-gray-500 text-sm mr-6">Price:</div>
                <div className="text-black">
                  <div className="flex items-center">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-blue-400 border border-blue-100 rounded-full text-center mr-1 leading-none">
                      <Icon name="stripe" />
                    </div>

                    <div className="!text-xs">
                      {/* TODO: Need to update text sizing from here */}
                      <PriceInfoBlock
                        planId={plan.id}
                        items={plan.billingProduct.prices}
                      />
                    </div>

                    {/* {!plan.monthlyPrice && !plan.yearlyPrice && (
                      <Pill
                        color="red"
                        text="normal"
                        type="rounded"
                        className="ml-2"
                      >
                        No default price on product
                      </Pill>
                    )} */}
                  </div>
                </div>
              </div>
            )}
            {plan.isTrialable && (
              <div className="flex flex-row justify-between">
                <div className="text-gray-500 text-sm mr-6">Trial</div>
                <div className="text-black">
                  {plan.trialDays} {pluralize("Day", plan.trialDays)}{" "}
                </div>
              </div>
            )}
          </div>

          {plan.isTrialable && (
            <div className="mt-1">
              <Pill className="text-sm" text="normal">
                Trial supported
              </Pill>
            </div>
          )}
        </div>

        {plan.entitlements?.length === 0 && (
          <div className="flex flex-col space-y-3 p-6 min-h-[260px] text-center justify-center">
            <div className="text-sm">No entitlement yet</div>
            <div>
              <Button
                color="white"
                className="text-sm w-auto !text-blue-400 hover:!text-white"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  onAddEnvironmentButtonClick(e);
                }}
              >
                Add Entitlements
              </Button>
            </div>
          </div>
        )}

        {plan.entitlements?.length > 0 && (
          <div className="flex flex-col space-y-3 h-full min-h-[260px] max-h-[260px] overflow-hidden overflow-y-auto">
            <div className="">
              <SortableList
                direction="column"
                items={orderedEntitlements}
                setItems={(sortedItems) => {
                  handleEntitlementsReorder(sortedItems);
                }}
                renderItem={(entitlement) => {
                  const feature = entitlement.feature;
                  return (
                    <React.Fragment key={entitlement.id}>
                      <div className="flex items-center w-full">
                        <div className="mr-2">
                          {/*
                            TODO: Make a common class to use for IconRound
                            and normal icons so it supports other icon types
                          */}
                          <IconRound
                            className="!-ml-1 !text-sm !bg-transparent !border-transparent !border-0 !leading-none "
                            name={entitlement.feature!.icon as IconNameTypes}
                            size="xs"
                          />
                        </div>
                        {feature?.featureType === FeatureType.Boolean ? (
                          <div className="text-sm">{feature.name}</div>
                        ) : (
                          <div className="text-sm">
                            <EntitlementCell
                              showUsageBasedPricesDescription
                              entitlement={entitlement}
                              showSmallText={false}
                            />
                          </div>
                        )}
                        <div
                          className={
                            "absolute top-[50%] translate-y-[-50%] right-4"
                          }
                          onMouseDown={async (e) => {
                            e.stopPropagation();
                            await handleEntitlementVisibilityToggle(
                              entitlement.id,
                              !isEntitlementVisible(entitlement.id),
                            );
                          }}
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill={
                              isEntitlementVisible(entitlement.id)
                                ? "black"
                                : "black"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                              isEntitlementVisible(entitlement.id)
                                ? "group-hover:opacity-100 opacity-0"
                                : "opacity-100"
                            }
                          >
                            <path d="M4.07158 6.67603C2.96943 7.40485 1.95707 8.26059 1.05609 9.22621C0.913594 9.37984 0.913594 9.61808 1.05609 9.77171C1.9779 10.7692 5.40899 14.1535 9.49988 14.1535C10.1166 14.1513 10.7304 14.0778 11.3301 13.9346L9.85762 12.4621C9.73887 12.4762 9.61938 12.4828 9.49988 12.4828C8.70871 12.4836 7.95021 12.1689 7.3905 11.6093C6.8309 11.0504 6.5162 10.2912 6.51694 9.4999C6.51694 9.3804 6.52436 9.26091 6.53772 9.14291L4.07158 6.67603Z" />
                            <path d="M17.9442 9.77165C18.0867 9.61802 18.0867 9.37978 17.9442 9.22614C17.0224 8.22864 13.5913 4.84645 9.50174 4.84645C8.47974 4.85758 7.46741 5.05203 6.51361 5.42164L2.80196 1.70923L1.71094 2.80099L16.2007 17.2908L17.2917 16.1997L13.9831 12.8911C15.4563 12.0613 16.7922 11.0089 17.9442 9.77165ZM11.2281 10.1361C11.4789 9.46216 11.3134 8.70364 10.805 8.19522C10.2966 7.68682 9.5381 7.52131 8.86417 7.77217L8.01214 6.92013C8.77436 6.47705 9.69394 6.39541 10.5222 6.69599C11.3513 6.99657 12.0036 7.64969 12.305 8.47798C12.6055 9.30701 12.5232 10.2258 12.0808 10.9881L11.2281 10.1361Z" />
                          </svg>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            </div>

            {/* Old List */}
            <div className="bg-red-100 hidden">
              <div className="mb-1 font-bold">Old List:</div>
              {plan.entitlements.map((entitlement) => {
                const feature = entitlement.feature;
                return (
                  <React.Fragment key={entitlement.id}>
                    <div className="flex items-center">
                      <div className="mr-2">
                        <IconRound
                          className="bg-gray-300/70"
                          name={entitlement.feature!.icon as IconNameTypes}
                          size="xs"
                        />
                      </div>
                      {feature?.featureType === FeatureType.Boolean ? (
                        <div className="text-sm">{feature.name}</div>
                      ) : (
                        <div className="text-sm">
                          <EntitlementCell
                            entitlement={entitlement}
                            showSmallText={false}
                          />
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            {/*

              TODO: Need to rework this interaction:

              1. List should be scrollable, otherwise they wont be able to drag and drop between all the items
              2. Instead of + more button, we can increase the height of the scrollable area using a view all button

            */}

            {/* {plan.entitlements?.length > 4 && (
              <div className="pt-2">
                <Link
                  to={`../plans/${plan.id}/${PlanSubview.Entitlements}`}
                  className="font-medium text-sm text-blue-400 hover:text-blue-800"
                >
                  +{plan.entitlements.length - 3} More
                </Link>
              </div>
            )} */}
          </div>
        )}
      </div>

      <div className="flex justify-between items-center py-4">
        {displayCustom && customPlanEnabled && (
          <div className="flex flex-row justify-start gap-2">
            <div>
              <Checkbox
                checked={isCustom}
                value={plan.id}
                onPointerDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  // eslint-disable-next-line
                  // @ts-ignore
                  onCustomClick(e.target.checked as boolean);
                }}
                label="Is custom"
                className="!h-[20px] !w-[20px]"
              />
            </div>
            <label className="text-sm">Custom</label>
          </div>
        )}
        <div
          className="text-blue-400 text-sm font-medium"
          onPointerDown={(e) => e.stopPropagation()}
          onClick={onAddEnvironmentButtonClick}
        >
          Add Entitlements
        </div>
      </div>
    </div>
  );
};
