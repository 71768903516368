import {
  BillingPriceView,
  CompanyOverrideResponseData,
  CreateCompanyOverrideRequestBodyValueTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
  CreatePlanEntitlementRequestBodyValueTypeEnum,
  PlanEntitlementResponseData,
} from "@models/api";
import { Feature } from "@models/feature";
import { Plan } from "@models/plan";

export type CompanyOverride = CompanyOverrideResponseData & {
  feature?: Feature;
  metricPeriod?: CreateOrUpdateConditionRequestBodyMetricPeriodEnum;
  metricPeriodMonthReset?: CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum;
  valueType: CreateCompanyOverrideRequestBodyValueTypeEnum;
  // added to match types
  priceBehavior?: PriceBehaviourType;
  meteredYearlyPrice?: BillingPriceView;
  meteredMonthlyPrice?: BillingPriceView;
  softLimit?: number;
};

export type PlanEntitlement = PlanEntitlementResponseData & {
  feature?: Feature;
  plan?: Plan;
  priceBehavior?: PriceBehaviourType;
  meteredYearlyPrice?: BillingPriceView;
  meteredMonthlyPrice?: BillingPriceView;
  metricPeriod?: CreateOrUpdateConditionRequestBodyMetricPeriodEnum;
  metricPeriodMonthReset?: CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum;
  valueType: CreatePlanEntitlementRequestBodyValueTypeEnum;
  softLimit?: number;
};

export enum EntitlementValueType {
  Boolean = "boolean",
  Numeric = "numeric",
  Unlimited = "unlimited",
  Trait = "trait",
}

export enum PriceBehaviourType {
  PayAsYouGo = "pay_as_you_go",
  PayInAdvance = "pay_in_advance",
  Overage = "overage",
}
