import { useUser } from "@clerk/clerk-react";
import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface AuthenticationRedirectProps {
  children: ReactNode;
}

export const AuthenticationRedirect = ({
  children,
}: AuthenticationRedirectProps) => {
  const { isLoaded, isSignedIn } = useUser();
  const { pathname } = useLocation();

  if (!isLoaded) {
    return <SchematicOverlayLoader className="top-0" />;
  }

  if (isSignedIn && (pathname === "/sign-in" || pathname === "/sign-up")) {
    return <Navigate to="/" replace />;
  }

  return children;
};
