import { SettingsView } from "@modules/settings/components";
import {
  ApiKeysPage,
  AuditLogPage,
  KeysAndTraitsPage,
  MiscPage,
  UsageAndBillingPage,
  TeamPage,
} from "@modules/settings/components/pages";
import { AccountSettingsPage } from "@modules/settings/components/pages/AccountSettings";
import { SettingsPage } from "@modules/settings/consts";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { Navigate, RouteObject } from "react-router-dom";
import { PlansPage } from "./components/pages/PlansPage";

export const settingRoutes: RouteObject[] = [
  {
    path: "settings/:page?",
    element: <SettingsView />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      { index: true, element: <Navigate to={SettingsPage.AuditLog} replace /> },
      {
        path: SettingsPage.AuditLog,
        element: <AuditLogPage />,
      },
      {
        path: SettingsPage.Team,
        element: <TeamPage />,
      },
      {
        path: SettingsPage.KeysAndTraits,
        element: <KeysAndTraitsPage />,
      },
      {
        path: SettingsPage.ApiKeys,
        element: <ApiKeysPage />,
      },
      {
        path: SettingsPage.AccountSettings,
        element: <AccountSettingsPage />,
      },
      {
        path: SettingsPage.Plans,
        element: <PlansPage />,
      },
      {
        path: SettingsPage.UsageAndBilling,
        element: <UsageAndBillingPage />,
      },
      {
        path: SettingsPage.Misc,
        element: <MiscPage />,
      },
    ],
  },
];
