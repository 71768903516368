import { NotFound } from "@components/blanks/pages/NotFound";
import { SettingsBox } from "@components/ui/SettingsBox";
import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { SettingsPage } from "@modules/settings/consts";
import { SchematicEmbed } from "@schematichq/schematic-components";
import { Navigate } from "react-router-dom";
import { createTemporaryToken } from "../../queries/tokens";

export const PlansPage = () => {
  const plansBillingSettingsEditAllowed = usePermission(
    ClerkUserPermission.plan_billing_edit,
  );
  const { data } = useContextQuery({
    queryFn: () => createTemporaryToken({ skipCache: false }),
    // Try to avoid generating redundant tokens within the same minute
    queryKey: ["createTemporaryToken", new Date().toISOString().slice(0, 16)],
    refetchOnWindowFocus: false,
  });

  if (!plansBillingSettingsEditAllowed) {
    return <Navigate to={`../${SettingsPage.AuditLog}`} replace />;
  }

  const componentId = import.meta.env.VITE_SCHEMATIC_COMPONENT_PLAN_ID;
  if (!componentId) {
    return <NotFound />;
  }

  return (
    <SettingsBox className="px-1 *:w-full pb-4">
      <SchematicEmbed accessToken={data?.token} id={componentId} />
    </SettingsBox>
  );
};
