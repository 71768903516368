export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const DEFAULT_CURRENCY = "USD";

export function formatCurrency(
  amount: number | string,
  currency: string = DEFAULT_CURRENCY,
  amountDecimal?: string,
): string {
  const resolvedCurrency = (currency || DEFAULT_CURRENCY).toUpperCase();
  if (
    typeof amount === "string" ||
    (amountDecimal && !isNaN(Number.parseFloat(amountDecimal)))
  ) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: resolvedCurrency,
      minimumSignificantDigits: 2,
    }).format(Number.parseFloat(`${amountDecimal}`) / 100);
  }

  try {
    const dollars = amount / 100;

    const formatValue = (value: number, symbol: string): string => {
      let formatted = value.toFixed(1);
      if (formatted.endsWith(".0")) {
        formatted = formatted.slice(0, -2);
      }

      if (resolvedCurrency !== DEFAULT_CURRENCY) {
        return `${resolvedCurrency}${formatted}${symbol}`;
      }

      return `$${formatted}${symbol}`;
    };

    if (dollars >= 1_000_000) {
      return formatValue(dollars / 1_000_000, "M");
    }

    if (dollars >= 1_000) {
      return formatValue(dollars / 1_000, "k");
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: resolvedCurrency,
    }).format(dollars);
  } catch (error) {
    console.error("Error formatting currency", error);

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: resolvedCurrency,
    }).format(amount / 100);
  }
}

export function formatInitials(name: string): string {
  const cleaned = name.trim();
  if (cleaned === "") {
    return "";
  }

  const names = cleaned.split(" ");
  const initials = names.map((n) => n[0]);
  if (initials.length === 1) {
    return initials[0].toUpperCase();
  }

  return [initials[0], initials[initials.length - 1]]
    .filter(Boolean)
    .join("")
    .toUpperCase();
}

export function titlecase(str: string): string {
  str = str.replace(/_/g, " ");

  const crmChars = str.slice(0, 3);
  if (crmChars === "crm") {
    const title = str.toLowerCase().split(" ");
    const firstPart = title[0].toUpperCase();
    const secondPart = title[1].replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
    return `${firstPart} ${secondPart}`;
  }

  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function formatJson(json: string) {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch {
    return json;
  }
}

export function formatOrdinal(n: number) {
  const enOrdinalRules = new Intl.PluralRules("en-US", { type: "ordinal" });
  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);
  const rule = enOrdinalRules.select(n);
  const suffix = suffixes.get(rule);

  return `${n}${suffix}`;
}

export function getMonthName(date: Date | string) {
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date(date));
}
