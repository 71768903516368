import cx from "classnames";
import { useState } from "react";

export interface ProgressBarProps {
  value?: number;
  total?: number | string;
  progress: number; // Progress is based on a percentage value from 0 to 100
  color?: "gray" | "orange" | "blue" | "red";
  softLimit?: number; // Optional soft limit value
  overLimitCost?: string | number; // Optional cost information for over-limit usage
}

export const ProgressBar = ({
  value,
  total = 0,
  color = "blue",
  progress,
  softLimit,
  overLimitCost,
}: ProgressBarProps) => {
  const [hoverSection, setHoverSection] = useState<
    "normal" | "overlimit" | null
  >(null);
  const [hoverPosition, setHoverPosition] = useState<number>(0);

  const barColorMap = {
    gray: "bg-gray-400/30",
    orange: "bg-orange-500/60",
    blue: "bg-blue-500",
    red: "bg-red-600/60",
  };

  const styles = cx(
    "flex flex-1 relative h-2 bg-gray-50 rounded-full overflow-hidden",
  );

  // Calculate if we're over the soft limit and the percentages
  const hasSoftLimit = softLimit !== undefined;
  const totalValue = typeof total === "string" ? parseInt(total) : total;
  const isOverSoftLimit =
    hasSoftLimit && value !== undefined && value > softLimit;
  const isOverHardLimit =
    value !== undefined && totalValue !== 0 && value > totalValue;

  // Calculate the soft limit position as a percentage
  const softLimitPercent = isOverSoftLimit ? (softLimit! / value) * 100 : 100;

  // Calculate overusage amounts
  const softLimitOverage =
    isOverSoftLimit && value !== undefined && hasSoftLimit
      ? value - softLimit!
      : 0;

  const hardLimitOverage =
    isOverHardLimit && value !== undefined ? value - totalValue : 0;

  // Handle mouse enter for different sections
  const handleMouseEnter = (
    section: "normal" | "overlimit",
    position: number,
  ) => {
    setHoverSection(section);
    setHoverPosition(position);
  };

  // Handle mouse leave
  const handleMouseLeave = () => {
    setHoverSection(null);
  };

  // Generate tooltip content based on which section is being hovered
  const getTooltipContent = () => {
    if (hoverSection === "normal") {
      if (hasSoftLimit) {
        return (
          <div className="py-2 px-3 font-body text-xs rounded-lg font-medium shadow-lg bg-white">
            <div>Usage: {value}</div>
            <div>Soft limit: {softLimit}</div>
            {value !== undefined && value < softLimit! && (
              <div>Remaining: {softLimit! - value}</div>
            )}
          </div>
        );
      } else {
        return (
          <div className="py-2 px-3 font-body text-xs rounded-lg font-medium shadow-lg bg-white">
            <div>Usage: {value}</div>
            <div>Total: {total}</div>
            {!isOverHardLimit && value !== undefined && (
              <div>Remaining: {totalValue - value}</div>
            )}
          </div>
        );
      }
    } else if (hoverSection === "overlimit") {
      return (
        <div className="py-2 px-3 font-body text-xs rounded-lg font-medium shadow-lg bg-white">
          <div>Over soft limit: {softLimitOverage}</div>
          {isOverHardLimit && <div>Over hard limit: {hardLimitOverage}</div>}
          {overLimitCost && <div>Cost: {overLimitCost}</div>}
        </div>
      );
    }

    // Default tooltip for general hover
    return (
      <div className="py-2 px-3 font-body text-xs rounded-lg font-medium shadow-lg bg-white">
        {progress}%
      </div>
    );
  };

  return (
    <div className="relative group flex items-center space-x-4">
      <div className="relative flex flex-1 items-center space-x-4">
        <div className={styles}>
          {/* If we have a soft limit and we're over it, render two segments */}
          {hasSoftLimit && isOverSoftLimit ? (
            <>
              {/* Normal segment up to soft limit */}
              <div
                className={cx(
                  "h-full rounded-l-full cursor-pointer bg-blue-500",
                )}
                style={{ width: `${Math.min(softLimitPercent, 100)}%` }}
                onMouseEnter={() =>
                  handleMouseEnter("normal", softLimitPercent / 2)
                }
                onMouseLeave={handleMouseLeave}
              />
              {/* Red segment for exceeding soft limit */}
              <div
                className={cx("h-full cursor-pointer", barColorMap["red"])}
                style={{
                  width: `${Math.min(progress - softLimitPercent, 100 - softLimitPercent)}%`,
                  position: "absolute",
                  left: `${softLimitPercent}%`,
                }}
                onMouseEnter={() =>
                  handleMouseEnter(
                    "overlimit",
                    (softLimitPercent + progress) / 2,
                  )
                }
                onMouseLeave={handleMouseLeave}
              />
            </>
          ) : (
            // Normal progress bar (single segment)
            <div
              className={cx(
                "h-full rounded-full cursor-pointer",
                barColorMap[color],
              )}
              style={{ width: `${Math.min(progress, 100)}%` }}
              onMouseEnter={() => handleMouseEnter("normal", progress / 2)}
              onMouseLeave={handleMouseLeave}
            />
          )}
        </div>

        {/* Enhanced tooltip that shows different information based on hover section */}
        {hoverSection && (
          <div
            className="absolute bottom-full -translate-y-2 -translate-x-[50%] opacity-100 visible z-10"
            style={{ left: `${hoverPosition}%` }}
          >
            {getTooltipContent()}
            <div className="absolute left-[50%] translate-x-[-50%] h-0 w-0 border-x-[6px] border-x-transparent border-t-[6px] border-t-white"></div>
          </div>
        )}
      </div>

      {total !== 0 && (
        <div className="font-body text-sm leading-none">
          {value}/{total}
        </div>
      )}
    </div>
  );
};
