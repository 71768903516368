import { Error } from "@ui/Error";
import cx from "classnames";
import { ErrorMessage, useField } from "formik";
import CurrencyInput from "react-currency-input-field";

export interface PriceInputProps {
  disabled?: boolean;
  label?: string;
  name: string;
  style?: "default" | "inline";
  description?: string;
  className?: string;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  required?: boolean;
  value?: number | string;
  placeholder?: string;
  currency?: string;
  currencyPosition?: "prefix" | "suffix";
  decimalPlaces?: number;
}

export const PriceInput = ({
  label,
  name,
  description,
  className,
  style = "default",
  disabled = false,
  setFieldValue: externalSetFieldValue,
  required = false,
  currency = "$",
  ...rest
}: PriceInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);

  const styleMap = {
    default:
      "border border-gray-300 h-[44px] focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500",
    inline: "border border-transparent !text-2xl",
  };

  const inputStyles = cx(
    "outline-none rounded-lg flex-1 w-full h-full bg-transparent px-3",
    disabled && "hover:cursor-not-allowed",
    className,
  );

  const wrapperStyles = cx(
    "relative flex flex-col",
    style === "inline" && "w-full",
  );

  const labelStyles = cx("relative label-md", disabled && "cursor-not-allowed");

  const inputContainerStyles = cx(
    "flex items-center rounded-lg transition-colors bg-white",
    styleMap[style],
  );

  return (
    <div className={wrapperStyles}>
      {label && (
        <label htmlFor={name} className={labelStyles}>
          {label}
          {required && (
            <span className="opacity-30 text-black ml-1 text-xs font-normal">
              *
            </span>
          )}
        </label>
      )}
      <div className={inputContainerStyles}>
        <CurrencyInput
          id={name}
          className={inputStyles}
          name={name}
          decimalsLimit={12}
          onValueChange={(value) => {
            if (value) {
              helpers.setValue(Number.parseFloat(value));
            }
          }}
          inputMode={"decimal"}
          placeholder={rest.placeholder || "0.00"}
          prefix={currency}
          value={field.value}
        />
      </div>

      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}

      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
