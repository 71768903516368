import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import {
  Overlay,
  OverlayModal,
  OverlayFooter,
  OverlayHeaderClose,
} from "@components/ui/Overlay";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { updateCompanySubscriptionTrialEnd } from "@modules/companies/queries";
import { PaymentMethodRequiredAlert } from "@modules/plans/components/PaymentMethodRequiredAlert";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { convertLocalToUTCDate } from "@utils/date";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";

type TrialEditOverlayProps = {
  onClose: () => void;
  trialEnd: Date;
  paymentMethodRequired: boolean;
  defaultPlan?: PlanGroupPlanDetailResponseData;
  subscriptionId: string;
};

type TrialEditFormValues = {
  trialEnd: Date;
};

export const TrialEditOverlay = ({
  onClose,
  trialEnd,
  paymentMethodRequired,
  defaultPlan,
  subscriptionId,
}: TrialEditOverlayProps) => {
  const queryClient = useQueryClient();
  const initialValues = {
    trialEnd: trialEnd,
  };

  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (
    { trialEnd }: TrialEditFormValues,
    helpers: FormikHelpers<TrialEditFormValues>,
  ) => {
    setLoading(true);
    try {
      await updateCompanySubscriptionTrialEnd(subscriptionId, {
        trialEnd: trialEnd ? convertLocalToUTCDate(trialEnd) : undefined,
      });

      await queryClient.invalidateQueries();
      onClose();
      setLoading(false);
    } catch (error) {
      setApiError(errorMessage(error));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      <OverlayModal size="lg">
        <OverlayHeaderClose onClose={onClose} />
        {loading && <SchematicOverlayLoader />}
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form className="py-12">
            <div className="flex-1 px-12">
              <FormHeader label={"Edit trial"} title="Edit Trial" />
              <FormColumn>
                <FormRow>
                  <div className="flex flex-col space-y-5">
                    <FormikControl
                      control="date"
                      name="trialEnd"
                      type="date"
                      value={initialValues.trialEnd}
                      label="Trial Expiration Date"
                      dateFormat="MMM dd, yyyy"
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      popperClassName="!hidden"
                    />
                    <FormikControl
                      control="date"
                      name="trialEnd"
                      type="date"
                      value={initialValues.trialEnd}
                      dateFormat="MMM dd, yyyy"
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      inline
                      popperClassName="!hidden"
                      className="inline-datepicker"
                    />
                  </div>
                  <div className="mt-[1.4rem]">
                    <PaymentMethodRequiredAlert
                      trialPaymentMethodRequired={paymentMethodRequired}
                      defaultPlan={defaultPlan}
                      className="mt-0 !pt-4 text-sm px-4"
                    />
                  </div>
                </FormRow>
              </FormColumn>
              {apiError && (
                <div className="pt-12 px-12">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}

              <OverlayFooter>
                <button className="button button-sm button-blue" type="submit">
                  Save changes
                </button>
              </OverlayFooter>
            </div>
          </Form>
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
