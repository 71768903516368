import { NotFound } from "@components/blanks/pages/NotFound";
import { SettingsPageLoader } from "@components/loaders/SettingsPageLoader";
import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { Icon } from "@components/ui/Icon";
import { useContextQuery } from "@hooks/useContextQuery";
import { UpgradeCtaOverlay } from "@modules/integrations/components/overlays/UpgradeCtaOverlay";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { SettingsBox } from "@ui/SettingsBox";
import { formatDate } from "@utils/date";
import { getSegmentIntegrationStatus } from "../../../settings/queries/integrations";

export const SegmentIntegrationPage = () => {
  const getStatus = useContextQuery({
    queryFn: getSegmentIntegrationStatus,
    queryKey: ["getSegmentIntegrationStatus"],
  });

  const openSegmentDocs = () => {
    window.open(
      "https://segment.com/docs/connections/destinations/catalog/actions-schematic/",
      "_blank",
    );
  };

  const segmentEnabled = useSchematicFlag("segment-integration", {
    fallback: false,
  });

  const { data, isLoading } = getStatus;
  if (isLoading) {
    return <SettingsPageLoader />;
  } else if (typeof data === "undefined") {
    return <NotFound />;
  }

  const { connected, lastEventAt } = data;

  return (
    <div className="relative flex flex-col space-y-8 max-w-full w-full -mx-2">
      {!segmentEnabled ? (
        <UpgradeCtaOverlay title="Upgrade to use Segment Integration" />
      ) : null}
      <div className="px-2">
        <div>
          <SettingsBox
            title="Segment Integration"
            description="Enforce usage-based limits and track usage with Segment events"
            style="inline"
            className="h-auto relative"
          >
            <div className="bg-white absolute right-0 translate-y-[-50%] top-[50%] flex p-0.5 items-center rounded-full border border-gray-300">
              <Icon
                name="segment"
                className="text-[#78B39C] text-4xl leading-none"
              />
            </div>
          </SettingsBox>

          <SettingsBox style="boxed" className="space-y-8">
            {!connected && (
              <>
                <div className="flex flex-col space-y-4">
                  <div className="text-lg font-medium">
                    Send Segment data to Schematic with one click
                  </div>
                  <div>
                    <ul className="list-disc pl-4">
                      <li>Identify User</li>
                      <li>Track Event</li>
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col space-y-4">
                  <div className="text-lg font-medium">
                    Take action in Schematic with Segment data
                  </div>
                  <div>
                    <ul className="list-disc pl-4">
                      <li>Assign events to features to start tracking usage</li>
                      <li>Query the API for usage reporting by company</li>
                      <li>
                        Use events to create usage-based entitlements (e.g.
                        seats or queries)
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <Button onClick={() => openSegmentDocs()} color="white">
                    Add Segment Connection
                  </Button>
                </div>
              </>
            )}

            {connected && (
              <>
                <div className="flex flex-col space-y-4">
                  <div className="text-lg font-medium">
                    <span className="mr-4">Status</span>
                    <Alert
                      style="green"
                      className="inline-flex py-1 px-3 text-sm border-none !rounded-full"
                    >
                      <Icon
                        name="check"
                        className="text-lg leading-none text-green-700 mr-1"
                      />
                      {lastEventAt
                        ? `Last event received: ${formatDate(lastEventAt)}`
                        : "Connected"}
                    </Alert>
                  </div>
                  <div>
                    <ul className="list-disc pl-4">
                      <li>Monitor incoming events</li>
                      <li>Assign events to features to start tracking usage</li>
                      <li>Query the API for usage reporting by company</li>
                      <li>
                        Use events to create usage-based entitlements (e.g.
                        seats or queries)
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </SettingsBox>
        </div>
      </div>
    </div>
  );
};
