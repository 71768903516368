import { Button } from "@components/ui/Button";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface AccountPageProps {
  title: string | ReactNode;
  description: string | ReactNode;
  actionDescription: string;
  actionLabel: string;
  actionUrl: string;
  children: ReactNode;
}

const SchematicLogo = () => {
  return (
    <svg
      width="153"
      height="28"
      viewBox="0 0 153 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.9274 11.006C37.8277 10.6271 37.595 10.3014 37.1962 10.0356C36.7974 9.76307 36.3322 9.63678 35.7938 9.63678C34.4977 9.63678 33.5672 10.1752 33.5672 11.1389C33.5672 11.6042 33.7732 11.9498 34.1853 12.1758C34.5841 12.4018 35.2421 12.6278 36.1394 12.8338C37.5219 13.1662 38.386 13.4254 39.2368 13.8242C39.6555 14.0302 39.9945 14.2429 40.2404 14.4822C40.7123 14.9674 40.9981 15.6188 40.9649 16.4363C40.9316 17.6128 40.4664 18.53 39.5691 19.1814C38.6718 19.8394 37.4289 20.1651 35.8669 20.1651C34.3049 20.1651 33.1484 19.8726 32.2046 19.301C31.2674 18.7294 30.7224 17.8986 30.5762 16.8085L33.2548 16.5692C33.3944 17.101 33.7067 17.4998 34.2052 17.7656C34.7037 18.0315 35.2554 18.1777 35.8669 18.1777C36.5449 18.1777 37.1098 18.0382 37.5618 17.7457C38.0138 17.4665 38.2398 17.0744 38.2398 16.5892C38.2398 15.9112 37.6017 15.3795 36.7908 15.0671C36.3787 14.9142 35.8736 14.7747 35.2687 14.6351C34.4179 14.429 33.7466 14.2363 33.2481 14.0635C32.7496 13.9039 32.3109 13.6846 31.9188 13.4187C31.1411 12.887 30.8686 12.1891 30.8686 11.1389C30.8686 10.0289 31.3139 9.16487 32.2046 8.56667C33.0886 7.97512 34.2983 7.66937 35.8403 7.66937C38.5521 7.66937 40.3202 8.75942 40.6126 10.8199L37.9141 11.006H37.9274Z"
        fill="black"
      />
      <path
        d="M52.5342 16.0375L54.9735 17.0611C54.4551 18.0249 53.6907 18.776 52.6871 19.3276C51.6834 19.8859 50.5734 20.1717 49.3504 20.1717C48.1275 20.1717 47.0839 19.9258 46.1002 19.414C45.1165 18.9089 44.3189 18.1711 43.7274 17.214C43.1358 16.2502 42.8301 15.1602 42.8301 13.9305C42.8301 12.7009 43.1292 11.5976 43.7274 10.6404C44.3189 9.67667 45.1165 8.94554 46.1002 8.4404C47.0839 7.93525 48.174 7.68268 49.3504 7.68268C50.5269 7.68268 51.6834 7.96184 52.6937 8.5268C53.6974 9.08512 54.4617 9.84284 54.9735 10.8066L52.5342 11.8235C51.8895 10.6006 50.7197 9.93589 49.3504 9.93589C48.2803 9.93589 47.383 10.3148 46.6386 11.0725C45.8942 11.8302 45.5286 12.7873 45.5286 13.9438C45.5286 15.1003 45.8942 16.0575 46.6386 16.8019C47.383 17.5596 48.2803 17.9385 49.3504 17.9385C50.7197 17.9385 51.8895 17.2672 52.5342 16.0508V16.0375Z"
        fill="black"
      />
      <path
        d="M66.8664 20.0123V13.9372C66.8664 11.3583 65.9292 9.98909 63.7557 9.98909C62.6856 9.98909 61.8216 10.3547 61.1569 11.0924C60.4789 11.8236 60.1399 12.774 60.1399 13.9438V20.0189H57.4414V3.43549H60.1399V9.78305C60.9043 8.45371 62.5128 7.67605 64.6397 7.67605C66.3812 7.67605 67.6307 8.21443 68.4084 9.28455C69.1861 10.3547 69.5716 11.91 69.5716 13.9372V20.0123H66.8597H66.8664Z"
        fill="black"
      />
      <path
        d="M83.1735 9.62373C84.33 10.8467 84.9747 12.7144 84.8551 14.8347H74.699C74.8851 15.7852 75.3238 16.5495 76.0151 17.1345C76.7063 17.726 77.5172 18.0185 78.4344 18.0185C80.023 18.0185 81.3058 17.1411 81.9505 15.6855L84.2636 16.7955C83.7318 17.8456 82.9342 18.6632 81.8907 19.268C80.8405 19.8729 79.6907 20.172 78.4344 20.172C76.6731 20.172 75.0446 19.5472 73.8549 18.4771C72.6651 17.407 71.9141 15.7785 71.9141 13.9241C71.9141 12.0697 72.6718 10.4346 73.8549 9.35786C75.0446 8.28775 76.6731 7.66296 78.4344 7.66296C80.3354 7.66296 82.017 8.3941 83.1735 9.61708V9.62373ZM75.9752 10.7204C75.2972 11.3053 74.8718 12.0697 74.6924 13.0335H82.1964C82.0103 12.0697 81.5916 11.292 80.9136 10.7138C80.2357 10.1422 79.4115 9.84972 78.4411 9.84972C77.4707 9.84972 76.6598 10.1422 75.9818 10.7271L75.9752 10.7204Z"
        fill="black"
      />
      <path
        d="M106.747 9.35762C107.425 10.4809 107.717 11.9365 107.717 13.9372V20.0122H105.019V13.9372C105.019 11.3583 104.188 9.98905 102.214 9.98905C101.164 9.98905 100.346 10.348 99.7613 11.0658C99.1764 11.7903 98.8839 12.7474 98.8839 13.9372V20.0122H96.1721V13.9372C96.1721 11.3583 95.3413 9.98905 93.3672 9.98905C92.317 9.98905 91.4995 10.348 90.9146 11.0658C90.323 11.7903 90.0306 12.7474 90.0306 13.9372V20.0122H87.332V7.85548H89.9109L90.0306 9.77636C90.7617 8.32074 92.1243 7.66937 94.3177 7.66937C96.3715 7.66937 97.7208 8.54673 98.3655 10.2948C99.1564 8.5999 100.805 7.66937 103.171 7.66937C104.853 7.66937 106.056 8.24098 106.747 9.35098V9.35762Z"
        fill="black"
      />
      <path
        d="M120.421 9.77632L120.541 7.85544H123.12V20.0122H120.541L120.421 18.0913C119.71 19.4937 118.195 20.1983 115.875 20.185C112.698 20.2581 109.999 17.4997 110.066 13.9371C109.999 10.3878 112.698 7.60951 115.875 7.67597C117.988 7.67597 119.677 8.47358 120.421 9.78297V9.77632ZM119.311 16.742C120.056 15.9976 120.421 15.0604 120.421 13.9238C120.421 12.7873 120.056 11.8634 119.311 11.1057C118.567 10.3612 117.656 9.98236 116.593 9.98236C115.529 9.98236 114.639 10.3612 113.894 11.1057C113.15 11.8634 112.784 12.8005 112.784 13.9238C112.784 15.0471 113.15 15.9976 113.894 16.742C114.639 17.4997 115.536 17.8786 116.593 17.8786C117.649 17.8786 118.567 17.4997 119.311 16.742Z"
        fill="black"
      />
      <path
        d="M127.498 5.1037H130.183V7.85541H133.313V9.96905H130.183V16.0175C130.183 17.1275 130.562 17.6991 131.432 17.7988C131.964 17.9052 132.589 17.8852 133.313 17.7456V19.9789C132.516 20.1052 131.778 20.1717 131.1 20.1717C129.791 20.1717 128.874 19.8393 128.328 19.168C127.777 18.51 127.498 17.4864 127.498 16.1106V9.96905H125.77V7.85541H127.498V5.1037Z"
        fill="black"
      />
      <path
        d="M136.048 5.63561C135.722 5.32322 135.562 4.93106 135.562 4.47909C135.562 4.02712 135.722 3.66155 136.048 3.33586C136.36 3.02347 136.752 2.86395 137.204 2.86395C137.656 2.86395 138.035 3.02347 138.347 3.33586C138.66 3.66155 138.819 4.04041 138.819 4.47909C138.819 4.91777 138.66 5.30992 138.347 5.63561C138.035 5.96129 137.643 6.12082 137.204 6.12082C136.766 6.12082 136.36 5.96129 136.048 5.63561ZM135.842 7.85559H138.553V20.0123H135.842V7.85559Z"
        fill="black"
      />
      <path
        d="M150.562 16.0375L153.001 17.0611C152.482 18.0249 151.718 18.776 150.714 19.3276C149.711 19.8859 148.601 20.1717 147.378 20.1717C146.155 20.1717 145.111 19.9258 144.128 19.414C143.144 18.9089 142.346 18.1711 141.755 17.214C141.163 16.2502 140.857 15.1602 140.857 13.9305C140.857 12.7009 141.157 11.5976 141.755 10.6404C142.346 9.67667 143.151 8.94554 144.128 8.4404C145.105 7.93525 146.201 7.68268 147.378 7.68268C148.554 7.68268 149.711 7.96184 150.721 8.5268C151.725 9.08512 152.489 9.84284 153.001 10.8066L150.562 11.8235C149.917 10.6006 148.747 9.93589 147.378 9.93589C146.308 9.93589 145.41 10.3148 144.666 11.0725C143.922 11.8302 143.556 12.7873 143.556 13.9438C143.556 15.1003 143.922 16.0575 144.666 16.8019C145.41 17.5596 146.308 17.9385 147.378 17.9385C148.747 17.9385 149.917 17.2672 150.562 16.0508V16.0375Z"
        fill="black"
      />
      <path
        d="M10.5914 19.3141L12.9934 16.8197L6.85199 10.4421C5.33118 8.86284 5.33118 6.28951 6.85199 4.71021C8.37279 3.13091 10.8508 3.13091 12.3716 4.71021L18.513 11.0878L20.915 8.59343L14.7736 2.21585C11.9288 -0.738371 7.29481 -0.738371 4.45001 2.21585C1.60521 5.17006 1.60521 9.98228 4.45001 12.9365L10.5914 19.3141Z"
        fill="#DB6669"
      />
      <path
        d="M10.8201 6.33603C10.1581 5.64857 9.08011 5.64857 8.41811 6.33603C7.75611 7.02349 7.75611 8.14293 8.41811 8.83039L16.1116 16.8198L10.592 22.5517L2.89848 14.5623C2.23648 13.8749 1.1585 13.8749 0.496499 14.5623C-0.1655 15.2498 -0.1655 16.3692 0.496499 17.0567L10.592 27.5404L20.9156 16.8198L10.8201 6.33603Z"
        fill="#DB6669"
      />
    </svg>
  );
};

export const AccountPageContent = ({
  title,
  description,
  actionDescription,
  actionLabel,
  actionUrl,
  children,
}: AccountPageProps) => {
  return (
    <div className="flex-1 flex flex-col justify-between h-full max-h-full lg:overflow-y-auto">
      <div className="flex items-center justify-between pt-8 px-8 lg:pt-12 lg:px-12">
        <Link to="https://schematichq.com">
          <SchematicLogo />
        </Link>
        <div>
          <div className="text-sm flex items-center space-x-4">
            <span className="hidden lg:block">{actionDescription}</span>
            <Button
              size="xs"
              className="text-[#DB6769] ml-2 cursor-pointer text-sm"
              onClick={() => {
                window.location.href = actionUrl;
              }}
            >
              {actionLabel}
            </Button>
          </div>
        </div>
      </div>

      <div className="h-full mb-8 flex flex-col items-center justify-center mt-10 md:mt-14 lg:mt-0">
        <div className="flex flex-col items-center w-full h-full justify-center ">
          <div className="mb-10 lg:mb-14 text-center w-full">
            <div className="font-display text-3xl lg:text-4xl mb-1 lg:mb-2">
              {title}
            </div>
            <div>{description}</div>
          </div>
          <div className="w-full max-w-[400px] text-center px-8 lg:px-12">
            <div className="w-full">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
