import { useSignUp } from "@clerk/clerk-react";
import { FormikControl } from "@forms/FormikControl";
import { FormSignUpProps } from "@modules/accounts/consts";
import { Button } from "@ui/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";

export const SignUpEmailOtpForm = ({ setStep, setError }: FormSignUpProps) => {
  const { isLoaded, signUp } = useSignUp();
  const handleEmailVerification = async (code: string, setSubmitting: any) => {
    if (!isLoaded) return;
    try {
      await signUp.attemptEmailAddressVerification({ code });

      if (signUp?.phoneNumber) {
        await signUp.preparePhoneNumberVerification();
        setStep("otp-phone");
      }

      if (signUp.status === "complete") {
        setStep("done");
      }
      setError("");
    } catch (error: any) {
      setSubmitting(false);
      setError("Invalid verification code.");
    }
  };

  return (
    <Formik
      initialValues={{ emailVerificationCode: "" }}
      onSubmit={({ emailVerificationCode }, { setSubmitting }) => {
        setSubmitting(true);
        handleEmailVerification(emailVerificationCode, setSubmitting);
      }}
      validationSchema={Yup.object({
        emailVerificationCode: Yup.string()
          .length(6, "Verification code must be 6 digits")
          .required("Verification code is required"),
      })}
      key="form-otp-email"
    >
      {({ isSubmitting, dirty }) => {
        return (
          <Form>
            <div className="text-left">
              <FormikControl
                control="input"
                type="text"
                name="emailVerificationCode"
                label="Email Verification Code"
                placeholder="Enter verification code sent to your email"
                className="w-full"
              />
            </div>

            <div className="w-full flex-1 flex pt-4">
              <Button
                type="submit"
                className="w-full text-center max-w-[100%]"
                color="blue"
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
              >
                {isSubmitting ? "Verifying code..." : "Verify"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
