import { AddOnPriceWithAddOnName } from "@modules/companies/types";
import { PriceIntervalDisplayName } from "@modules/plans/consts";
import { Pill } from "@ui/Pill";
import { formatCurrency } from "@utils/strings";
import React from "react";

export const AddOnPriceOptionLabel = ({
  price,
}: {
  price: AddOnPriceWithAddOnName;
}) => {
  return (
    <Pill
      key={price.id}
      color="gray"
      type="tag"
      text="normal"
      className="text-black font-semibold font-display items-baseline"
    >
      {price.addOnName} ·{" "}
      {formatCurrency(price.price, price.currency, price.priceDecimal)}
      {PriceIntervalDisplayName[price.interval] || ""}
    </Pill>
  );
};
