import { Error } from "@ui/Error";
import cx from "classnames";
import { ErrorMessage, Field } from "formik";

type InputTypes = "text" | "number" | "email" | "numeric-string";

export interface InputProps {
  disabled?: boolean;
  label?: string;
  name: string;
  style?: "default" | "inline";
  description?: string;
  className?: string;
  setFieldValue?: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  type?: InputTypes;
  required?: boolean;
  value?: string;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
}

function handleNumericChange(
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
) {
  const parsedValue = parseFloat(e.target.value);

  setFieldValue(e.target.name, parsedValue);
}

export const Input = ({
  label,
  name,
  description,
  className,
  style = "default",
  type = "text",
  disabled = false,
  setFieldValue,
  required = false,
  prefix,
  suffix,
  ...rest
}: InputProps) => {
  const styleMap = {
    default:
      "border border-gray-300 h-[44px] focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500",
    inline: "border border-transparent !text-2xl",
  };

  const handleOnChange =
    type === "number" && setFieldValue
      ? {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleNumericChange(e, setFieldValue),
        }
      : {};

  // both "number" and "numeric-string" types are treated as numeric in the UI,
  // but "number" is converted to a numeric value while "numeric-string" is left as a string
  const htmlType = type === "numeric-string" ? "text" : type;

  const inputStyles = cx(
    "outline-none rounded-lg flex-1 w-full h-full bg-transparent px-3",
    suffix && "pr-3",
    disabled && "hover:cursor-not-allowed",
    className,
  );

  const wrapperStyles = cx(
    "relative flex flex-col",
    style === "inline" && "w-full",
  );

  const labelStyles = cx("relative label-md", disabled && "cursor-not-allowed");

  const inputContainerStyles = cx(
    "flex items-center rounded-lg transition-colors bg-white",
    styleMap[style],
  );

  return (
    <div className={wrapperStyles}>
      {label && (
        <label htmlFor={name} className={labelStyles}>
          {label}
          {required && (
            <span className="opacity-30 text-black ml-1 text-xs font-normal">
              *
            </span>
          )}
        </label>
      )}
      <div className={inputContainerStyles}>
        {prefix && (
          <label
            className="text-xs text-gray-400/70 pl-3 whitespace-nowrap"
            htmlFor={name}
          >
            {prefix}
          </label>
        )}

        <Field
          id={name}
          name={name}
          type={htmlType}
          disabled={disabled}
          {...rest}
          {...handleOnChange}
          className={inputStyles}
        />

        {suffix && (
          <div className="text-sm leading-none text-gray-400 pr-3">
            {suffix}
          </div>
        )}
      </div>

      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}

      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
