import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import {
  ChangeSubscriptionInternalRequestBody,
  CompanyDetailResponseData,
  PlanGroupPlanDetailResponseData,
  BillingPriceResponseData,
} from "@models/api";
import { Plan } from "@models/plan";
import {
  checkout,
  checkoutWithoutSubscription,
  getCheckoutData,
  listAddOnsPricesWithAddOnName,
} from "@modules/companies/queries";
import { AddOnPriceWithAddOnName } from "@modules/companies/types";
import {
  listAddOnsWithEntitlements,
  listPlansWithEntitlements,
} from "@modules/plans/queries/planConfiguration";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Logo } from "@ui/Logo";
import {
  Overlay,
  OverlayFooter,
  OverlayHeader,
  OverlayModal,
} from "@ui/Overlay";
import { Pill } from "@ui/Pill";
import { PlanLabel } from "@ui/PlanLabel";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";
import { AddOnPriceOptionLabel } from "./AddOnPriceOptionLabel";

type CompanyWithoutSubscriptionManagePlanOverlayProps = {
  onClose: () => void;
  onSuccess: () => void;
  company: CompanyDetailResponseData;
};

type FormValues = {
  currentPlan?: Plan;
  selectedPlan?: Plan;
  selectedPlanId?: string;
  currentAddOns: Plan[];
  selectedAddOns: Plan[] | AddOnPriceWithAddOnName[];
  selectedAddOnIds: string[];
};

type PlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type AddOnOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: Plan;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

const AddOnOptionLabel = ({ addOn }: { addOn: Plan }) => {
  return (
    <Pill
      key={addOn.id}
      color="gray"
      type="tag"
      text="normal"
      className="text-black font-semibold font-display items-baseline"
    >
      {addOn.name}
    </Pill>
  );
};

export const CompanyWithoutSubscriptionManagePlanOverlay = ({
  onClose,
  onSuccess,
  company,
}: CompanyWithoutSubscriptionManagePlanOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined,
  );

  const { data: checkoutData, isLoading: isCheckoutDataLoading } =
    useContextQuery({
      queryKey: ["checkoutData", company.id],
      queryFn: () =>
        getCheckoutData({
          companyId: company.id,
        }),
      retry: false,
    });

  useEffect(() => {
    if (checkoutData) {
      const addOns = checkoutData.activeAddOns as Plan[];
      const currentPlan = checkoutData.activePlan
        ? (checkoutData.activePlan as Plan)
        : undefined;

      setInitialValues({
        currentPlan: currentPlan,
        selectedPlan: currentPlan,
        selectedPlanId: currentPlan?.id,
        currentAddOns: addOns,
        selectedAddOns: addOns,
        selectedAddOnIds: addOns.map(({ id }) => id),
      });
    }
  }, [checkoutData]);

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    setLoading(true);

    try {
      if (values.selectedPlan?.billingProduct) {
        // Find $0 price
        const newPrice = values.selectedPlan.billingProduct.prices.find(
          (price) => price.price === 0,
        );

        if (!newPrice) {
          throw new Error("Plan does not have $0 price");
        }

        const req: ChangeSubscriptionInternalRequestBody = {
          companyId: company.id,
          addOnIds: (values.selectedAddOns as AddOnPriceWithAddOnName[]).map(
            (addOn) => ({
              addOnId: addOn.addOnId,
              priceId: addOn.id,
            }),
          ),
          newPlanId: values.selectedPlan.id,
          newPriceId: newPrice.id,
          payInAdvance: [],
        };
        await checkout(req);
      } else {
        const req = {
          addOnIds: (values.selectedAddOns as Plan[]).map((addOn) => addOn.id),
          basePlanId: values.selectedPlan?.id,
        };
        await checkoutWithoutSubscription(company.id, req);
      }

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      onSuccess();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {(loading || isCheckoutDataLoading) && <SchematicOverlayLoader />}
      <OverlayModal>
        <OverlayHeader
          label="Manage plan"
          title={company?.name}
          onClose={onClose}
        >
          <Logo src={company.logoUrl} alt={company.name} />
        </OverlayHeader>

        {initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              selectedPlanId: Yup.string().required("Must provide a plan"),
              selectedAddOnIds: Yup.array(),
            })}
          >
            {({ values, setFieldValue, dirty }) => (
              <Form className="flex flex-col">
                <FormColumn className="px-12">
                  <FormRow>
                    <FormikAsyncSelect
                      className="flex-1"
                      label="Plan"
                      name="selectedPlanId"
                      description="Companies can only have one base plan"
                      placeholder="Type to select plan..."
                      defaultOptions
                      loadOptions={listPlansWithEntitlements}
                      loadOptionsMappers={{
                        mapperFunction: (plan) => ({
                          value: plan.id,
                          label: (
                            <PlanLabel
                              plan={plan}
                              price={plan.monthlyPrice || plan.yearlyPrice}
                              font="normal"
                            />
                          ),
                          entity: plan,
                        }),
                        requestFilter: {
                          withoutPaidProductId: true,
                        },
                      }}
                      selectedOption={
                        values.selectedPlan && {
                          value: values.selectedPlan.id,
                          label: (
                            <PlanLabel
                              plan={values.selectedPlan}
                              price={
                                values.selectedPlan.monthlyPrice ||
                                values.selectedPlan.yearlyPrice
                              }
                              font="normal"
                            />
                          ),
                          entity: values.selectedPlan,
                        }
                      }
                      onChange={async (option: PlanOption) => {
                        const filteredAddOns = (
                          values.selectedAddOns || []
                        ).filter((addon) => {
                          if (option?.entity?.billingProduct) {
                            return "billingProductId" in addon;
                          }

                          return !("billingProductId" in addon);
                        });

                        await setFieldValue("selectedAddOns", filteredAddOns);
                        await setFieldValue(
                          "selectedAddOnIds",
                          filteredAddOns.map((addon) => addon.id),
                        );

                        await setFieldValue(
                          "selectedPlan",
                          option?.entity || null,
                        );
                      }}
                    />
                  </FormRow>

                  {values.selectedPlan?.billingProduct && (
                    <Alert size="xs" style="yellow">
                      Only $0 addons could be selected for $0 plan
                    </Alert>
                  )}

                  {values.selectedPlan?.billingProduct &&
                    values.currentAddOns.length > 0 && (
                      <Alert size="xs" style="yellow">
                        Company would retain access to following add ons:
                        <div className="flex flex-col space-y-2 p-2">
                          {values.currentAddOns.map((addon) => (
                            <PlanLabel key={addon.id} plan={addon} />
                          ))}
                        </div>
                        You can cancel access by removing company from add-ons
                        audience rules
                      </Alert>
                    )}

                  <FormRow>
                    {values.selectedPlan?.billingProduct ? (
                      <FormikAsyncSelect
                        key="addons-paid"
                        className="flex-1"
                        defaultOptions
                        label="Add Ons"
                        isMulti
                        description="Add Ons must have same billing period as plan"
                        loadOptions={listAddOnsPricesWithAddOnName}
                        loadOptionsMappers={{
                          mapperFunction: (
                            price: AddOnPriceWithAddOnName,
                          ): PriceOption => ({
                            // Using billingProductId as value let us select only on price per product
                            name: price.billingProductId,
                            value: price.billingProductId,
                            label: <AddOnPriceOptionLabel price={price} />,
                            resource: price,
                          }),
                          // Only addons with 0$ subscription could be linked to $0 plan
                          resultsFilter: (price: AddOnPriceWithAddOnName) =>
                            price.price === 0,
                        }}
                        name="selectedAddOnIds"
                        placeholder="Select add ons"
                        onChange={async (options: PriceOption[]) => {
                          await setFieldValue(
                            "selectedAddOns",
                            options.map(({ resource }) => resource),
                          );
                        }}
                        selectedOption={(
                          values.selectedAddOns as AddOnPriceWithAddOnName[]
                        ).map((price) => ({
                          name: price.billingProductId,
                          value: price.billingProductId,
                          label: <AddOnPriceOptionLabel price={price} />,
                          resource: price,
                        }))}
                      />
                    ) : (
                      <FormikAsyncSelect
                        key="addons-free"
                        className="flex-1"
                        defaultOptions
                        label="Add Ons"
                        isMulti
                        description="Companies can have multiple add ons"
                        loadOptions={listAddOnsWithEntitlements}
                        loadOptionsMappers={{
                          mapperFunction: (addOn: Plan): AddOnOption => ({
                            name: addOn.name,
                            value: addOn.id,
                            label: <AddOnOptionLabel addOn={addOn} />,
                            resource: addOn,
                          }),
                          requestFilter: {
                            withoutProductId: true,
                          },
                        }}
                        name="selectedAddOnIds"
                        placeholder="Select add ons"
                        onChange={async (options: AddOnOption[]) => {
                          await setFieldValue(
                            "selectedAddOns",
                            options.map(({ resource }) => resource),
                          );
                        }}
                        selectedOption={(values.selectedAddOns as Plan[]).map(
                          (addOn) => ({
                            name: addOn.name,
                            value: addOn.id,
                            label: <AddOnOptionLabel addOn={addOn} />,
                            resource: addOn,
                          }),
                        )}
                      />
                    )}
                  </FormRow>
                </FormColumn>

                {apiError && (
                  <div className="pt-12 px-12">
                    <Alert size="xs" style="red">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="text-base font-body">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </div>
                      </div>
                    </Alert>
                  </div>
                )}

                <OverlayFooter className="px-12 pb-6">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="submit" color="blue" disabled={!dirty}>
                    Save changes
                  </Button>
                </OverlayFooter>
              </Form>
            )}
          </Formik>
        )}
      </OverlayModal>
    </Overlay>
  );
};
