import { FormikControl } from "@forms/FormikControl";
import { Elevate } from "@ui/Elevate";
import React from "react";

export const CustomPlanSettingsElevate = () => {
  return (
    <Elevate className={"w-full"}>
      <div className="flex flex-row gap-4">
        {/* Header Section */}
        <div className="space-y-4  w-1/3">
          <h1 className="text-xl">Custom Plan Rules</h1>
          <p className="text-gray-600 text-sm">
            Custom plans are not available for purchase via checkout but can be
            used as a call to action to schedule a demo.
          </p>
          <p className="text-gray-600 text-sm">
            e.g. Enterprise plan with "Contact Us" instead of a price
          </p>
        </div>

        {/* Form Section */}
        <div className="w-2/3">
          {/* Price Text Section */}
          <div className="space-y-2 mb-5">
            <FormikControl
              control="input"
              name="customPlanConfig.priceText"
              type="text"
              label="Price text"
              placeholder="Price text"
              description={`Text will be displayed instead of a price`}
            />
          </div>

          {/* Call to Action Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <FormikControl
                control="input"
                name="customPlanConfig.ctaText"
                type="text"
                label="Call to Action text"
                placeholder="Call to action text"
              />
            </div>

            <div className="space-y-2">
              <FormikControl
                control="input"
                name="customPlanConfig.ctaWebSite"
                type="text"
                label="Call to Action URL"
                placeholder="Call to Action URL"
              />
            </div>
          </div>
        </div>
      </div>
    </Elevate>
  );
};
