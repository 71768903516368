import { OverlayContextProvider } from "@hooks/useOverlayContext";
import cx from "classnames";
import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import { FormHeader } from "../FormParts";
import { Icon } from "../Icon";

type OverlayPositions = "center" | "right";

interface OverlayProps {
  position?: OverlayPositions;
  onClose: () => void;
  className?: string;
  children: ReactNode;
}

export interface OverlayHeaderProps {
  label?: string;
  title: string | ReactNode;
  description?: string;
  onClose: () => void;
  children?: ReactNode;
}

export interface OverlayHeaderCloseProps {
  onClose: () => void;
}

interface OverlayModalProps {
  position?: OverlayPositions;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  expand?: boolean;
  children: ReactNode;
}

interface OverlayFooterProps {
  className?: string;
  children: ReactNode;
}

export const OverlayModal = ({
  position = "center",
  size = "lg",
  children,
  className,
  expand,
}: OverlayModalProps) => {
  const sizeMap = {
    sm: "min-w-[400px] max-w-[400px]",
    md: "min-w-[600px] max-w-[600px]",
    lg: "min-w-[650px] max-w-[650px]",
    xl: "min-w-[890px] max-w-[890px]",
  };

  const positionMap = {
    center:
      "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-55%] max-h-[calc(100vh-3rem)] overflow-y-auto my-6 rounded-lg",
    right: "overflow-hidden h-full right-0 absolute overflow-y-auto pb-12",
  };

  const styles = cx(
    "bg-white shadow-lg",
    expand && "h-auto max-h-full",
    sizeMap[size],
    positionMap[position],
    className,
  );

  return <div className={styles}>{children}</div>;
};

export const OverlayHeaderClose = ({ onClose }: OverlayHeaderCloseProps) => {
  return (
    <div
      onClick={() => onClose && onClose()}
      className="inline-flex absolute z-[500] top-8 right-8 hover:cursor-pointer text-black/50 hover:text-blue-400"
    >
      <Icon name="close" className="text-3xl" />
    </div>
  );
};

export const OverlayHeader = ({
  onClose,
  label,
  title,
  description,
  children,
  ...props
}: OverlayHeaderProps) => {
  return (
    <div className="px-12 pt-12">
      <div className="flex justify-between items-start">
        {children ? (
          <div className="flex justify-between w-full">
            <FormHeader
              label={label}
              title={title}
              description={description}
              {...props}
            />
            {children}
          </div>
        ) : (
          <FormHeader
            label={label}
            title={title}
            description={description}
            {...props}
          />
        )}

        <OverlayHeaderClose onClose={onClose} />
      </div>
    </div>
  );
};

export const Overlay = ({ onClose, className, children }: OverlayProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const styles = cx("fixed w-screen h-screen left-0 top-0 z-70", className);

  return createPortal(
    <OverlayContextProvider value={{ onClose }}>
      <div className={styles}>
        <div className="bg-black/40 w-full h-full absolute" onClick={onClose} />
        {children}
      </div>
    </OverlayContextProvider>,
    document.body,
  );
};

export const OverlayFooter = ({ className, children }: OverlayFooterProps) => {
  const styles = cx("flex mt-8 items-end justify-end space-x-2", className);

  return <div className={styles}>{children}</div>;
};
