import { listBillingProducts } from "@data/billing";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";

import {
  BillingProductResponseData,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
} from "@models/api";
import { EntitlementValueType } from "@models/entitlement";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriodMonthResetDisplay,
  ConditionMetricPeriodMonthResets,
  ConditionMetricPeriods,
  FeatureType,
} from "@models/feature";
import { FormValues } from "@modules/plans/components/overlays/PlanEntitlementOverlay/PlanEntitlementEditOverlay";
import { MetricPeriodDisplay } from "@modules/plans/consts";
import { Alert } from "@ui/Alert";
import {
  AutoCreateBillingProductOptionLabel,
  BillingProductOptionLabel,
} from "@ui/BillingProductOptionLabel";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Switch } from "@ui/Switch";
import { FormikErrors } from "formik";
import pluralize from "pluralize";
import { ReactNode, useEffect, useState } from "react";

type OverageEntitlementBlockProps = {
  values: any;
  name?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;
};

type BillingProductOption = {
  name: string;
  value: string;
  label: ReactNode;
  autoCreate?: boolean;
  resource: BillingProductResponseData;
};

export const OverageEntitlementBlock = ({
  values,
  name = "",
  setFieldValue,
}: OverageEntitlementBlockProps) => {
  const [hasHardLimit, setHasHardLimit] = useState<boolean>(false);
  const [selectedBillingProduct, setSelectedBillingProduct] =
    useState<BillingProductResponseData>();

  useEffect(() => {
    if (values.valueNumeric && values.valueType === "numeric") {
      setHasHardLimit(true);
    }
    setSelectedBillingProduct(values.usageBasedProduct);

    // if (Number.isInteger(values.monthlyUnitPrice)) {
    //   console.log(`${name}monthlyUnitPrice`, values.monthlyUnitPrice)
    //   setFieldValue(`${name}monthlyUnitPrice`, Number(values.monthlyUnitPrice).toFixed(2));
    // }
    //
    // if (Number.isInteger(values.yearlyUnitPrice)) {
    //   console.log(`${name}yearlyUnitPrice`, values.yearlyUnitPrice);
    //   setFieldValue(`${name}yearlyUnitPrice`, Number(values.yearlyUnitPrice).toFixed(2));
    // }
  }, [values]);

  useEffect(() => {
    if (!values.monthlyUnitPrice && values.monthlyMeteredPrice) {
      const lastPriceFromTier =
        values.monthlyMeteredPrice.priceTier[
          values.monthlyMeteredPrice.priceTier.length - 1
        ].perUnitPrice;
      const lastDecimalPriceFromTier =
        values.monthlyMeteredPrice.priceTier[
          values.monthlyMeteredPrice.priceTier.length - 1
        ].perUnitPriceDecimal;
      setFieldValue(
        `${name}monthlyUnitPrice`,
        (lastPriceFromTier ?? Number(lastDecimalPriceFromTier)) / 100,
      );
    }

    if (!values.yearlyUnitPrice && values.yearlyMeteredPrice) {
      const lastPriceFromTier =
        values.yearlyMeteredPrice.priceTier[
          values.yearlyMeteredPrice.priceTier.length - 1
        ].perUnitPrice;
      const lastDecimalPriceFromTier =
        values.yearlyMeteredPrice.priceTier[
          values.yearlyMeteredPrice.priceTier.length - 1
        ].perUnitPriceDecimal;
      setFieldValue(
        `${name}yearlyUnitPrice`,
        (lastPriceFromTier ?? Number(lastDecimalPriceFromTier)) / 100,
      );
    }
  }, [values]);

  const metricPeriodOptions = ConditionMetricPeriods.map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const metricPeriodMonthResetOptions = ConditionMetricPeriodMonthResets.map(
    (period) => {
      return {
        value: period,
        label: ConditionMetricPeriodMonthResetDisplay[period],
      };
    },
  );

  const valueToText = (values: FormValues) => {
    if (!values.feature) return "";

    if (values.valueType === EntitlementValueType.Numeric) {
      return (
        <span>
          <span className="font-bold">
            {(values.valueNumeric ?? 0).toLocaleString()}{" "}
            {pluralize(values.feature.name, values.valueNumeric ?? 0)}
          </span>{" "}
          {values.metricPeriod &&
            MetricPeriodDisplay[
              values.metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
            ]}
        </span>
      );
    }
  };

  return (
    <div className="flex flex-col justify-between  mt-6">
      <FormikAsyncSelect
        label="Select stripe product"
        name={`${name}billingProduct`}
        defaultOptions
        selectedOption={() => {
          console.log(values, values.meteredProductId, selectedBillingProduct);
          if (
            values.autoCreate &&
            values.overageBillingProductId === "generate"
          ) {
            return {
              autoCreate: true,
              name: "generate",
              value: "generate",
              label: <AutoCreateBillingProductOptionLabel />,
            };
          }

          if (values.usageBasedProduct) {
            return {
              autoCreate: false,
              name: values.name,
              value: values.usageBasedProduct.productId,
              label: (
                <BillingProductOptionLabel product={values.usageBasedProduct} />
              ),
            };
          } else {
            return null;
          }
        }}
        maxNumberOfSelected={1}
        loadOptions={async (flt) => {
          const result = await listBillingProducts(flt);
          if (!flt.offset) {
            // eslint-disable-next-line
            // @ts-ignore
            result.unshift({
              name: "Create automatically",
              // eslint-disable-next-line
              // @ts-ignore
              autoCreate: true,
            });
          }
          return result;
        }}
        loadOptionsMappers={{
          mapperFunction: (
            product: BillingProductResponseData,
          ): BillingProductOption => {
            // eslint-disable-next-line
            // @ts-ignore
            if (product.autoCreate) {
              return {
                autoCreate: true,
                name: "generate",
                value: "generate",
                label: <AutoCreateBillingProductOptionLabel />,
                resource: product,
              };
            }

            return {
              autoCreate: false,
              name: product.name,
              value: product.productId,
              label: <BillingProductOptionLabel product={product} />,
              resource: product,
            };
          },
        }}
        onChange={async (options: Option) => {
          if (!options) {
            await setFieldValue(`${name}billingProduct`, null);
            return null;
          } else {
            await setFieldValue(`${name}billingProduct`, options.value);
            await setFieldValue(`${name}usageBasedProduct`, options.value);
            await setFieldValue(
              `${name}overageBillingProductId`,
              options.value,
            );
            return null;
          }
        }}
      />

      {selectedBillingProduct && (
        <div>
          <div className="h-px w-full my-6 bg-gray-300" />
          <FormColumn className="mt-6">
            <div className="flex flex-row justify-between">
              <div>
                <div className="text-base mb-2">Soft limit</div>
                <div className="text-base mb-5 text-gray-600">
                  Set the usage included before reaching overage state.
                </div>
              </div>
              {values.feature.featureType === "event" && (
                <div className="text-gray-600">Event based</div>
              )}
              {values.feature.featureType === "trait" && (
                <div className="text-gray-600">Trait based</div>
              )}
            </div>

            <div>
              <FormRow className={"flex-row"}>
                <FormikControl
                  control="input"
                  label="Usage value"
                  name={`${name}softLimit`}
                  type="number"
                />

                <FormikControl
                  control="select"
                  name={`${name}metricPeriod`}
                  label="Period"
                  options={metricPeriodOptions}
                />
              </FormRow>

              {values.metricPeriod ===
                CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth && (
                <FormRow className={"mt-4"}>
                  <FormikControl
                    control="select"
                    name={`${name}metricPeriodMonthReset`}
                    label="Date"
                    options={metricPeriodMonthResetOptions}
                    description={
                      values.metricPeriodMonthReset ==
                        CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle &&
                      "If no subscription present for a company, entitlement will reset on first of the month."
                    }
                  />
                </FormRow>
              )}

              <FormRow className={"mt-4"}>
                {values.softLimit > 0 && (
                  <div className="w-full">
                    <div className="flex flex-col items-stretch">
                      <div className={"flex flex-row justify-between"}>
                        <div>
                          <div className="text-base mb-2">Overage fee</div>
                          <div className="text-base mb-5 text-gray-600">
                            Specify price per each unit after reaching overage
                            state.
                          </div>
                        </div>
                      </div>

                      <div className="w-full">
                        <div className="flex flex-col md:flex-row gap-4 justify-between">
                          {values.plan.monthlyPrice && (
                            <div className="w-full">
                              <FormikControl
                                control="price"
                                label="Monthly price per unit"
                                name={`${name}monthlyUnitPrice`}
                              />
                            </div>
                          )}

                          {values.plan.yearlyPrice && (
                            <div className="w-full">
                              <FormikControl
                                control="price"
                                label="Yearly price per unit"
                                name={`${name}yearlyUnitPrice`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FormRow>

              <div className="flex flex-row justify-start gap-4 mt-6">
                <Switch
                  name="hardLimit"
                  checked={hasHardLimit}
                  onCheckedChange={async (checked) => {
                    if (checked) {
                      await setFieldValue(
                        `${name}valueType`,
                        EntitlementValueType.Numeric,
                      );
                      await setFieldValue(`${name}valueBool`, false);
                    } else {
                      await setFieldValue(
                        `${name}valueType`,
                        EntitlementValueType.Unlimited,
                      );
                    }
                    setHasHardLimit(checked);
                  }}
                />
                <div>
                  <div className="mb-1 text-lg">Hard limit</div>
                  <div className="mb-2 text-sm">
                    Set an upper limit at which access is denied
                  </div>
                </div>
              </div>

              {hasHardLimit && (
                <div>
                  <FormRow className={"flex-row"}>
                    <FormikControl
                      control="input"
                      label="Usage value"
                      name={`${name}valueNumeric`}
                      type="number"
                    />

                    <FormikControl
                      disabled
                      control="select"
                      name={`${name}metricPeriod`}
                      label="Period"
                      options={metricPeriodOptions}
                    />
                  </FormRow>
                </div>
              )}
            </div>

            {values.feature.featureType !== FeatureType.Boolean && (
              <Alert size="xs" style="yellow">
                {values.plan.companyCount} Companies in{" "}
                <span className="font-bold">{values.plan.name}</span> Plan get
                access to {valueToText(values)}
              </Alert>
            )}
          </FormColumn>
        </div>
      )}
    </div>
  );
};
