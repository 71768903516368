import { SettingsPageLoader } from "@components/loaders/SettingsPageLoader";
import { Button } from "@components/ui/Button";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { HubspotFailedToInstallOverlay } from "@modules/settings/components/overlays/HubspotFailedToInstallOverlay";
import { HubspotInstalledOverlay } from "@modules/settings/components/overlays/HubspotSuccessInstallOverlay";
import { StripeIntegrationDeleteOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationDeleteOverlay";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const HubSpotConnection = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "hubspot" }),
  });

  const [searchParams] = useSearchParams();

  const installIntegrationMutation = useMutation({
    mutationFn: integrationsApi.installIntegration,
  });

  const [removeIntegration, setRemoveIntegration] = useState<string | null>(
    null,
  );

  const onConnect = async () => {
    installIntegrationMutation
      .mutateAsync({ type: "hubspot", config: {} })
      .then((data) => {
        window.location.assign(data.config.config.connect_url);
      });
  };

  const { data, isLoading } = getIntegrations;

  if (isLoading) {
    return <SettingsPageLoader />;
  } else if (typeof data === "undefined") {
    return <></>;
  } else if (
    data?.length === 0 ||
    data.find((i) => i.type === "hubspot") === undefined
  ) {
    return (
      <div>
        {searchParams.get("result") === "failed" && (
          <HubspotFailedToInstallOverlay
            onClose={() => {
              getIntegrations.refetch();
            }}
          />
        )}
        <Button color="blue" onClick={onConnect}>
          Connect HubSpot
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-start space-x-12">
      {searchParams.get("result") === "success" && (
        <HubspotInstalledOverlay
          onClose={() => {
            getIntegrations.refetch();
          }}
        />
      )}
      <div>HubSpot is connected</div>
      {removeIntegration && (
        <StripeIntegrationDeleteOverlay
          onClose={() => {
            setRemoveIntegration(null);
            getIntegrations.refetch();
          }}
          integrationId={getIntegrations.data![0].id}
        />
      )}
      <Button
        onClick={() =>
          setRemoveIntegration(
            getIntegrations.data!.find((i) => i.type === "hubspot")!.id,
          )
        }
      >
        Remove connection
      </Button>
    </div>
  );
};
