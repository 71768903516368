import { EventBodyFlagCheck } from "@models/api";
import { Feature } from "@models/feature";
import pluralize from "pluralize";

export const getCheckStatus = (check?: EventBodyFlagCheck) => {
  if (!check) return "gray";

  if (check.error) {
    return "error";
  }

  if (check.reason && check.reason.toLowerCase().includes("not found")) {
    return "warning";
  }

  return "ok";
};

export const getCheckStatusColor = (status: string) => {
  if (status === "error") {
    return "red";
  }

  if (status === "warning") {
    return "yellow";
  }

  if (status === "ok") {
    return "green";
  }

  return "gray";
};

export const getCheckStatusText = (status: string) => {
  if (status === "error") {
    return "Failed";
  }

  if (status === "warning") {
    return "Warning";
  }

  if (status === "ok") {
    return "Success";
  }

  return "Unknown";
};

interface getFeatureName {
  (
    feature: Pick<Feature, "name" | "singularName" | "pluralName">,
    count?: number,
  ): string;
}

export const getFeatureName: getFeatureName = (feature, count = 0) => {
  const shouldBePlural = count === 0 || count > 1;
  const { name, singularName, pluralName } = feature;

  if (pluralName && shouldBePlural) return pluralName;
  if (singularName)
    return shouldBePlural ? pluralize(singularName, count) : singularName;

  return pluralize(name, count);
};
