import { Alert } from "@components/ui/Alert";
import { useState } from "react";
import { FormSignUpSteps } from "./consts";
import {
  AccountPageContent,
  AccountPageWrapper,
  PrivacyPolicy,
  SignUpEmailOtpForm,
  SignUpForm,
  SignUpPhoneOtpForm,
  SocialForm,
  VideoContent,
} from ".";

export const SignUpPage = () => {
  const [step, setStep] = useState<FormSignUpSteps>("sign-up");
  const [error, setError] = useState("");

  const isSignUp = step === "sign-up";
  const isOtpEmail = step === "otp-email";
  const isOtpPhone = step === "otp-phone";
  const isDone = step === "done";

  const renderForm = () => {
    switch (true) {
      case isSignUp:
        return <SignUpForm setError={setError} setStep={setStep} />;
      case isOtpEmail:
        return <SignUpEmailOtpForm setError={setError} setStep={setStep} />;
      case isOtpPhone:
        return <SignUpPhoneOtpForm setError={setError} setStep={setStep} />;
      case isDone:
        return <>Logging you in...{(window.location.href = "/")}</>;
    }
  };

  return (
    <AccountPageWrapper>
      <AccountPageContent
        title="Let's get started"
        description="Set up your account in seconds"
        actionDescription="Already have an account?"
        actionLabel="Sign in"
        actionUrl="sign-in"
      >
        {renderForm()}

        {error && (
          <Alert size="xs" style="red" className="mt-8 text-sm py-4 w-full">
            {error}
          </Alert>
        )}

        <SocialForm setError={setError} type="sign-up" />
        <PrivacyPolicy type="sign-up" />
      </AccountPageContent>
      <VideoContent />
    </AccountPageWrapper>
  );
};
