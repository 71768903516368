export const SettingsPageLoader = () => {
  return (
    <div className="relative w-full h-full">
      <div
        role="status"
        className="absolute top-0 left-0 w-full pt-2.5 animate-pulse"
      >
        <div className="flex items-center mb-3">
          <div className="h-6 bg-gray-200 rounded w-56" />
        </div>

        <div className="p-0 flex justify-between items-center">
          <div className="h-4 bg-gray-200 rounded w-[450px]" />
          <div className="h-10 w-10 bg-gray-200 rounded-full mr-2" />
        </div>

        <div className="flex border border-gray-300 rounded-lg mt-7 w-full  overflow-hidden p-14 flex-col space-y-6">
          <div className="h-5 w-[200px] bg-gray-200 roudned-lg" />
          <div className="border border-gray-300 rounded mt-7 w-full h-full overflow-hidden p-8 flex-col space-y-7">
            <div className="flex items-center ">
              <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
              <div className="h-4 bg-gray-200 rounded w-[250px]" />
            </div>

            <div className="flex items-center ">
              <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
              <div className="h-4 bg-gray-200 rounded w-[250px]" />
            </div>
            <div className="flex items-center">
              <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
              <div className="h-4 bg-gray-200 rounded w-[250px]" />
            </div>
            <div className="flex items-center">
              <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
              <div className="h-4 bg-gray-200 rounded w-[250px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
