import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import { AccountEnvironment } from "@models/account";
import { ClerkUserPermission } from "@models/clerkUser";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import { Icon } from "@ui/Icon";
import { Pill, PillStyleTypes } from "@ui/Pill";
import { Switch } from "@ui/Switch";
import cx from "classnames";
import { FormikProps } from "formik";
import { MouseEvent, useState } from "react";
import { TeammateData } from "./index";

const PermissionTitles = {
  [ClerkUserPermission.none]: "None",
  [ClerkUserPermission.flags_edit]: "Flags",
  [ClerkUserPermission.flag_rules_edit]: "Flag Rules",
  [ClerkUserPermission.features_edit]: "Features",
  [ClerkUserPermission.overrides_edit]: "Overrides",
  [ClerkUserPermission.plan_entitlements_edit]: "Plan Entitlements",
  [ClerkUserPermission.plans_edit]: "Plans",
  [ClerkUserPermission.companies_edit]: "Companies",
  [ClerkUserPermission.company_users_edit]: "Users",
  [ClerkUserPermission.components_edit]: "Components",
  [ClerkUserPermission.webhooks_edit]: "Webhooks",
  [ClerkUserPermission.webhooks_reveal_secret]: "Webhooks Reveal Secret",
  [ClerkUserPermission.plan_billing_edit]: "Plan & Billing",
};

type TeammateEnvironmentPermissionsProps = {
  env: AccountEnvironment;
  form: FormikProps<TeammateData>;
  permissions: Record<string, boolean>;
  defaultOpen?: boolean;
};

export const TeammateEnvironmentPermissions = ({
  env,
  form,
  permissions,
  defaultOpen = false,
}: TeammateEnvironmentPermissionsProps) => {
  const [open, setOpen] = useState(defaultOpen);

  const numberOfSelectedPermissions =
    Object.values(permissions).filter(Boolean).length;

  const setAllValue = (value: boolean) => (event: MouseEvent) => {
    event.stopPropagation();

    const newPermissions = Object.entries(permissions).reduce(
      (acc, [key]) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    form.setFieldValue(`permissions[${env.id}]`, newPermissions);
  };

  return (
    <Collapsible
      key={env.id}
      open={open}
      onOpenChange={setOpen}
      className={cx(
        "border border-gray-300 rounded-md hover:cursor-pointer hover:bg-gray-100 group",
        open && "!bg-white",
      )}
    >
      <CollapsibleTrigger asChild>
        <div className="flex justify-between py-4 px-6">
          <div className="flex">
            <Icon
              name={`chevron-${open ? "up" : "down"}`}
              className={cx(
                "group-hover:text-blue-400 mr-2 -ml-2 text-3xl text-gray-400 leading-none rotate-0 transition-all group-hover:opacity-80 inline-block",
                open && "!text-blue-500",
              )}
            />
            <div className="flex space-x-4">
              <div>{env.name}</div>
              <div>
                <Pill
                  color={
                    environmentPillColors[env.environmentType] as PillStyleTypes
                  }
                >
                  {EnvironmentTypeShort[env.environmentType]}
                </Pill>
              </div>
            </div>
          </div>
          <div className="flex space-x-4 items-center ">
            {open ? (
              <div className="flex space-x-2 items-center">
                <button
                  type="button"
                  className="cursor-pointer hover:underline text-blue-400 text-sm leading-none"
                  onClick={setAllValue(true)}
                >
                  Select all
                </button>
                <div className="opacity-30 text-sm leading-none font-light">
                  |
                </div>
                <button
                  type="button"
                  className="cursor-pointer hover:underline text-blue-400 text-sm leading-none"
                  onClick={setAllValue(false)}
                >
                  Unselect all
                </button>
              </div>
            ) : (
              <div className="text-gray-400 text-sm">
                {numberOfSelectedPermissions} permission enabled
              </div>
            )}
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="CollapsibleContent">
        {Object.values(ClerkUserPermission)
          .filter((permission) => permission !== ClerkUserPermission.none)
          .map((permission) => {
            return (
              <div
                key={permission}
                className="flex justify-between border-b px-6 py-4"
              >
                <div>
                  {PermissionTitles[permission]} •{" "}
                  <span className="text-gray-400 text-sm">
                    Create, edit and delete
                  </span>
                </div>
                <div>
                  <Switch
                    name={`permissions[${env.id}][${permission}]`}
                    checked={permissions[permission]}
                    onCheckedChange={async (checked) => {
                      await form.setFieldValue(
                        `permissions[${env.id}][${permission}]`,
                        checked,
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
      </CollapsibleContent>
    </Collapsible>
  );
};
